import React, { useState} from 'react'
import {useDispatch} from 'react-redux';
import {useDropzone} from 'react-dropzone'
import demo_user from '../../assets/images/avatar/user.png';
import {uploadToFirebase} from '../../service/firebase';
import {updateProfile} from '../../features/user/userSlice';
import {Amplitude} from '../../features/tools';
let EventTracker = new Amplitude();

export default function UpdateAvatar(props) {
  let [uploaded, setUploaded] = useState();
  let dispatch = useDispatch();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept
  } = useDropzone({
    accept: '.jpeg,.png,.jpg',
    multiple:false,
    onDrop: (files) => {
      let reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const baseStr = reader.result
        setUploaded(baseStr)
      }
      reader.readAsDataURL(files[0]);
      uploadToFirebase('avatar', files[0]).then((response)=>{
        EventTracker.logEvent('UPLOAD_PROFILE_IMAGE_SUCCESS')
        dispatch(updateProfile({avatar:response}));
      }).catch((err)=>{
        EventTracker.logEvent('UPLOAD_PROFILE_IMAGE_FAILURE')
        console.error(err);
      })
    }
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="d-lg-flex align-items-center justify-content-between pb-4">
        <div className="d-flex align-items-center mb-4 mb-lg-0">
          <img
            src={uploaded|| props.avatar||demo_user}
            id="img-uploaded"
            className="avatar-xl rounded-circle"
            alt=""
          />
          <div className="ms-3">
            <h4 className="mb-0">Your avatar</h4>
            {
              isDragActive ?
              <p className="mb-0">Drop the files here ...</p> :
              isDragAccept?
              <p className="mb-0">Files accepted.</p>:
              <p className="mb-0">Upload a 300 x 300px avatar.</p>
            }
          </div>
        </div>
        <div>
          <button className="btn btn-white brand-button">
            Upload
          </button>
        </div>
      </div>
    </div>
  )
}
//@NFTmarket.africa 2022
