import React, {useEffect, useState} from 'react';
// import {NavLink} from 'react-router-dom';
import {useSelector, useDispatch} from 'react-redux';
import {connectAccount, disconnectAccount, web3Modal} from '../../features/auth/authSlice';
import {getUserProfile} from '../../features/user/userSlice';
import logo2 from '../../assets/images/logo/nftmarketafrica_icon.png';
import avatar from '../../assets/images/avatar/user.png';

let AuthNavBar = (props)=> {
  let dispatch = useDispatch()
  let [search, setSearch] = useState("nftmarketafrica")
  let [loaded, setLoaded] = useState(false)
  let {profile} = useSelector(state => state.user.value)

  useEffect(()=>{
    if(!profile){
      dispatch(getUserProfile())
    }
  },[dispatch, profile])

  // useEffect(()=>{
  //   dispatch(getUserProfile())
  // },[dispatch])

  useEffect(()=>{
    let timer = setTimeout(()=>{
      if (web3Modal.cachedProvider && !loaded) {
        dispatch(connectAccount())
        setLoaded(true)
      }
    },1000)

    return function(){
      clearTimeout(timer);
    }
  },[dispatch, loaded])

  let handleInput =(e)=> {
    let {value} = e.target;
    if(value){
      setSearch(value);
    }
  }

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }

  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-default fixed-top shadow-none py-3"  style={{zIndex:2}}>
      <div className="container px-0">
        <a className="navbar-brand" href="https://nftmarket.africa">
          <img src={logo2} width={50} alt="logo"/>
        </a>
        <ul className="navbar-nav navbar-right-wrap ms-auto d-lg-none d-flex nav-top-wrap">
          <li className="dropdown ms-2">
            <a
              className="rounded-circle"
              href="/#"
              role="button"
              data-bs-toggle="dropdown"
            >
              <div className="avatar avatar-md avatar-indicators avatar-online">
                <img
                  alt="avatar"
                  src={profile.avatar || avatar}
                  className="rounded-circle"
                />
              </div>
            </a>
            <div className="dropdown-menu dropdown-menu-end shadow">
              <div className="dropdown-item fs-4">
                <div className="">
                  <div className="ms-3 lh-1">
                    <h5 className="mb-1">{profile.full_name || ''}</h5>
                    <p className="mb-0 text-muted">{profile.account?sliceCharacter(profile.account):'No account'}</p>
                  </div>
                </div>
              </div>
              <div className="dropdown-divider" />
              <ul className="list-unstyled">
              {
                profile.profile_verified?
                <li>
                  <a className="dropdown-item fs-4" href="/storefront">
                    Storefront
                  </a>
                </li>
                :
                null
              }
                <li>
                  <a className="dropdown-item fs-4" href="/dashboard">
                    Dashboard
                  </a>
                </li>
                <li>
                  <a className="dropdown-item fs-4" href="/wallet">
                    Wallet
                  </a>
                </li>
                <li>
                  <a className="dropdown-item fs-4" href="/gallery">
                    Gallery
                  </a>
                </li>
                {
                  profile.profile_verified?
                  <li>
                    <a className="dropdown-item fs-4" href="/sales">
                      Sales
                    </a>
                  </li>
                  :
                  null
                }
                <li>
                  <a className="dropdown-item fs-4" href="/orders">
                    Orders
                  </a>
                </li>
                <li>
                  <a className="dropdown-item fs-4" href="/profile/edit">
                    Profile
                  </a>
                </li>
              </ul>
              <div className="dropdown-divider" />
              <ul className="list-unstyled">
                <li>
                  <a
                  className="dropdown-item fs-4"
                  href="/#"
                  onClick={() => dispatch(disconnectAccount())}>
                    <i className="fe fe-power me-2" />
                    Sign Out
                  </a>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        {/* Button */}
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar top-bar mt-0" />
          <span className="icon-bar middle-bar" />
          <span className="icon-bar bottom-bar" />
        </button>
        {/* Collapse */}
        <div className="collapse navbar-collapse offset-lg-1" id="navbar-default">
        <ul className="navbar-nav d-lg-none">
          <form action={`/@/${search}`} method="GET" className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
            <span className="position-absolute ps-3 search-icon">
              <i className="fe fe-search" />
            </span>
            <input
              type="search"
              className="form-control form-control-lg rounded-pill ps-6"
              placeholder="Search creators"
              onChange={handleInput}
            />
          </form>
        </ul>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a
              className="nav-link fs-4"
              href="https://nftmarket.africa/shop"
            >
              Shop
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link fs-4"
              href="https://nftmarket.africa/utility"
            >
              Utility
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link fs-4"
              href="/faq"
            >
              FAQ
            </a>
          </li>
          <li className="nav-item">
            <a
              className="nav-link fs-4"
              href="https://blog.nftmarket.africa"
              target="blank_"
            >
              Blog
            </a>
          </li>
        </ul>
          <ul className="navbar-nav navbar-right-wrap ms-auto d-none d-lg-block">
            <li className="nav-item docs-header-btn d-flex justify-content-between">
              <div className="d-flex align-items-center">
                <form action={`/@/${search}`} method="GET" className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center me-3">
                  <span className="position-absolute ps-3 search-icon">
                    <i className="fe fe-search" />
                  </span>
                  <input
                    type="search"
                    className="form-control form-control-lg rounded-pill ps-6"
                    placeholder="Search creators"
                    onChange={handleInput}
                  />
                </form>
                <div className="dropdown ms-2 d-inline-block">
                  <a
                    className="rounded-circle"
                    href="/#"
                    data-bs-toggle="dropdown"
                    data-bs-display="static"
                    aria-expanded="false"
                  >
                    <div className="avatar avatar-md">
                      <img
                        alt="avatar"
                        src={profile.avatar || avatar}
                        className="rounded-circle"
                      />
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-end">
                    <div className="dropdown-item fs-4">
                      <div className="ms-3 lh-1">
                        <h5 className="mb-1">{profile.full_name || 'No name'}</h5>
                        <p className="mb-0 text-muted">{profile.account?sliceCharacter(profile.account):'No account'}</p>
                      </div>
                    </div>
                    <div className="dropdown-divider"/>
                    <ul className="list-unstyled">
                    {
                      profile.profile_verified?
                      <li>
                        <a className="dropdown-item fs-4" href="/storefront">
                          Storefront
                        </a>
                      </li>
                      :
                      null
                    }
                      <li>
                        <a className="dropdown-item fs-4" href="/dashboard">
                          Dashboard
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item fs-4" href="/wallet">
                          Wallet
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item fs-4" href="/gallery">
                          Gallery
                        </a>
                      </li>
                      {
                        profile.profile_verified?
                        <li>
                          <a className="dropdown-item fs-4" href="/sales">
                            Sales
                          </a>
                        </li>
                        :
                        null
                      }
                      <li>
                        <a className="dropdown-item fs-4" href="/orders">
                          Orders
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item fs-4" href="/profile/edit">
                          Profile
                        </a>
                      </li>
                    </ul>
                    <div className="dropdown-divider" />
                    <ul className="list-unstyled">
                      <li>
                        <a
                        className="dropdown-item fs-4"
                        href="/#"
                        onClick={() => dispatch(disconnectAccount())}>
                          <i className="fe fe-power me-2" />
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </>
  )
}
export default AuthNavBar;
//@nftmarket.africa 2022
