import React, {useState} from 'react';
import flutterwave from '../../../assets/images/brand/flutterwave.jpeg';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import {getTransaction} from '../../../features/wallet/walletSlice';
import {useDispatch} from 'react-redux';
import {Amplitude} from '../../../features/tools';
let EventTracker = new Amplitude();

export default function FlutterWaveButton(props) {
  let dispatch = useDispatch();
  let [paying, setPaying] = useState(false)
  let {
    transaction_id,
    amount,
    currency
  } = props.transaction;
  let {
    email,
    full_name
  } = props.user;
  const config = {
    public_key: 'FLWPUBK-cd8511a2a000b0732917e31889500b27-X',
    tx_ref:transaction_id,
    amount,
    currency,
    payment_options: 'card,banktransfer,barter,qr,account,mobilemoney,ussd',
    customer: {
      email,
      name:full_name,
    },
    customizations: {
      title: full_name || 'Wallet Funding',
      description: 'Fund your Marketplace Wallet',
      logo: 'https://nftmarket.africa/static/media/nftmarketafrica_icon.088d6de5.png',
    },
  };
  const handleFlutterPayment = useFlutterwave(config);
  return (
    <div className="d-grid gap-2">
    {
      !paying?
      <button
        style={{zIndex:1}}
        className="btn btn-light btn-lg d-flex justify-content-between align-items-center"
        onClick={() => {
          EventTracker.logEvent('PAY_WITH_FLUTTERWAVE')
          setPaying(true)
          handleFlutterPayment({
            callback: (response) => {
               EventTracker.logEvent('PAY_WITH_FLUTTERWAVE_SUCCESS')
               dispatch(getTransaction(transaction_id))
                closePaymentModal() // this will close the modal programmatically
            },
            onClose: () => {setPaying(false)},
          });
        }}
      >
        <span>Pay with Flutterwave</span>
        <img className="rounded avatar avatar-sm" alt="flutterwave payment gateway" src={flutterwave}/>
      </button>
      :
      <div className="d-grid">
        <button
          className="btn btn-light btn-lg d-flex justify-content-between align-items-center">
          <span>{" "}Processing payment</span>
          <div className="spinner-border spinner-border-sm" role="status">
            <span className="sr-only">...</span>
          </div>
        </button>
      </div>
    }
    </div>
  );
}
