import axios from 'axios';
import {baseURL} from '../../config';


let galleryClient = axios.create({
  baseURL,
  timeout:30000
})

class GalleryApi {

  async createGallery(data){
    return await galleryClient.post(`/gallery/create`, data.details, {headers:{'x-token':data.token}});
  }

  async getGalleries(token){
    return await galleryClient.get(`/gallery`, {headers:{'x-token':token}});
  }

  async getGalleryItems(data){
    return await galleryClient.post(`/gallery/items`, {gallery_id:data.gallery_id}, {headers:{'x-token':data.token}});
  }

  async getPrivateKeys(data){
    return await galleryClient.post(`/gallery/get-keys`, {gallery_id:data.gallery_id}, {headers:{'x-token':data.token}});
  }

  async getMnemonic(data){
    return await galleryClient.post(`/gallery/get-keys`, {gallery_id:data.gallery_id, type:'mnemonic'}, {headers:{'x-token':data.token}});
  }
}

export default GalleryApi;
//@NFTmarket.africa 2022
