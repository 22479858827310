import signupcover from '../assets/images/blog/signup.png';
import buycover from '../assets/images/blog/buy.png';
import sellcover from '../assets/images/blog/sell.png';
import unbrokencover from '../assets/images/blog/unbroken.png';


let utility_prices = {
  iframe:150,
  merch:50,
  physical_work:0
}

let utilities = {
  iframe:{
    include:false,
    slug:'iframe'
  },
  merch:{
    include:false,
    slug:'merch'
  },
  physical_work:{
    include:false,
    slug:'physical_work'
  }
}
// let utilities = [
//   {
//     name:"NFT iFrame",
//     slug:'nft-iframe',
//     options:['basic'],
//     prices:{
//       "basic":300
//     }
//   },
//   {
//     name:"Merchandise",
//     slug:'merch',
//     options:['1-pack','2-pack','3-pack'],
//     prices:{
//       "1-pack":150,
//       "2-pack":200,
//       "3-pack":300
//     }
//   },
//   {
//     name:"Physical Work",
//     slug:'physical-work',
//     options:['art-fair', 'self-deliver'],
//     prices:{
//
//     }
//   }
// ];



let course = {
  name:"Fundamentals of NFTs",
  timeframe:"10 videos (1 hour and 46 minutes)",
  description:"This course is aimed at teaching the basic and fundamentals of NFT. If you are new to NFTs and you want to jumpstart into creating, buying or selling your own NFT, this is the right course for you.",
  what_you_will_learn:[
    "The basics of NFTs",
    "The basics of Blockchain",
    "How to create and sell NFTS",
    "How to discover and buy NFTs"
  ],
  rating:"22",
  reviews:[
    "I have really enjoyed this course and learnt alot."
  ],
  lessons:[
    {
      title:"What is NFT?",
      youtube_id:"004sfRJ6Ixg",
      locked:false,
      slug:"what-is-nft",
      time:"2m 46 sec",
      complete_percentage:0,
      author:{
        name:"Dumo Qwaqwa",
        username:"dumo",
        avatar:"https://firebasestorage.googleapis.com/v0/b/nftmarket-africa.appspot.com/o/avatar%2F656download.png?alt=media&token=ef7aab97-889f-43eb-91cd-63bb6ff37902"
      },
      transcript:[
        {
          title:"Introduction[00:00:00]",
          details:"Hello world, I am Dumo from NFT Market Africa,]. This is the first of a series to teach you about the basics and fundamentals of NFT."
        },
        {
          title:"Summary[00:01:13]",
          details:"We will be on the journey with you from minting, to selling and even owning your own collection of NFTs. Lets break it down"
        }
      ],
      faq:[
        {
          q:"What is the meaning of NFT?",
          a:"NFT stands for Non-Fungible Token"
        },
        {
          q:"What is the difference between Fungible and Non-Fungible?",
          a:"Fungible means many that can be exchanged for each other while Non-Fungible means it is one and unique, therefore cannot be exchanged for another."
        }
      ]
    },
    {
      title:"What is Blockchain?",
      youtube_id:"F03X_pqFH_Y",
      locked:false,
      slug:"what-is-blockchain",
      time:"2m 06 sec",
      complete_percentage:10,
      author:{
        name:"Ayodeji Adediran",
        username:"donjayodeji",
        avatar:"https://firebasestorage.googleapis.com/v0/b/nftmarket-africa.appspot.com/o/avatar%2F146a311ec8c-3b2d-4deb-b221-06b181b65e3c.JPG?alt=media&token=59cebdfd-b71d-4af7-bf63-2ef5bde32d1f"
      },
      transcript:[
        {
          title:"Introduction[00:00:00]",
          details:"Hello, I am Ayodeji from NFT Market Africa. I'll be talking to you about blockchain."
        },
        {
          title:"Summary[00:01:13]",
          details:"Although, the name blockchain might seem complicated. It really is this simple. Just as its name sounds, BLOCK-CHAIN. Blockchain are records of information or blocks of information that are chained togther, and these information are verifable and open to the public, this makes it impossble to cheat, maniputale or change the system."
        }
      ],
      faq:[
        {
          q:"What is Blockchain?",
          a:"Blockchain are records of information or blocks of information that are chained togther, and these information are verifable and open to the public, this makes it impossble to cheat, maniputale or change the system."
        },
        {
          q:"What are the examples of Blockchain?",
          a:"Bitcoin, Ethereum, Polygon, Solana Blockchain e.t.c"
        }
      ]
    },
    {
      title:"What is Ethereum?",
      youtube_id:"BoMJ0r13wHg",
      locked:false,
      slug:"what-is-ethereum",
      time:"2m 04 sec",
      complete_percentage:20,
      author:{
        name:"Dumo Qwaqwa",
        username:"dumo",
        avatar:"https://firebasestorage.googleapis.com/v0/b/nftmarket-africa.appspot.com/o/avatar%2F656download.png?alt=media&token=ef7aab97-889f-43eb-91cd-63bb6ff37902"
      },
      transcript:[
        {
          title:"Introduction[00:00:00]",
          details:"Hello, so what is Ethereum?. Ethereum is a Peer to Peer Blockchain technology that is used to create the cryptocurrecy called Ether, it also facilitates the creation of smart contracts and decentralized applications called Dapps"
        },
        {
          title:"Summary[00:00:23]",
          details:"Ethereum has the second largest crypto market cap currently valued at 366 billion dollars, it only comes second to Bitcoin, however ethereum is more than just a cryptocurrency, it was primarily built to be a programmable blockchain on which smart contracts can be executed and developers can build other applications."
        }
      ],
      faq:[
        {
          q:"What is Ethereum?",
          a:"Ethereum is a Peer to Peer Blockchain technology that is used to create the cryptocurrecy called Ether, it also facilitates the creation of smart contracts and decentralized applications called Dapps."
        },
        {
          q:"What is a smart contract?",
          a:"A smart contract is a self executing code on the blockchain that automatically executes an agreement once all requirements are met."
        }
      ]
    },
    {
      title:"What is Gas?",
      youtube_id:"lixn6IbhTmo",
      locked:false,
      slug:"what-is-gas",
      time:"0m 54 sec",
      complete_percentage:30,
      author:{
        name:"Ayodeji Adediran",
        username:"donjayodeji",
        avatar:"https://firebasestorage.googleapis.com/v0/b/nftmarket-africa.appspot.com/o/avatar%2F146a311ec8c-3b2d-4deb-b221-06b181b65e3c.JPG?alt=media&token=59cebdfd-b71d-4af7-bf63-2ef5bde32d1f"
      },
      transcript:[
        {
          title:"Introduction[00:00:00]",
          details:"In Ethereum all transations that occur require a fee called gas"
        },
        {
          title:"Summary[00:00:23]",
          details:"Gas is a fee that is being paid to miners to compensate for the computing power of their system to valiate the transactions on the blockchain."
        }
      ],
      faq:[
        {
          q:"What is Gas?",
          a:"Gas is a transaction fee on the blockchain to process a transaction."
        },
        {
          q:"Why does a transaction require gas?",
          a:"Gas fees are used to pay the miners or validators for validating transactions on the blockchain."
        }
      ]
    },
    {
      title:"Proof of Work vs Proof of Stake?",
      youtube_id:"0Ek4W1qYlhs",
      locked:false,
      slug:"pow-vs-pos",
      time:"1m 42 sec",
      complete_percentage:40,
      author:{
        name:"Dumo Qwaqwa",
        username:"dumo",
        avatar:"https://firebasestorage.googleapis.com/v0/b/nftmarket-africa.appspot.com/o/avatar%2F656download.png?alt=media&token=ef7aab97-889f-43eb-91cd-63bb6ff37902"
      },
      transcript:[
        {
          title:"Introduction[00:00:00]",
          details:"There are two methods used to validate transactions on the blockchain which are proof of work and proof of stake."
        },
        {
          title:"Summary[00:01:13]",
          details:"Proof of work is more decentralized as anyone can participate in validating the transactions on the Blockchain, whereby Proof of stake is somewhat decentralized because it requires a set amount of funds to qualify and become a validator"
        }
      ],
      faq:[
        {
          q:"Why is Proof of Work more expensive?",
          a:"Due to the unlimited amount of miners, more gas is needed to compensate the miners for their computational power"
        },
        {
          q:"Which mechanism is better?",
          a:"Although Proof of Stake is not as decentralized as Proof of Work, but it has proven to be more scalable, requires less gas and is environmental friendly"
        }
      ]
    },
    {
      title:"How to create an NFT",
      locked:true,
      time:'0m 0 sec',
      slug:'how-to-create-nft'
    },
    {
      title:"What is Minting?",
      locked:true,
      time:'0m 0 sec',
      slug:'what-is-minting'
    },
    {
      title:"What can be sold as an NFT?",
      locked:true,
      time:'0m 0 sec',
      slug:'what-can-you-sell'
    },
    {
      title:"How to sell your NFT?",
      locked:true,
      time:'0m 0 sec',
      slug:'how-to-sell'
    }
  ]
}









let articles =[
  {
    title:"How to sign up",
    cover:signupcover,
    slug:"how-to-register",
    summary:"Learn how to register on NFT Market Africa.",
    type:"Guide",
    content:
    <div className="row justify-content-center">
      <div className="col-xl-10 col-lg-10 col-md-12 col-12 mb-6">
        <h5 className="mb-3">Complete these 5 steps.</h5>
        <ol className="ps-3">
          <li>Connect your Blockchain wallet</li>
          <li>Choose a username</li>
          <li>Update your profile</li>
        </ol>
      </div>
    </div>,
    updated_at:"1 week ago",
    read_time_minutes:"12",
    author:"0x0f431130a33158217277cd18b5083cd126cf8fa0"
  },
  {
    title:"How to sell your NFT",
    cover:sellcover,
    slug:"how-to-sell",
    summary:"Learn how to sell NFTs on NFT Market Africa.",
    type:"Guide",
    content:
    <div className="row justify-content-center">
      <div className="col-xl-10 col-lg-10 col-md-12 col-12 mb-6">
        <h5 className="mb-3">Complete these 5 steps.</h5>
        <ol className="ps-3">
          <li>Import an NFT to your gallery</li>
          <li>Select your NFT and click on Sell Item</li>
          <li>Enter additional details and pricing</li>
          <li>Optionally <strong>add utility</strong> to your item</li>
          <li>List item to your storefront</li>
          <li>Receive a sale and complete your order</li>
          <li>Get paid in your preferred currency</li>
        </ol>
      </div>
    </div>,
    updated_at:"1 week ago",
    read_time_minutes:"5",
    author:"0x0ccf174246975ce98743c504a54e371badd478f3"
  },
  {
    title:"How to buy an NFT",
    slug:"how-to-buy",
    cover:buycover,
    summary:"Learn how to purchase NFTs.",
    type:"Guide",
    content:
    <div className="row justify-content-center">
      <div className="col-xl-10 col-lg-10 col-md-12 col-12 mb-6">
        <h5 className="mb-3">Complete these 5 steps.</h5>
        <ol className="ps-3">
          <li>Find an NFT to purchase</li>
          <li>Click on Buy to continue</li>
          <li>Select your gallery address or enter a different address to receive NFT</li>
          <li>Make payment from your balance</li>
          <li>NFT is transferred to your specified address</li>
          <li>Claim Utility if included in NFT</li>
        </ol>
      </div>
    </div>,
    updated_at:"1 week ago",
    read_time_minutes:"5",
    author:"0x0ccf174246975ce98743c504a54e371badd478f3"
  },
  {
    title:"The Unbroken Collection",
    cover:unbrokencover,
    slug:"unbroken-collection",
    summary:"A collection of 20 NFTs by 20 artists from 20 perspectives that expresses the challenges and overcomings of us as humans.",
    type:"Article",
    content:
    <>
      <div class="d-flex align-items-start mt-4">
        <div>
          <hr className="mt-lg-10 mb-lg-6 my-md-6"/>
          <blockquote className="blockquote text-center ">
            <p className="text-primary font-italic fw-semi-bold lh-lg h1 px-2 px-lg-14">
              "To be broken is to have no hope,<br/>
              To have no hope is to have no life,<br/>
              If we breath, we are unbroken!"
            </p>
            <footer className="blockquote-footer mt-3 text-muted">
              <cite title="Source Title">The Unbroken Collection</cite>
            </footer>
          </blockquote>
          <hr className="mt-lg-6 mb-lg-10 my-md-6"/>
          <p>
            As humans we have been through certain situations in
            life that has shaped how we live and who we have become today.
            Some of them we forget easily, while some remains evergreen 🍀.
          </p>
          <p>
            It is no denial that the 2020 pandemic has reshaped the world in the ways we live and socialize today.
          </p>
          <p>
            We all have our past, we all have stories to share.
          </p>
          <p> If we breath, <strong>we are unbroken!</strong>.</p>
          <h3>What's the purpose of this Collection?</h3>
          <p>
            The Unbroken Collection is NFT Market Africa's first NFT Collection
            which is aimed at giving creators a medium to express themselves.
            The collection will enable us to build our community and establish
            our value of a platform for creators by creators.
          </p>
        </div>
    </div>
    <hr className="mt-lg-6 mb-lg-10 my-md-6"/>
    <div className="row">
      <div className="offset-md-2 col-md-8 col-12">
        <div className="mb-4 mt-6">
          <h3 className="mb-0 fw-semi-bold">How to Feature</h3>
        </div>
        <div className="accordion accordion-flush" id="accordionExample3">
          <div className="border p-3 rounded-3 mb-2" id="headingTwelve">
            <h3 className="mb-0 fs-4">
              <a
                href="#/"
                className="d-flex align-items-center text-inherit
                  text-decoration-none"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwelve"
                aria-expanded="false"
                aria-controls="collapseTwelve"
              >
                <span className="me-auto">
                  Who can feature in this Collection?
                </span>
                <span className="collapse-toggle ms-4">
                  <i className="fe fe-chevron-down text-muted" />
                </span>
              </a>
            </h3>
            <div
              id="collapseTwelve"
              className="collapse"
              aria-labelledby="headingTwelve"
              data-bs-parent="#accordionExample3"
            >
              <div className="pt-2">
                All artists are eligible to submit their entry and stand a chance to be featured in The Unbroken Collection.
              </div>
            </div>
          </div>
          {/* Card  */}
          {/* Card header  */}
          <div className="border p-3 rounded-3 mb-2" id="headingThirteen">
            <h3 className="mb-0 fs-4">
              <a
                href="#/"
                className="d-flex align-items-center text-inherit
                  text-decoration-none"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThirteen"
                aria-expanded="false"
                aria-controls="collapseThirteen"
              >
                <span className="me-auto">
                  How many entries are accepted?
                </span>
                <span className="collapse-toggle ms-4">
                  <i className="fe fe-chevron-down text-muted" />
                </span>
              </a>
            </h3>
            <div
              id="collapseThirteen"
              className="collapse"
              aria-labelledby="headingThirteen"
              data-bs-parent="#accordionExample3"
            >
              <div className="pt-3">
                There is no limit to the numbers of accepted entry. However only 20 artworks will be selected.
              </div>
            </div>
          </div>
          {/* Card  */}
          {/* Card header  */}
          <div className="border p-3 rounded-3 mb-2" id="headingFourteen">
            <h3 className="mb-0 fs-4">
              <a
                href="#/"
                className="d-flex align-items-center text-inherit
                  text-decoration-none active"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFourteen"
                aria-expanded="false"
                aria-controls="collapseFourteen"
              >
                <span className="me-auto">How do I submit my entry?</span>
                <span className="collapse-toggle ms-4">
                  <i className="fe fe-chevron-down text-muted" />
                </span>
              </a>
            </h3>
            <div
              id="collapseFourteen"
              className="collapse"
              aria-labelledby="headingFourteen"
              data-bs-parent="#accordionExample3"
            >
              <div className="pt-3">
                Entries are accepted on the Unbroken Collection's Discord channel. <a href="https://discord.gg/FDpQwShFpV" target="blank_">Click here</a> to submit your entry.
              </div>
            </div>
          </div>
          {/* Card  */}
          {/* Card header  */}
          <div className="border p-3 rounded-3 mb-2" id="headingFifteen">
            <h3 className="mb-0 fs-4">
              <a
                href="#/"
                className="d-flex align-items-center text-inherit
                  text-decoration-none active"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFifteen"
                aria-expanded="false"
                aria-controls="collapseFifteen"
              >
                <span className="me-auto">What is the benefit for artists?</span>
                <span className="collapse-toggle ms-4">
                  <i className="fe fe-chevron-down text-muted" />
                </span>
              </a>
            </h3>
            <div
              id="collapseFifteen"
              className="collapse"
              aria-labelledby="headingFifteen"
              data-bs-parent="#accordionExample3"
            >
              <div className="pt-3">
                <ul>
                  <li>One-Time Ticket to our Art Fair</li>
                  <li>80% Revenue from Sale</li>
                  <li>5% Royalty from Secondary Sales</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="row pt-6 justify-content-center align-items-center">
      <div className="col-auto">
        <a href="https://discord.gg/FDpQwShFpV" target="blank_" className="btn btn-primary">
          Submit Entry
        </a>
      </div>
    </div>
    </>,
    updated_at:"1 week ago",
    read_time_minutes:"10",
    author:"0x0f431130a33158217277cd18b5083cd126cf8fa0"
  }
]

export {
  course,
  articles,
  utilities,
  utility_prices
}
//@nftmarket.africa 2022
