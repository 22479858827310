import React, {useState, useEffect} from 'react';
import blank from '../../assets/images/tools/blank_image.png';
import {getURL} from '../../features/tools';

export default function PostCardContent(props){
  let [data, setData] = useState({media_url:blank});
  let {metadata, token_address, token_id} = props.data.alchemy_data;
  useEffect(()=>{
    if(metadata){
      let parsed_metadata = JSON.parse(metadata)
      setData( prev => {
        return {
          ...prev,
          ...parsed_metadata,
          token_address,
          token_id,
          media_url:getURL(parsed_metadata.image)
        }
      })
    }else{
      console.log('media not detected');
    }
  },[metadata, token_id, token_address])

  let mediaTypeIsVideo =(url)=> {
    if(url){
      let url_ = new URL(url)
      let {pathname} = url_
      //get url extension
      let type = pathname.split('.').pop()
      if(type.match('mp4')){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }

  return (
    <div
    className="card-body text-center bg-white m-auto clickable"
    style={{...props.resolution}}
    >
    {
      mediaTypeIsVideo(data.media_url)?
      <video
      className="rounded-4"
      alt="NFT"
      type="video/mp4"
      loop
      autoPlay
      muted={true}
      src={data.media_url}
      style={{height: '100%', width: '100%', objectFit: 'contain'}}
      />
      :
      <img
      className="rounded-4"
      alt="NFT"
      src={data.media_url}
      style={{height: '100%', width: '100%', objectFit: 'contain'}}
      />
    }
    {
      props.showLikes?
      <span
        className="position-absolute mt-2 ms-n4"
        data-bs-toggle="tooltip"
        data-placement="top"
        title="Likes"
      >
        <span className="d-block rounded rounded-3 bg-is-transparent p-1 text-white fw-bold"><i className="bi bi-heart fs-6 fw-bold"/> {props.data.likes}</span>
      </span>
      :
      null    
    }
      <div className="card-img-overlay"></div>
    </div>
  )
}
//@NFTmarket.africa 2022
