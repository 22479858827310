import React from "react";
import {Navigate } from "react-router-dom";
import {useSelector} from 'react-redux';


function Controlled({destination, children}){
  let {loggedIn} = useSelector( state => state.auth.value)
  return !loggedIn? children : <Navigate to={destination} />;
}

export default Controlled;

//@NFTmarket.africa 2022
