import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import WalletApi from '../../service/api/wallet_api';
import Cookies from 'js-cookie';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
let api = new WalletApi();
Notify.init({
  position:'left-bottom'
})

export const getWallets = createAsyncThunk(
  'wallet/GET_WALLETS',
  async(arg, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let data = {
          token
        }
        let response = await api.getWallets(data);
        return response.data;
      }else{
        throw thunkAPI.rejectWithValue('Token is required');
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue("Network Error");
      }
    }
  }
)



export const getTransaction = createAsyncThunk(
  'wallet/GET_A_TRANSACTION',
  async(transaction_id, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let data = {
          token,
          transaction_id
        }
        if(transaction_id!=="start"){
          let response = await api.getTransaction(data);
          return response.data;
        }else{
          return true
        }
      }else{
        throw thunkAPI.rejectWithValue('Token is required');
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue("Network Error");
      }
    }
  }
)


export const getTransactions = createAsyncThunk(
  'wallet/GET_TRANSACTIONS',
  async(arg, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let data = {
          token
        }
        let response = await api.getTransactions(data);
        return response.data;
      }else{
        throw thunkAPI.rejectWithValue('Token is required');
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue("Network Error");
      }
    }
  }
)

export const activateWallet = createAsyncThunk(
  'wallet/ACTIVATE',
  async(arg, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let data = {
          token
        }
        api.activateWallet(data).then(()=>{
          thunkAPI.dispatch(getWallets())
          return true
        })
      }else{
        throw thunkAPI.rejectWithValue('Token is required');
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue("Network Error");
      }
    }
  }
)


export const createTransaction = createAsyncThunk(
  'wallet/CREATE_TRANSACTION',
  async(data, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let request = {
          token,
          request:{
            ...data.request
          },
          headers:{
            ...data.headers
          }
        }
        let response = await api.createTransaction(request);
        return response.data;
      }else{
        throw thunkAPI.rejectWithValue('Token is required');
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue("Network Error");
      }
    }
  }
)

export const walletSlice = createSlice({
  name:"wallet",
  initialState:{
    value:{
      data:[],
      transaction:null,
      transactions:[],
      transaction_id:'',
      activating:false,
      loading_wallets:false,
      loading_transactions:false,
      error:null
    }
  },
  reducers:{
  },
  extraReducers:(builder)=>{
    builder.addCase(getWallets.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading_wallets:true,
        error:null
      }
    })
    builder.addCase(getWallets.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        data:action.payload.data,
        loading_wallets:false,
        activating:false,
        error:null
      }
    })
    builder.addCase(getWallets.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        activating:true,
        error:null
      }
    })
    builder.addCase(createTransaction.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading_transactions:true,
        error:null
      }
    })
    builder.addCase(createTransaction.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        transaction_id:action.payload,
        loading_transactions:false,
        error:null
      }
    })
    builder.addCase(createTransaction.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading_transactions:false,
        error:action.payload
      }
    })
    builder.addCase(getTransactions.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading_transactions:true,
        error:null
      }
    })
    builder.addCase(getTransactions.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        transactions:action.payload,
        loading_transactions:false,
        error:null
      }
    })
    builder.addCase(getTransactions.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        transactions:null,
        loading_transactions:false,
        error:action.payload
      }
    })
    builder.addCase(getTransaction.pending, (state, action)=> {
      state.value = {
        ...state.value,
        transaction:null,
        loading_transactions:true,
        error:null
      }
    })
    builder.addCase(getTransaction.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        transaction:action.payload,
        loading_transactions:false,
        error:null
      }
    })
    builder.addCase(getTransaction.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading_transactions:false,
        error:action.payload
      }
    })
    builder.addCase(activateWallet.pending, (state, action)=> {
      state.value = {
        ...state.value,
        activating:true,
        error:null
      }
    })
    builder.addCase(activateWallet.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        activating:false,
        error:null
      }
    })
    builder.addCase(activateWallet.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        activating:false,
        error:action.payload
      }
    })
  }
})
export const {enter_action_here} = walletSlice.actions;
export default walletSlice.reducer;

//@NFTmarket.africa 2022
