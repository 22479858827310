import axios from 'axios';
import {baseURL} from '../../config';

let dataClient = axios.create({
  baseURL,
  timeout:30000
})

class DataApi {
  async getData(){
    return await dataClient.get('/data');
  }

  async getPlatform(){
    return await dataClient.get('/data/platform');
  }
}

export default DataApi;
//@NFTmarket.africa 2022
