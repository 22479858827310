import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import './assets/css/theme.css';
import './assets/css/custom.css';
import './assets/fonts/feather/feather.css';
import '././assets/libs/bootstrap-icons/font/bootstrap-icons.css';
import './assets/libs/@mdi/font/css/materialdesignicons.min.css';
import './assets/libs/dropzone/dist/dropzone.css';
import './assets/libs/bootstrap-select/dist/css/bootstrap-select.min.css';
import './assets/libs/datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import {
  // HowitWorks,
  // Faq,
  // Learn,
  // Articles,
  Signin,
  Discover,
  ViewItem,
  EditItem,
  Storefront,
  Dashboard,
  ListItem,
  // ProfileView,
  // ProfileAccountView,
  Profile,
  Sales,
  Wallet,
  FundWallet,
  Gallery,
  AddNewItem,
  CreateGallery,
  Buy,
  Checkout,
  Order,
  Orders,
  SaleView,
  VerifyProfile,
  Contact,
  TermsAndConditions,
  Privacy,
  // Litepaper,
  // Roadmap,
  // AboutUs,
  // Utility,
  ScanProduct
} from './container';
// import LandingPage from './container/landing';
import {
  PostModal,
  MenuModal,
  SupportModal
} from './component/modal';
import Protected from './middleware/private';
import Controlled from './middleware/controlled';
import './config';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/*" element={<Signin/>} />
        <Route path="/shop" element={<Discover/>} />
        <Route path="/contact/:contact_type" element={<Contact/>} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions/>}/>
        <Route path="/privacy-policy" element={<Privacy/>}/>
        <Route path="/signin" element={<Controlled destination="/dashboard"><Signin/></Controlled>}/>
        <Route path="/p/:post_id" element={<ViewItem/>}/>
        <Route path="/buy/:post_id" element={<Buy/>}/>
        <Route path="/checkout/:post_id" element={<Protected><Checkout/></Protected>}/>
        <Route path="/order/:order_id" element={<Order/>}/>
        <Route path="/sale/:order_id" element={<Protected><SaleView/></Protected>} />
        <Route path="/checkout/:post_id/review" element={<Checkout/>}/>
        <Route path="/storefront" element={<Protected><Storefront/></Protected>}/>
        <Route path="/dashboard" element={<Protected><Dashboard/></Protected>}/>
        <Route path="/draft" element={<Protected><ListItem/></Protected>}/>
        <Route path="/draft/:post_id" element={<Protected><EditItem/></Protected>}/>
        <Route path="/profile/edit" element={<Protected><Profile/></Protected>}/>
        <Route path="/profile/verification" element={<Protected><VerifyProfile/></Protected>}/>
        <Route path="/orders" element={<Protected><Orders/></Protected>}/>
        <Route path="/sales" element={<Protected><Sales/></Protected>}/>
        <Route exact path="/wallet" element={<Protected><Wallet/></Protected>}/>
        <Route path="/tx/:transaction_id" element={<Protected><FundWallet/></Protected>}/>
        <Route index path="/gallery" element={<Protected><Gallery/></Protected>}/>
        <Route path="/gallery/add-item" element={<Protected><AddNewItem/></Protected>}/>
        <Route path="/gallery/create" element={<Protected><CreateGallery/></Protected>}/>
        <Route path="/scan/:product_id" element={<ScanProduct/>}/>
      </Routes>
      <PostModal/>
      <MenuModal/>
      <SupportModal/>
    </BrowserRouter>
  );
}

export default App;
//@nftmarket.africa 2022
