import React, {useState, useEffect} from 'react';
import Cookies from 'js-cookie';
import {useDispatch} from 'react-redux';
import {likePost} from '../../features/post/postSlice';
import postApi from '../../service/api/post_api';
import {Amplitude} from '../../features/tools';
const api = new postApi();
const EventTracker = new Amplitude();

export default function Like(props){
  let dispatch = useDispatch()
  let [properties, setProperties] = useState({
    toggled:false,
    like:false
  })

  useEffect(()=>{
    let token = Cookies.get('c-token')
    let data = {
      post_id:props.data.id,
      token
    }
    if(token && data.post_id){
      api.getLikes(data).then((response)=>{
        setProperties(function(prev){
          return {...prev, like:response.data.liked}
        })
      }).catch((err)=>{
        console.error(err);
      })
    }
    return function cleanUp(){
      setProperties({})
    }
  },[props.data.id])

  let handleLikeToggle=()=>{
    switch (properties.like) {
      case true:
        setProperties({
          ...properties,
          like:false
        })
        dispatch(likePost({type:'dislike', post_id:props.data.id}))
        break;
      case false:
        setProperties({
          ...properties,
          like:true
        })
        dispatch(likePost({type:'like', post_id:props.data.id}))
        break;
      default:
    }
  }

  return(
    <div className="">
      <span className="fs-3 clickable" onClick={handleLikeToggle}>
      <button
        className="btn btn-white btn-icon border-0 rounded-circle"
        type="button"
        onClick={()=> EventTracker.logEvent('LIKED_AN_ITEM')}
      >
      {
        properties.like?
        <i className="text-danger fas fa-heart fs-3"></i>
        :
        <i className="bi bi-heart fs-3"></i>
      }
      </button>
      </span>
    </div>
  )
}
