import React, {useEffect, useState, useCallback} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getUserProfile} from '../features/user/userSlice';
import {getGalleries, getGalleryItems} from '../features/gallery/gallerySlice';
import {createPost} from '../features/post/postSlice';
import {LandingWrapper} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Gallery",
  meta_title:"NFT Market Africa - Gallery",
  description:"Your NFT gallery"
}

const SellItem =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('SELL_AN_ITEM')
    }
  },[pageLoaded, setPageLoaded])
  /********************************/
  let dispatch = useDispatch()
  let navigate = useNavigate()
  let [currentGallery, setCurrentGallery] = useState(0)
  const [nftDetails, setNftDetails] = useState({
    contract_address:null,
    token_id:null,
    chain:"ethereum"
  });
  let user = useSelector(state => state.user.value)
  let post = useSelector(state => state.post.value)
  let galleries = useSelector( state => state.gallery.value)

  let {data, items, loading} = galleries;
  let {creating} = post

  useEffect(()=>{
    if(user && user.profile && !user.profile.profile_verified){
      navigate('/dashboard')
    }
  },[user, navigate])

  useEffect(()=>{
    dispatch(getUserProfile())
    dispatch(getGalleries()).then((response)=>{
      if(response.meta.requestStatus === 'fulfilled' && !response.payload.length){
        navigate('/gallery/create')
      }
    })
  },[dispatch, navigate])

  useEffect(()=>{
    if(data && data.length){
      dispatch(getGalleryItems(data[currentGallery]['id']))
    }
  },[dispatch, data, currentGallery])

/********************************/
  let handleSelectNft = useCallback((e)=> {
    if(items&&items.result&&items.result.length){
      let selectedNft = items.result.filter((item) => item.metadata)[e.target.value];
      if(selectedNft){
        setNftDetails({
          contract_address:selectedNft.token_address,
          token_id:selectedNft.token_id,
          chain:data?data[currentGallery]['chain']:"ethereum"
        })
      }
    }
  },[items, currentGallery, data])

  useEffect(()=>{
    if(items&&items.result&&items.result.length){
      handleSelectNft({target:{value:0}})
    }
  },[items, handleSelectNft])

  let handleToggleGallery =(e)=>{
    let {value} = e.target;
    setCurrentGallery(value)
  }

  let handleSubmit=(e)=>{
    e.preventDefault();
    if(nftDetails.contract_address && nftDetails.token_id){
      dispatch(createPost(nftDetails)).then((response)=>{
        let {payload, meta} = response;
        if(meta && meta.requestStatus === "fulfilled"){
           EventTracker.logEvent('CREATE_ITEM_POST_SUCCESS')
          navigate(`/draft/${payload}`)
        }else{
         EventTracker.logEvent('CREATE_ITEM_POST_ERROR')
        }
      })
    }
  }

/********************************/
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 bg-white min-vh-75">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/storefront">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
          </div>
          <div className="bs-stepper py-3">
            <div className="row">
              <div className="offset-lg-1 col-lg-10 col-md-12 col-12">
                <div className="bs-stepper-header shadow-sm rounded-4" role="tablist">
                  <div className="step active" data-target="#test-l-1">
                    <button
                      type="button"
                      className="step-trigger"
                      role="tab"
                      id="courseFormtrigger1"
                      aria-controls="test-l-1"
                      aria-selected={true}
                    >
                      <span className="bs-stepper-circle">1</span>
                      <span className="bs-stepper-label">Select your Item</span>
                    </button>
                  </div>
                  <div className="bs-stepper-line" />
                  <div className="step" data-target="#test-l-2">
                    <button
                      type="button"
                      className="step-trigger"
                      role="tab"
                      id="courseFormtrigger2"
                      aria-controls="test-l-2"
                    >
                      <span className="bs-stepper-circle">2</span>
                      <span className="bs-stepper-label">Item Details</span>
                    </button>
                  </div>
                  <div className="bs-stepper-line" />
                  <div className="step" data-target="#test-l-3">
                    <button
                      type="button"
                      className="step-trigger"
                      role="tab"
                      id="courseFormtrigger3"
                      aria-controls="test-l-3"
                    >
                      <span className="bs-stepper-circle">3</span>
                      <span className="bs-stepper-label">Complete</span>
                    </button>
                  </div>
                </div>
                <div className="mt-5">
                  <form onSubmit={handleSubmit}>
                    <div
                      id="test-l-1"
                      className="show fade"
                    >
                      {/* Card */}
                      <div className="card mb-3 rounded-4">
                        <div className="card-header border-bottom px-4 py-3">
                          <h4 className="mb-0">Select an Item</h4>
                        </div>
                        {/* Card body */}
                        <div className="card-body">
                          <div className="mb-3">
                            <label className="form-label">Gallery</label>
                            <select className="form-select rounded-4 form-select-lg" data-width="100%" onChange={handleToggleGallery} onClick={()=> EventTracker.logEvent('SELECTED_A_GALLERY')}>
                            {
                              galleries.data&&galleries.data.length?
                              galleries.data.map( (gallery, i)=>
                              <option key={i} value={i}>{gallery.name}</option>
                              )
                              :
                              <option>Select a gallery</option>
                            }
                            </select>
                          </div>
                          <div className="mb-3">
                            <label className="form-label">Select item</label>
                            <select className="form-select rounded-4 form-select-lg" onChange={handleSelectNft} data-width="100%" onClick={()=> EventTracker.logEvent('SELECTED_A_GALLERY_ITEM')}>
                              {
                                items&&items.result&&items.result.length?
                                items.result.filter((item) => item.metadata).map( (nft, i)=>
                                  <option key={i} value={i}>{JSON.parse(nft.metadata).name}</option>
                                )
                                :
                                <option>You have no NFT</option>
                              }
                            </select>
                            <small>
                              Select one of your NFTs
                            </small>
                          </div>
                        </div>
                      </div>
                      {
                        creating?
                        <button className="btn btn-primary brand-button" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {" "}Verifying...
                        </button>
                        :
                        (loading)?
                        <button className="btn btn-primary brand-button" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {" "}Please wait...
                        </button>
                        :
                        <button type="submit" className="btn btn-primary brand-button" disabled={!nftDetails.contract_address || !nftDetails.token_id}>
                          Next
                        </button>
                      }
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default SellItem;

//@ NFTmarket.africa 2022
