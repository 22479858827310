import React, {useEffect, useState} from 'react';
import {useParams, Link} from 'react-router-dom';
import Pusher from 'pusher-js';
import {retrieveOrder} from '../features/checkout/checkoutSlice';
import {useDispatch, useSelector} from 'react-redux';
import loading from '../assets/animations/brand_loading.json';
import {Amplitude} from '../features/tools';
import {
  LandingWrapper
} from './wrapper';
// import Avatar from '../component/ui_kits/avatar';
// import PostCardContent from '../component/post_card/card_content';
// import AnimationCard from '../component/ui_kits/animation/animation_card';
import Media from '../component/cards/media';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Order",
  meta_title:"NFT Market Africa - Order",
  description:"Your purchase receipt"
}


const Order =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_ORDER_PAGE')
    }
  },[pageLoaded, setPageLoaded])

  let dispatch = useDispatch()
  let {order_id} = useParams()
  let [meta_data, setMetadata] = useState(false)
  let checkout = useSelector(state => state.checkout.value)
  let {post, order} = checkout.retrieved_order;

  const pusher = new Pusher('103af5a922d44a465a98', {
    cluster: 'us2',
    encrypted: true
  });
  const channel = pusher.subscribe('orders-channel');

  useEffect(()=>{
    channel.bind( order_id, data => {
      dispatch(retrieveOrder(data.message))
    });
  },[dispatch, order_id, channel])


  useEffect(()=>{
    dispatch(retrieveOrder(order_id))
  },[dispatch, order_id])

  useEffect(()=>{
    if(post && post.alchemy_data && !metadata){
      let {alchemy_data} = post;
      let {metadata} = alchemy_data;
      metadata = JSON.parse(metadata)
      setMetadata({...meta_data, ...metadata})
    }
  },[post, meta_data])

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-15 bg-white min-vh-75">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/orders">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-12 col-lg-6">
              <div className="d-grid justify-content-center">
              {
                post&&order?
                <>
                <div className="col-12 col-lg-10 my-3">
                  <div className="card rounded-4">
                    <div className="card-body">
                      <div className="pb-6">
                        <Media data={post} resolution={{width:'100%', maxHeight:'100%', objectFit:'cover'}} />
                      </div>
                      <div className="border-top border-2 border-light pt-3">
                        <h3 className="fw-bold pb-2">Order Details</h3>
                        <p>Title: {JSON.parse(post.alchemy_data.metadata).name}</p>
                        <p>Order ID: {order.order_id}</p>
                        <p>Date: {(new Date((order.created_on._seconds)* 1000).toLocaleString()).slice(0,8)}</p>
                        <p>Deliver to: <span className="fw-bold">{sliceCharacter(order.collectors_address)}</span></p>
                        <p>Status: {order.status}</p>
                      </div>
                      <div className="py-3">
                      {
                        order.status ==='pending'?
                        <button className="btn btn-light brand-button">
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        <span>{" "}Order Procesing</span>
                        </button>
                        :
                        <button className="btn btn-light brand-button">Download Receipt</button>
                      }
                      </div>
                    </div>
                  </div>
                </div>
                </>
                :
                (loading)?
                <div className="col-12 col-lg-10 my-3">
                  <div className="py-3">
                    <span className="spinner-border spinner-border-lg" role="status" aria-hidden="true"></span>
                  </div>
                </div>
                :
                <p>No Order Found</p>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Order;
//@ NFTmarket.africa 2022
