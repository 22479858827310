import React, {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
// import opensea from '../assets/images/brand/opensea.png';
// import rarible from '../assets/images/brand/rarible.svg';
// import instagram from '../assets/images/brand/instagram-2.png';
// import twitter from '../assets/images/brand/twitter.png';
// import foundation from '../assets/images/brand/foundation.jpeg';
import {getUserProfile, updateProfile} from '../features/user/userSlice';
import {
  LandingWrapper
} from './wrapper';
import {
  UpdateAvatar,
  UpdateBanner
} from '../component/upload';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Update profile",
  meta_title:"NFT Market Africa - Update profile",
  description:"Update your user profile"
}

const Profile =(props)=> {
  let dispatch = useDispatch();
  let user = useSelector(state => state.user.value)
  let [update, setUpdate] = useState({username:user.profile.username||""});

  useEffect(()=>{
    dispatch(getUserProfile())
  },[dispatch])

  let handleInput =(e)=> {
    let {value, name, accept} = e.target;
    update = {
      ...update,
      [name]:value
    }
    if(value && value.match(accept)){
      setUpdate(update);
    }
  }

  let validateInput =(e, acceptedValue)=> {
    let {value, name} = e.target;
    let tester = new RegExp(acceptedValue);
    if(value && tester.test(value)){
      update = {
        ...update,
        [name]:value.trim()
      }
      setUpdate(update);
    }
  }

  let handleUpdateProfile =()=> {
    dispatch(updateProfile(update)).then((response)=>{
      let {error} = response;
      if(!error){
        EventTracker.logEvent('UPDATE_PROFILE_SUCCESS')
      }
    }).catch((err)=>{
      EventTracker.logEvent('UPDATE_PROFILE_FAILURE')
    })
  }
  return (
    <LandingWrapper showNavigation={true} key={"dehdwuhuhe88hue"} metadata={metadata}>
    <div className="pt-15 bg-white">
      <section className="container py-3">
        <div className="row justify-content-center align-items-top">
          <div className="col-12 col-lg-10">
            <span className="fs-3 fw-bold">
              <Link to="/dashboard">
                <i className="fe fe-arrow-left fw-bolder px-3"/>
              </Link>
            </span>
          </div>
          <div className="col-12 col-md-7 col-lg-6 py-6">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                <div className="card mb-3 rounded-4 shadow-none bg-light">
                  {/* Card body */}
                  <div className="card-body">
                    <UpdateAvatar avatar={user.profile.avatar}/>
                    <UpdateBanner banner={user.profile.banner}/>
                    <div className="mb-3">
                      <label htmlFor="courseTitle" className="form-label">
                        Username
                      </label>
                      <input
                        id="username"
                        name="username"
                        className="form-control brand-button"
                        type="text"
                        placeholder="Choose a username"
                        value={update.username || user.profile.username||""}
                        onInput={(e)=>validateInput(e, /^[a-zA-Z0-9_.]+$/)}
                        maxLength="20"
                      />
                      <small>Choose a username (Min 3 characters).</small>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="courseTitle" className="form-label">
                        Full name
                      </label>
                      <input
                        id="fullName"
                        name="full_name"
                        className="form-control brand-button"
                        type="text"
                        placeholder="Enter your full name"
                        onChange={handleInput}
                        defaultValue={user.profile&&user.profile.full_name?user.profile.full_name:""}
                      />
                      <small>Enter your full name.</small>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="courseTitle" className="form-label">
                        Email
                      </label>
                      <input
                        id="email"
                        name="email"
                        className="form-control brand-button"
                        type="email"
                        placeholder="Enter your email address"
                        onChange={handleInput}
                        defaultValue={user.profile&&user.profile.email?user.profile.email:""}
                      />
                      <small>Enter your email address.</small>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="validationCustomUsername" className="form-label">
                        Twitter
                      </label>
                      <input
                        type="text"
                        className="form-control brand-button"
                        id="twiter"
                        name="twitter_profile"
                        placeholder="Link to twitter profile"
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInput}
                        defaultValue={user.profile&&user.profile.twitter_profile?user.profile.twitter_profile:""}
                      />
                      <small>Link to Twitter profile.</small>
                    </div>

                    <div className="mb-3">
                      <label htmlFor="validationCustomUsername" className="form-label">
                        Instagram
                      </label>
                      <input
                        type="text"
                        placeholder="Link to instagram profile"
                        className="form-control brand-button"
                        id="instagram"
                        name="instagram_profile"
                        aria-describedby="inputGroupPrepend"
                        onChange={handleInput}
                        defaultValue={user.profile&&user.profile.instagram_profile?user.profile.instagram_profile:""}
                      />
                      <small>Link to Instagram profile.</small>
                    </div>

                    <div className="mb-3">
                      <label className="form-label">About me</label>
                      <textarea
                      className="form-control brand-button"
                      maxLength="200"
                      name="about_me"
                      onChange={handleInput}
                      defaultValue={user.profile&&user.profile.about_me?user.profile.about_me:""}
                      >
                      </textarea>
                      <small>A brief description of yourself.</small>
                    </div>

                    <div className="d-grid">
                    {
                      user.updating?
                      <button className="btn btn-lg btn-primary brand-button" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {" "}Updating...
                      </button>
                      :
                      <button
                      className="btn btn-lg btn-primary brand-button"
                      type="button"
                      onClick={handleUpdateProfile}
                      >
                      Update
                      </button>
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      </div>
    </LandingWrapper>
  )
}
export default Profile;
//@ NFTmarket.africa 2022
