import React, {useEffect, useState} from 'react';
import {useParams, useSearchParams, useNavigate, Link} from 'react-router-dom';
import Pusher from 'pusher-js';
import {createTransaction, getTransaction, getWallets} from '../features/wallet/walletSlice';
import {signMessage} from '../features/auth/authSlice';
import {useDispatch, useSelector} from 'react-redux';
import {currencyFormat, Amplitude} from '../features/tools';
import {
  LandingWrapper
} from './wrapper';
import {FlutterWaveButton, PayPalButton} from '../component/ui_kits/payment_buttons';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Funding",
  meta_title:"NFT Market Africa - Funding",
  description:"Make payment for your NFT"
}


const CreateTransaction =(props)=> {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  let {transaction_id} = useParams()
  const [searchParams] = useSearchParams();
  let user = useSelector(state => state.user.value.profile)
  let wallet = useSelector( state => state.wallet.value);
  let {transaction, loading_transactions, error} = useSelector(state => state.wallet.value)
  let [request, setRequest] = useState({
    amount:0,
    type:'credit',
    reason:'funding',
    currency:"USD"
  })

  useEffect(()=>{
    dispatch(getWallets())
  },[dispatch])

  useEffect(()=>{
    if(!wallet.loading_wallets && !wallet.data.length){
      navigate('/wallet')
    }
  },[wallet, navigate])

  const pusher = new Pusher('103af5a922d44a465a98', {
    cluster: 'us2',
    encrypted: true
  });
  const channel = pusher.subscribe('transaction-channel');

  useEffect(()=>{
    let type = searchParams.get('type');
    if(type==="credit"){
      setRequest((prev)=>
      {
        return {
          ...prev,
          type:'credit',
          reason:'funding'
        }
      }
    )
    }else if(type==="debit"){
      setRequest((prev)=>
      {
        return {
          ...prev,
          type:'debit',
          reason:'withdrawal'
        }
      }
    )
    }
  },[searchParams, setRequest])

  useEffect(()=>{
    channel.bind( transaction_id, data => {
      dispatch(getTransaction(data.message))
    });
  },[dispatch, transaction_id, channel])

  useEffect(()=>{
    if(transaction_id){
      dispatch(getTransaction(transaction_id))
    }
  },[dispatch, transaction_id])


  let handleEnterAmount =(e)=> {
    let {value} = e.target;
    if(value){
      let test = Number(value);
      if(!isNaN(test)){
        setRequest({
          ...request,
          amount:parseFloat(value)
        })
      }
    }
  }

  let handleSelectType =(e)=> {
    let {value} = e.target;
    if(value==="credit"){
      setRequest({
        ...request,
        type:'credit',
        reason:'funding',
      })
    }else{
      setRequest({
        ...request,
        type:'debit',
        reason:'withdrawal',
      })
    }
  }

  function getMessage(){
    let date = new Date()
    return `I want to request ${request.type==='credit'?'credit to':'debit from'} my Marketplace wallet
    \nOn today ${date} \n\nAmount: ${request.amount} \nCurrency: ${request.currency}`
  }

  let handleCreateTransaction =()=> {
    dispatch(signMessage(getMessage())).then((response)=>{
      let raw_transaction = {
        headers:response.payload,
        request
      }
      dispatch(createTransaction(raw_transaction)).then((transaction_response)=>{
        let {error, payload} = transaction_response;
        if(!error && payload){
          EventTracker.logEvent("CREATE_TRANSACTION_SUCCESS")
          if(request.type === 'credit'){
            return window.location.href = `/tx/${payload.transaction_id}`;
          }else{
            navigate(`/tx/${payload.transaction_id}`);
          }
        }
        EventTracker.logEvent("CREATE_TRANSACTION_FAILURE")
      }).catch((err)=>{
        EventTracker.logEvent("CREATE_TRANSACTION_FAILURE")
        console.error(err);
      })
    }).catch((err)=>{
      EventTracker.logEvent("TRANSACTION_SIGNATURE_ERROR")
      console.error(err);
    })
    EventTracker.logEvent("CREATE_TRANSACTION")
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-10 pt-15 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="d-lg-flex align-items-center justify-content-between">
                {/* Content */}
                <div className="mb-4 mb-lg-0">
                  <h1 className="text-white mb-1 display-4">Funding</h1>
                  <p className="mb-0 text-muted lead">
                    Add & Withdraw funds
                  </p>
                </div>
                <div className="d-flex">
                  <Link to="/wallet" className="btn btn-white btn-sm">
                    Back to Wallet
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row mt-0 mt-md-4  py-6 pb-10 justify-content-center">
        {
          (loading_transactions)?
          <div className="col-lg-5 col-md-8 col-12">
            {/* Card */}
            <div className="card mb-4">
              {/* Card body */}
              <div className="card-body d-grid">
                <div className="text-center py-3">
                  <input className="form-control fw-bold fs-3" disabled={true} placeholder="Please wait..."/>
                </div>
                <button
                  disabled
                  className="btn btn-success btn-lg me-2">
                  <div className="spinner-border spinner-border-sm" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          :
          (transaction&&transaction.status==="pending")?
          <div className="col-lg-5 col-md-6 col-12">
            {/* Card */}
            <div className="card mb-4">
              {/* Card body */}
              <div className="card-body">
                <div className="pb-3">
                  <input key="60044212r" disabled={true} readOnly defaultValue={currencyFormat(transaction.amount, 'USD')} className="form-control fw-bold fs-3" placeholder="Enter Amount"/>
                </div>
                <div className="d-grid">
                  <p>Type: {transaction.type}</p>
                  <p>Reason: {transaction.reason}</p>
                  <p>Currency: {transaction.currency}</p>
                  <p>Transaction Date: {(new Date((transaction.created_on._seconds)* 1000).toLocaleString()).slice(0,9)}</p>
                </div>
                {
                  transaction.type === "credit"?
                  <div>
                    <label>Make Payment</label>
                    <hr/>
                    <FlutterWaveButton transaction={transaction} user={user}/>
                    <PayPalButton transaction={transaction} user={user}/>
                  </div>
                  :
                  <div className="d-grid">
                    <button
                      className="btn btn-success btn-lg me-2">
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">...</span>
                      </div>
                      {" "}Withdrawal Processing
                    </button>
                  </div>
                }
              </div>
            </div>
          </div>
          :
          (transaction&&transaction.status==="complete")?
          <div className="col-lg-5 col-md-8 col-12">
            {/* Card */}
            <div className="card mb-4">
              {/* Card body */}
              <div className="card-body d-grid">
                <div className="text-center py-3">
                  <input key="674er" className="form-control fw-bold fs-3 text-success" readOnly defaultValue={currencyFormat(transaction.amount, 'USD')} placeholder="Amount"/>
                </div>
                <div className="d-grid">
                  <p>Type: {transaction.type}</p>
                  <p>Reason: {transaction.reason}</p>
                  <p>Currency: {transaction.currency}</p>
                  <p>Status: {transaction.status}</p>
                  <p>Transaction Date: {(new Date((transaction.created_on._seconds)* 1000).toLocaleString()).slice(0,8)}</p>
                </div>
              </div>
            </div>
          </div>
          :
          <div className="col-lg-5 col-md-8 col-12">
            {/* Card */}
            <div className="card mb-4">
              {/* Card body */}
              <div className="card-body d-grid">
                <div className="py-3">
                  <input key="6766fsdw" className="form-control fw-bold fs-3" type="number" onChange={handleEnterAmount} placeholder="Enter Amount"/>
                </div>
                <div className="text-center py-3">
                  <select className="form-select form-select-lg" defaultValue={request.type} data-width="100%" onChange={handleSelectType}>
                    <option value="credit">Deposit</option>
                    <option value="debit">Withdraw</option>
                  </select>
                </div>
                <button className="btn btn-lg btn-success" onClick={handleCreateTransaction} disabled={!request.amount}>{request.type === "credit" ?"Add Funds":"Withdraw Funds"}</button>
                <Link to="/wallet" className="btn btn-lg btn-light my-3" onClick={()=> EventTracker.logEvent('CANCELLED_TRANSACTION')}>Cancel</Link>
                <small className="text-danger">{error}</small>
              </div>
            </div>
          </div>
        }
        </div>
      </div>
    </LandingWrapper>
  )
}
export default CreateTransaction;
//@ NFTmarket.africa 2022
