import React, {useState, useEffect} from 'react';
import Avatar from '../../component/ui_kits/avatar';
import {useSelector} from 'react-redux';

export default function TransferCardFooter(props){
  let [meta_data, setMetadata] = useState(false);
  let checkout = useSelector(state => state.checkout.value);
  let {post, order} = checkout.retrieved_order;

  useEffect(()=>{
    if(post && post.alchemy_data){
      let {alchemy_data} = post;
      let {metadata} = alchemy_data;
      metadata = JSON.parse(metadata)
      setMetadata(metadata)
    }
  },[post])

  return (
    <div className="card-footer border-0 bg-white py-3 position-relative">
      <div className="d-grid gap-2">
        <div className="py-3">
          <h5>{meta_data.name}</h5>
          <div className="d-flex justify-content-between align-items-center py-2">
            <span className="col-4">Collector</span>
            <Avatar data={{account:order.collector}}/>
          </div>
        </div>
        <small></small>
        <button className="btn btn-primary" type="button">
          Complete Sale
        </button>
      </div>
    </div>
  )
}
//@NFTmarket.africa 2022
