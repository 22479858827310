import React, {
  useEffect,
  useState
} from 'react';
import {
  useParams
} from 'react-router-dom';
import avatar from '../assets/images/avatar/user.png';
// import opensea from '../assets/images/brand/opensea.png';
import instagram from '../assets/images/illustrations/instagram_button.svg';
import twitter from '../assets/images/illustrations/twitter_button.svg';
import discord from '../assets/images/illustrations/discord_button.svg';
import checkmark from '../assets/images/tools/checkmark.svg';
import userApi from '../service/api/user_api';
import {LandingWrapper} from './wrapper';
import {ProfileModal} from '../component/modal';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import NftCard from '../component/cards/nftcard';
Notify.init({position:'left-bottom'})
const api = new userApi();

const ProfileView =(props)=> {
  let [data, setData] = useState({
    profile:'',
    posts:[],
    metrics:{},
    loading:false
  })
  let [error, setError] = useState()
  let {username} = useParams()
  useEffect(()=>{
    setData( prevState => {return {...prevState, loading:true}})
    api.getProfileByUsername(username).then((response)=>{
      setData( prevState => {return {...prevState, loading:false, ...response.data}})
    }).catch((err)=>{
      setData( prevState => {return {...prevState, loading:false}})
      if(!err.response || !err.response.data){
        Notify.failure('Network error');
        setError('Network Error')
      }else{
        Notify.failure(err.response.data.error);
        setError(err.response.data.error)
      }
    })
  },[username])

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }
  return (
    <LandingWrapper
    showNavigation={true}
    showAcceptedPayments={true}
    metadata={{
      title:data.profile.username,
      meta_title:data.profile.username,
      description:data.profile.about_me,
      social_image:data.profile.avatar,
      url:`${window.location.origin}/@/${data.profile.username}`
    }}>
      <div
        className="py-20 bg-brand-1 position-relative"
        style={{
          backgroundImage: `url(${data.profile.banner || ""})`,
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
      </div>
      <div className="p-lg-2 pt-2 pt-lg-0 bg-white">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-9 col-md-11 col-12">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div className="position-relative mt-n9">
                    <img
                      src={data.profile.avatar|| avatar}
                      alt="avatar"
                      className="rounded-circle avatar-xxl border-light border border-4 position-relative bg-white"
                    />
                    {
                      data.profile &&
                      data.profile.profile_verified?
                      <span
                        className="position-absolute top-0 end-0 me-2"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="Verified"
                      >
                        <img
                          src={checkmark}
                          alt="checkmark"
                          height={30}
                          width={30}
                        />
                      </span>
                      :
                      null
                    }
                  </div>

                  <div className="ms-3 py-1">
                    <div className="d-flex align-items-center">
                      <h3 className="mb-0 fw-bold me-2">
                      {
                        !error&&data.profile.username?
                        `@${data.profile.username}`:
                        "@---"
                      }
                      </h3>
                    </div>
                    <span className={`fs-5 ${error?'text-danger':''}`}>
                      {
                        !error&&data.profile.account?
                        <span className="clickable">
                          {sliceCharacter(data.profile.account)}
                          &nbsp;&nbsp;<i className="fe fe-copy"/>
                        </span>
                        :
                        <span className="clickable">
                          {error||"0x---"}
                        </span>
                      }
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-2 col-12">
              <div className="fs-3 mt-4 mt-lg-0 pb-2 pb-lg-0 d-lg-flex d-md-flex justify-content-end align-items-center">
              {
                data.profile.instagram_profile?
                <a href={`${data.profile.instagram_profile}`} target="blank" className="text-muted me-4"><img alt="instagram icon" src={instagram} className="pb-1" width={35}/></a>
                :
                null
              }
              {
                data.profile.twitter_profile?
                <a href={`${data.profile.twitter_profile}`} target="blank" className="text-muted me-4"><img alt="twitter icon" src={twitter} className="pb-1" width={35}/></a>
                :
                null
              }
              {
                data.profile.discord_channel?
                <a href={`${data.profile.discord_channel}`} target="blank" className="text-muted me-4"><img alt="twitter icon" src={discord} className="pb-1" width={35}/></a>
                :
                null
              }
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="d-flex py-3">
                <h5>Items &nbsp;<span className="fs-4 fw-bold me-4">{data.metrics.total_posts || '0'}</span></h5>
                <h5>Sales &nbsp;<span className="fs-4 fw-bold me-4">{data.metrics.total_sales || '0'}</span></h5>
                <h5>Likes &nbsp;<span className="fs-4 fw-bold">{data.metrics.total_likes || '0'}</span></h5>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white">
        <div className="container px-2">
          <div className="row px-0">
            <div className="col-md-12 px-0">
            <>
              {/* javascript behaviour */}
              <ul className="nav nav-underline border-bottom border-2 border-light py-3" id="myTab" role="tablist">
                <li className="nav-item me-4">
                  <a
                    className="nav-link fs-3 fw-bold active"
                    id="home-tab"
                    data-bs-toggle="tab"
                    href="#home"
                    role="tab"
                    aria-controls="home"
                    aria-selected="true"
                  >
                    Items
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link fs-3 fw-bold"
                    id="profile-tab"
                    data-bs-toggle="tab"
                    href="#profile"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    About me
                  </a>
                </li>
              </ul>
              <div className="tab-content py-6" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  <div className="row">
                  {
                    data.loading?
                      <div className="vh-75">
                        <div className="row justify-content-center align-items-top">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                      </div>
                    :
                    (data.posts && data.posts.length)?
                    data.posts.map((item, i)=>
                      <div key={i} className="col-12 col-lg-4 col-md-6 pb-12">
                        <div className="p-2">
                          <NftCard
                          resolution={{width:"300px", maxHeight:"300px", objectFit:"cover"}}
                          data={item}
                          loading={data.loading}
                          error={error}
                          />
                        </div>
                      </div>
                    )
                    :
                    <div className="col-12 col-md-7 col-lg-10">
                      <div className="p-3 pb-20">
                        <p className="fs-4">{error || "No item in store"}</p>
                      </div>
                    </div>
                  }
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="profile"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  <div className="p-3 pb-20">
                    <p className="fs-4">{data.profile.about_me}</p>
                  </div>
                </div>
              </div>
            </>

            </div>
          </div>
        </div>
      </div>
      <ProfileModal profile={data.profile} layout="profileview"/>
    </LandingWrapper>
  )
}
export default ProfileView;
//@ NFTmarket.africa 2022
