import axios from 'axios';
import {baseURL} from '../../config';

let toolsClient = axios.create({
  baseURL,
  timeout:30000
})

class ToolsApi {

  async contactAdmin(request){
    return await toolsClient.post(`/tools/contact`, request);
  }

}

export default ToolsApi;
//@NFTmarket.africa 2022
