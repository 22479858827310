import axios from 'axios';
import {baseURL} from '../../config';

let authClient = axios.create({
  baseURL,
  timeout:30000
})

class AuthApi {
  async getToken({signature, hash}){
    return await authClient.post('/users/auth', {}, {
      headers:{"x-sign":signature, "x-nonce":hash}
    });
  }
}

export default AuthApi;
//@NFTmarket.africa 2022
