import React from 'react';
import {
  LandingWrapper
} from './wrapper';

const TermsAndConditions =(props)=> {
  let metadata = {
    title:"NFT Market Africa | Terms & Conditions",
    meta_title:"Take a moment to read our Terms & Conditions",
    description:"Take a moment to read our Terms & Conditions"
  }
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-10 bg-primary mt-9 border-bottom border-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div>
                <h1 className="mb-1 display-4 text-white">Terms & Conditions</h1>
                <p className="mb-0 lead text-muted">
                  Read our Terms & Conditions.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <div className="container">
          <div className="row">
            <div className="offset-md-2 col-md-8 col-12">
              <div className="mb-8">
                <h1 className="display-4 fw-semi-bold">Terms of Use</h1>
                <div className="mb-3">
                  <h2 className="fw-semi-bold">1. AGREEMENT TO TERMS</h2>
                  <p>
                    Welcome to NFT Market Africa. These terms and conditions control
                    the use and access of this website, features, or operability on
                    or in connection with our services not limited to using our services
                    to view, explore, to connect creators and collectors to buy, sell
                    or transfer NFTs on blockchain.
                  </p>
                  <p>
                  These Terms of Use constitute a legally binding agreement made
                  between you, whether personally or on behalf of an
                  entity ("you") and ("Company", "we", "us", or "our"),
                  concerning your access to and use of our website, mobile website,
                  or mobile application related, linked, or otherwise connected
                  thereto (collectively, the "Site"). The Site provides an online
                  marketplace for NFT. To help make our Marketplace a secure
                  environment for the purchase and sale of all kinds of NFT, all
                  users are required to accept and comply with these Terms of Use.
                  You agree that by accessing our website and/or the Marketplace Offerings,
                  you have read, understood, and agree to be bound by all of these
                  Terms of Use. IF YOU DO NOT AGREE WITH ALL OF THESE TERMS OF USE,
                  THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SITE AND/OR THE
                  MARKETPLACE OFFERINGS AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                  </p>
                  <p>
                  Supplemental terms and conditions or documents that may be posted
                  on the website from time to time are hereby expressly incorporated
                  herein by reference. We reserve the right, in our sole discretion,
                  to make changes or modifications to these Terms of Use at any time
                  and for any reason. We will alert you about any changes by updating
                  the "Last Updated" date of these Terms of Use, and you waive any right
                  to receive specific notice of each such change. Please ensure that you
                  check the applicable Terms every time you use our Site so that you
                  understand which Terms apply. You will be subject to and will be
                  deemed to have been made aware of and to have accepted, the changes
                  in any revised Terms of Use by your continued use of this website
                  after the date such revised Terms of Use are posted.
                  The information provided on the website is not intended for distribution
                  to or use by any person or an entity in any jurisdiction or country
                  where such distribution or use would be contrary to law or regulation
                  or which would subject us to any registration requirement within such
                  jurisdiction or country. Accordingly, those persons who choose to
                  access the website from other locations do so on their initiative
                  and are solely responsible for compliance with local laws, if and
                  to the extent local laws are applicable.
                  </p>
                </div>
                <div className="mb-3">
                  <h2 className="fw-semi-bold">2. INTELLECTUAL PROPERTY RIGHTS</h2>
                  <p>
                  Unless otherwise indicated, the website and services are our proprietary property
                  and all source code, databases, functionality, software, website designs, audio,
                  text, photographs, and graphics on the Site (collectively, the "Content") and
                  the trademarks, service marks and logos contained therein (the "Marks") are
                  owned or controlled by us or licensed to us, and are protected by copyright
                  and trademark laws and various other intellectual property rights and unfair
                  competition laws, international copyright laws, and
                  international conventions. The Content and the Marks are provided on the
                  Site "AS IS" for your information and personal use only. Except as expressly
                  provided in these Terms of Use, nö part of this website or the Marketplace
                  Offerings and no Content or Marks may be copied, reproduced, aggregated,
                  republished, uploaded, posted, publicly displayed, encoded, translated, and
                  transmitted, distributed, sold, licensed, or otherwise exploited for any
                  commercial purpose whatsoever, without our express prior written permission.
                  Provided that you are eligible to use this website, you are granted a limited
                  license to access and use this marketplace and to sell or buy any NFT to which
                  you have properly given us access solely for your personal use. We reserve all
                  rights not expressly granted to you in and to the site, and the content.
                  </p>
                </div>
                <div className="mb-3">
                  <h2 className="fw-semi-bold">3. USER REPRESENTATIONS</h2>
                  <p>By using this website or the Marketplace Offerings, you represent and warrant that</p>
                  <ol>
                    <li>you have the legal capacity and you agree to comply with these Terms of Use;</li>
                    <li>you are not a minor in the jurisdiction in which you reside;</li>
                    <li>
                    you will not access this website or the Marketplace Offerings through automated or
                    non-human means, whether through a bot, script, or otherwise;
                    </li>
                    <li>you will not use the website for any illegal or unauthorized purpose; and</li>
                    <li>your use of the Site or the Marketplace Offerings will not violate any applicable law or regulation.</li>
                  </ol>
                  <p>
                  If you provide any information that is untrue, inaccurate, not current, or incomplete,
                  we have the right to suspend or terminate your account and refuse all current or
                  future use of the Site (or any portion thereof).
                  You may not use the Site or the Marketplace Offerings for any illegal or
                  unauthorized purpose nor may you, in the use of Marketplace Offerings,
                  violate any laws. We are a service provider and make no representations as
                  to the safety, effectiveness, adequacy, accuracy, availability, prices,
                  ratings, reviews, or legality of any of the information contained on the
                  Site or the Marketplace Offerings displayed or offered through the Site.
                  You understand and agree that the content of the Site does not contain or
                  constitute representations to be reasonably relied upon, and you agree to
                  hold us harmless from any errors, omissions, or misrepresentations contained
                  within the Site's content. We do not endorse or recommend any Marketplace
                  Offerings and the Site is provided for informational and advertising purposes only.
                  </p>
                </div>
                <div>
                  <h2 className="fw-semi-bold">4. MARKETPLACE OFFERINGS</h2>
                  <p>
                  We make every effort to display as accurately as possible the colors,
                  features, specifications, and details of the Marketplace Offerings
                  available on the Site. However, we do not guarantee that the colors,
                  features, specifications, and details of the Marketplace Offerings will
                  be accurate, complete, reliable, current, or free of other errors, and
                  your electronic display may not accurately reflect the actual colors and
                  details of the products. All Marketplace Offerings are subject to availability.
                  Certain Marketplace Offerings may be available exclusively online through the Site.
                  Such Marketplace Offerings may have limited quantities and are subject exchange only.
                  </p>
                  <p>
                  We reserve the right to limit the quantities of the Marketplace Offerings
                  offered or available on the Site. All descriptions or pricing of the Marketplace
                  Offerings are subject to change at the users discretion. We reserve the right to
                  discontinue any Marketplace any time for any reason. We do not warrant that the
                  quality of any of the NFTs purchased by you will meet your expectations and any errors on the site will be corrected.
                  </p>
                </div>
                <div>
                  <h2 className="fw-semi-bold">5. PURCHASES AND PAYMENT</h2>
                  <p>We accept the following forms of payment: PayPal, Credit Card,
                  bank transfer, Cryptocurrency You agree to provide current, complete,
                  and accurate purchase and account information for all purchases of the
                  Marketplace Offerings made via the Site.
                  </p>
                  <p>You further agree to promptly update account and payment information,
                  including email address, payment method, so that we can complete your
                  transactions/and contact you as needed.
                  </p>
                  <p>
                  Sales tax will be added to the price of purchases as deemed required by us.
                  We may change prices at any time.
                  </p>
                  <p>
                  You agree to pay all charges at the prices then in effect for your purchases
                  and any applicable shipping fees, and you authorize us to charge your
                  chosen payment provider for any such amounts upon placing your order.
                  We reserve the right to correct any errors or mistakes in pricing,
                  even if we have already requested or received payment. We reserve the
                  right to refuse any order placed through the Site.
                  </p>
                </div>
                <div>
                  <h2 className="fw-semi-bold">6. ASSUMPTION OF RISK</h2>
                  <p>You accept and acknowledge:</p>
                  <p>
                  A lack of use or public interest in the creation and
                  development of distributed ecosystems could negatively
                  impact the development of those ecosystems and related
                  applications, and could therefore also negatively impact
                  the potential utility of NFTS.
                  </p>
                  <p>
                  The regulatory regime governing blockchain technologies,
                  non-fungible tokens, cryptocurrency, and other crypto-based
                  items is uncertain, and new regulations or policies may
                  materially adversely affect the development of the Service
                  and the utility of NFTs.
                  </p>
                  <p>
                  You are solely responsible for determining what, if any,
                  taxes apply to your transactions. NFTmarket Africa is not
                  responsible for determining the taxes that apply to your NFTs. There are
                  risks associated with purchasing items associated with content created
                  by third parties through peer-to-peer transactions, including but not
                  limited to, the risk of purchasing counterfeit items, mislabeled items,
                  items that are vulnerable to metadata decay, items on smart contracts
                  with bugs, and items that may become untransferable. You represent and
                  warrant that you have done sufficient research before making any decisions
                  to sell, obtain, transfer, or otherwise interact with any NFTs
                  or accounts/collections.
                  </p>
                  <p>
                  We do not control the public blockchains that you are interacting
                  with and we do not control certain smart contracts and protocols
                  that may be integral to your ability to complete transactions on
                  these public blockchains. Additionally, blockchain transactions are
                  irreversible and NFTmarket Africa cannot reverse any transactions on
                  the blockchain.
                  </p>
                  <p>
                  There are risks associated with using the Internet and blockchain-based products,
                  including, but not limited to, the risk associated with hardware, software,
                  and Internet connections, the risk of malicious software introduction,
                  and the risk that third parties may obtain unauthorized access to your
                  third-party wallet or Account. You accept and acknowledge
                  that NFTmarket Africa will not be responsible for any communication
                  failures, disruptions, errors, distortions, or delays you may
                  experience when using the Service or any Blockchain network,
                  however, caused.
                  </p>
                  <p>
                  If you have a dispute with one or more users,
                  YOU RELEASE US FROM CLAIMS, DEMANDS, AND DAMAGES OF
                  EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT
                  OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES. IN ENTERING INTO
                  THIS RELEASE YOU EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER STATUTORY
                  OR OTHERWISE) THAT WOULD OTHERWISE LIMIT THE COVERAGE OF
                  THIS RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR
                  SUSPECT TO EXIST IN YOUR FAVOR AT THE TIME OF AGREEING TO THIS RELEASE.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default TermsAndConditions;
//@ NFTmarket.africa 2022
