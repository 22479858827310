import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import { useMoralis, useWeb3Transfer } from "react-moralis";
import {getGalleries} from '../../../features/gallery/gallerySlice';
import {Amplitude} from '../../../features/tools';
let EventTracker = new Amplitude();

export default function NFTTransferModal(props){
  let dispatch = useDispatch();
  let user = useSelector( state => state.auth.value);
  let galleries = useSelector( state => state.gallery.value)
  let [data, setData] = useState({});
  let [loading, setLoading] = useState(false);
  let [transferComplete, setTransferComplete] = useState(null);
  let [showRecipientInput, setShowRecipientInput] = useState(false);
  let [error_, setError] = useState(null);
  const { Moralis } = useMoralis();

  useEffect(()=>{
    if(props.data){
      setData( d => { return {...d, ...props.data}})
    }
  },[props])

  useEffect(()=>{
    dispatch(getGalleries())
  },[dispatch, user])

  const { fetch, error } = useWeb3Transfer({
    type:data&&data.tokenType?data.tokenType.toLowerCase():"",
    receiver:data?data.recipient_address:"",
    contractAddress:data&&data.contract?data.contract.address:"",
    tokenId:data?data.tokenId:"",
    amount:data?data.amount:0
  });

  let handleTransferNFT = () => {
    setLoading(true)
    Moralis.enableWeb3().then(async()=>{
      let result = await fetch()
      if(result && result.wait){
        setLoading(true);
        result.wait().then((confirmed_transaction)=>{
          EventTracker.logEvent('BLOCKCHAIN_TRANSACTION_SUCCESS')
          setTransferComplete(confirmed_transaction)
          setLoading(false)
        }).catch((err)=>{
          setLoading(false);
          setError('Error confirming blockchain tx');
          EventTracker.logEvent('BLOCKCHAIN_TRANSACTION_SUCCESS')
        })
      }
    })
  }

  useEffect(()=>{
    if(error){
      setLoading(false)
      EventTracker.logEvent('BLOCKCHAIN_TRANSACTION_ERROR')
      setError('Check details and try again');
    }
  },[error])


  let handleInput = (e)=> {
    let {value, id} = e.target;
    if(value){
      if(value === "enter_recipient_address"){
        setShowRecipientInput(true);
      }else{
        setData({
          ...data,
          [id]:value.trim()
        });
      }
    }else{
      if(id){
        setData({
          ...data,
          [id]:null
        });
      }
    }
  }

  return (
      <div
        className="modal bg-light"
        id="exampleModalCenter"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content rounded-4 border-0">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalCenterTitle">
              {error_ ?<span className="text-danger">{error_}</span>: transferComplete? <span className="text-success">Transfer complete</span>:<span>Transfer NFT</span>}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
            {
              !showRecipientInput?
              <select className="form-select form-select-lg rounded-4" id="recipient_address" onChange={handleInput}>
                <option value={null}>Select an address</option>
                {
                  galleries&&galleries.data?
                  galleries.data.map((galry, i)=>
                    <option key={i} value={galry.address}>Gallery ({galry.chain})</option>
                   )
                   :
                   null
                }
                <option value="enter_recipient_address">Enter Address or ENS name</option>
              </select>
              :
              <>
              <input
              className="form-control form-control-lg brand-button mb-3"
              onChange={handleInput}
              placeholder="Recipient address or ENS name"
              id="recipient_address"
              defaultValue={data.recipient_address}
              />
              <span onClick={()=>setShowRecipientInput(false)} className="clickable">Show addresses</span>
              </>
            }
              <input
              className="form-control form-control-lg brand-button mt-3"
              onChange={handleInput}
              placeholder="How many"
              type="number"
              id="amount"
              defaultValue={data.amount}
              />
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary brand-button"
                data-bs-dismiss="modal"
                onClick={()=>{setError(null); setTransferComplete(null)}}
              >
                Close
              </button>
              {
                loading?
                <button
                type="button"
                className="btn btn-primary brand-button"
                disabled={true}
                >
                  <span className="spinner-border spinner-border-sm text-white" role="status">
                    <span className="sr-only"></span>
                  </span>
                </button>
                :
                <button
                type="button"
                className="btn btn-primary brand-button"
                onClick={handleTransferNFT}
                >
                  Transfer
                </button>
              }
            </div>
          </div>
        </div>
      </div>
  )
}
//@NFTmarket.africa 2022
