import React from 'react';
// import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import store, {persistor} from './store';
import { PersistGate } from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import {HelmetProvider} from 'react-helmet-async';
import reportWebVitals from './reportWebVitals';
import { hydrate, render } from "react-dom";
const rootElement = document.getElementById("root");

let APP = () => {
  return (
    <React.StrictMode>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <HelmetProvider>
              <App />
            </HelmetProvider>
          </PersistGate>
        </Provider>
    </React.StrictMode>
  )
}

if (rootElement.hasChildNodes()) {
  hydrate(<APP />, rootElement);
} else {
  render(<APP />, rootElement);
}

// ReactDOM.render(
//   <React.StrictMode>
//       <Provider store={store}>
//         <PersistGate persistor={persistor}>
//           <HelmetProvider>
//             <App />
//           </HelmetProvider>
//         </PersistGate>
//       </Provider>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
