import React from 'react';

export default function supportModal(props){
  return (
    <div
      className="offcanvas offcanvas-bottom rounded-top-3 border-0"
      tabIndex={-1}
      id="supportModal"
      aria-labelledby="supportModal"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="supportModalLabel">
            Donation
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fe fe-x-circle " />
        </button>
      </div>
      <div className="container p-0">
        <div className="offcanvas-body m-0">
          <div className="row justify-content-center align-items-center no-gutters">
            <div className="col-12 col-lg-5">
              <div className="d-grid gap-3 col-12 mx-auto">
                <button className="d-flex justify-content-between align-items-center btn btn-dark btn-lg border-0" type="button">
                  <span>Send ETH</span>
                  <i className="fas fa-gift text-success"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

//@NFTmarket.africa 2022
