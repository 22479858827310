import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getPost} from '../features/post/postSlice';
import polygon from '../assets/images/brand/polygon.svg';
import ethereum from '../assets/images/brand/ethereum.svg';
import {utility_prices} from '../store/data';
import {getPrice} from '../features/tools';
import {
  LandingWrapper
} from './wrapper';
import CopyText from '../component/ui_kits/ui_buttons/copy';
import Avatar from '../component/ui_kits/avatar';
import Media from '../component/cards/media';
import {getURL} from '../features/tools';

const ViewItem =(props)=> {
  let dispatch = useDispatch()
  let {post_id} = useParams()
  let post = useSelector(state => state.post.value)
  let [data, setData] = useState({})
  let {retrieved_post, retrieving} = post;

  useEffect(()=>{
    dispatch(getPost(post_id))
  },[dispatch, post_id])

  useEffect(()=>{
    if(retrieved_post&&retrieved_post.alchemy_data){
      let {alchemy_data} = retrieved_post;
      let {metadata} = alchemy_data;
      metadata = JSON.parse(metadata)
      setData(metadata)
    }
  },[retrieved_post])

  return (
    <LandingWrapper
    showNavigation={true}
    showAcceptedPayments={true}
    metadata={{
      title:data?data.name:"Post",
      description:data?(data.description):"View this NFT",
      meta_title:data?data.name:"Post",
      social_image:(data&&data.image)?getURL(data.image):"/",
      url:"https://nftmarket.africa"
    }}>
    {
      retrieving?
      <section className="py-20 bg-white">
        <div className="container vh-75">
          <div className="row justify-content-center align-items-top">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      </section>
      :
      <section className="py-15 bg-white">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <span className="fs-3 fw-bold">
                <a href={`https://nftmarket.africa/account/${retrieved_post?.account}`}>
                  <i className="fe fe-arrow-left fw-bolder px-3"/>Back
                </a>
              </span>
            </div>
          </div>
          <div className="row justify-content-between align-items-top py-6 pt-lg-10">
            <div className="col-12 col-lg-5">
              <div className="d-grid justify-content-top">
                <div className="col-12 col-lg-10">
                {
                  retrieved_post?
                  <Media data={retrieved_post} resolution={{width:'100%', maxHeight:'100%', objectFit:'cover'}} />
                  :
                  null
                }
                </div>
                <div className='col pt-3'>
                  <h3 className="fw-bold">Description</h3>
                  <p>{data.description}</p>
                </div>
                <div className='colpy-3 py-3'>
                  <h3 className="fw-" style={{color:"#2DA46B"}}>{retrieved_post&&retrieved_post.total_supply?retrieved_post.total_supply:(retrieved_post&&retrieved_post.is_sold?"0":"1")} available</h3>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="row">
                <div className='col-12 col-lg-10 border-bottom border-2 border-light'>
                  <div>
                    <h4 className="fw-bold opacity-75">Title</h4>
                    <h2 className="fw-bold">{data.name}</h2>
                  </div>
                  <div className="d-flex justify-content-between py-3">
                  {
                    retrieved_post?
                    <>
                    <Avatar data={{account:retrieved_post.account}} showName={true} customTag="Sold by"/>
                    <Avatar data={{account:retrieved_post.account}} showName={true} customTag="Created by"/>
                    </>
                    :
                    null
                  }
                  </div>
                </div>
              </div>
              <div style={{zIndex:2}} className="pt-3">
                <h4 className="fw-bold opacity-75">What you'll receive</h4>
                <div className="row">
                <div className="col-6 col-lg-5 pb-3">
                  <div className="card p-3 bg-light shadow-none">
                  <span><i className="fas fa-image"></i></span>
                    <h3 className="fw-bold">Digital Collectible</h3>
                  </div>
                </div>
                {
                  retrieved_post&&retrieved_post.utilities?
                  <>
                  {
                    (retrieved_post.utilities.merch&&retrieved_post.utilities.merch.include)?
                    <div className="col-6 col-lg-5 pb-3">
                      <div className="card p-3 bg-light shadow-none">
                      <span><i class="fas fa-tshirt"></i></span>
                        <h3 className="fw-bold">Merchandise</h3>
                      </div>
                    </div>
                    :
                    null
                  }
                  {
                    (retrieved_post.utilities.iframe&&retrieved_post.utilities.iframe.include)?
                    <div className="col-6 col-lg-5 pb-3">
                      <div className="card p-3 bg-light shadow-none">
                        <span><i className="fas fa-image"/></span>
                        <h3 className="fw-bold">NFT iFrame</h3>
                      </div>
                    </div>
                    :
                    null
                  }
                  {
                    (retrieved_post.utilities.physical_work&&retrieved_post.utilities.physical_work.include)?
                    <div className="col-6 col-lg-5 pb-3">
                      <div className="card p-3 bg-light shadow-none">
                        <span><i className="fas fa-box"/></span>
                        <h3 className="fw-bold">Physical Item</h3>
                      </div>
                    </div>
                    :
                    null
                    }
                  </>
                  :
                  null
                }
                </div>

                <h4 className="fw-bold opacity-75">Sale</h4>
                <div className="row">
                  <div className="col-6 col-lg-5 pb-3">
                    <div className="card p-3 bg-light shadow-none">
                      <span>Price</span>
                      <h3 className="fw-bold">{retrieved_post? getPrice(utility_prices, (retrieved_post.utilities||{}), retrieved_post.amount) : 0.00}</h3>
                    </div>
                  </div>
                  <div className="col-6 col-lg-5 pb-3">
                    <div className="card p-3 bg-light shadow-none">
                      <span>Contract Address</span>
                      <div className="d-flex justify-content-between align-items-center">
                      {
                        retrieved_post?
                        <>
                        <h3 className="fw-bold text-truncate" style={{width:"90%"}}>{retrieved_post.contract_address}</h3>
                        <CopyText text={retrieved_post.contract_address}/>
                        </>
                        :
                        null
                      }
                      </div>
                    </div>
                  </div>
                </div>

                {
                  retrieved_post?
                  <div className="col-12 col-lg-10 py-3">
                    <div className="card py-3 bg-light mb-6 shadow-none">
                      <div className="d-flex">
                        <div className="p-3">
                        {
                          retrieved_post.blockchain==="polygon"?
                          <img src={polygon} alt={"blockchain"} width={50}/>
                          :
                          <img src={ethereum} alt={"blockchain"} width={30}/>
                        }
                        </div>
                        <div>
                        <span>{retrieved_post.blockchain==="polygon"?"Polygon":retrieved_post.blockchain==="ethereum"?"Ethereum":"Unknown"} Blockchain</span>
                        <br/>
                        <span>Token ID</span>
                          {
                            retrieved_post?
                            <div className="d-flex justify-content-between align-items-center">
                                <h3
                                className="text-truncate fw-bold"
                                style={{width:"80%"}}>{retrieved_post.token_id.slice(0,30)}
                                </h3>
                                <CopyText
                                text={retrieved_post.token_id}
                                />
                            </div>
                            :
                            null
                          }
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                    {
                      retrieved_post&&retrieved_post.is_sold?
                      <button
                      className="btn btn-success btn-lg py-4 col-12"
                      >
                        <i className="fe fe-check-circle text-white"/> Sold out
                      </button>
                      :
                      <a href={`/buy/${retrieved_post?retrieved_post.id:'/'}`} className="btn btn-lg btn-primary py-4 col-12">Buy for {retrieved_post? getPrice(utility_prices, (retrieved_post.utilities||{}), retrieved_post.amount) : 0.00}</a>
                    }
                    </div>
                  </div>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </section>
    }
    </LandingWrapper>
  )
}
export default ViewItem;
//@ NFTmarket.africa 2022
