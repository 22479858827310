import { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {createNotification} from '../../../features/utilities/utilitySlice';
import {setTransactionProcessing} from '../../../features/checkout/checkoutSlice';
import {Amplitude} from '../../../features/tools';
import paypal from '../../../assets/images/brand/paypal.png';
import {
    PayPalScriptProvider,
    PayPalButtons,
    usePayPalScriptReducer
} from "@paypal/react-paypal-js";

let EventTracker = new Amplitude();

let defaultSettings = {
  style:{"layout":"vertical"},
  currency:"USD"
}

let initialOptions = {
    "client-id":"AfMYZbYSKVP0aOxsUBher6vph1ZD1xkl1EufwY4OjHbeu2W27R5aamDWGiIQr6kYy2tl9hQg3Ekx9BTZ",
    currency: "USD",
    intent: "capture",
    components:'buttons'
};

const ButtonWrapper = ({ transaction, currency, showSpinner }) => {
    const dispatch_ = useDispatch();
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    let [setOptions, setDefaultOption] = useState(options);
    useEffect(() => {
      if(!setOptions){
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: currency,
            },
        });
      }
    }, [dispatch, currency, showSpinner, options, setOptions, setDefaultOption]);

    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={defaultSettings.style}
                disabled={false}
                forceReRender={[transaction.amount, currency, defaultSettings.style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: currency,
                                        value: transaction.amount,
                                    },
                                    reference_id:transaction.transaction_id,
                                    invoice_id:transaction.transaction_id,
                                    custom_id:transaction.transaction_id
                                }
                            ],
                        })
                        .then((orderId) => {
                            // Your code here after create the order
                            return orderId;
                        });
                }}
                onApprove={function (data, actions) {
                    return actions.order.capture().then(function (response) {
                      //payment captured
                        dispatch_(setTransactionProcessing(true))
                        EventTracker.logEvent('PAY_WITH_PAYPAL_SUCCESS')
                        // dispatch_(getTransaction(transaction.transaction_id))
                    });
                }}
                onError={function(err){
                  dispatch(setTransactionProcessing(false))
                  EventTracker.logEvent('PAY_WITH_PAYPAL_FAILURE')
                  console.error(err);
                  dispatch_(createNotification({type:'failure', message:'Paypal payment failed'}));
                }}
            />
        </>
    );
}

export default function PayPalButton(props) {
  let [showPayPal, setShowPayPal] = useState(false)
  let {transaction_processing} = useSelector((state) => state.checkout.value);

	return (
		<div style={{ maxWidth: "750px", minHeight: "200px" }} className="py-3">
      <PayPalScriptProvider options={initialOptions}>
      {
        transaction_processing?
        <div className="d-grid">
          <button
            className="btn btn-light btn-lg d-flex justify-content-between align-items-center">
            <span>{" "}Processing payment</span>
            <div className="spinner-border spinner-border-sm" role="status">
              <span className="sr-only">...</span>
            </div>
          </button>
        </div>
        :
        (showPayPal && !transaction_processing)?
        <ButtonWrapper
          transaction={props.transaction}
          currency={defaultSettings.currency}
          showSpinner={false}
        />
        :
        <div className="d-grid" onClick={()=>EventTracker.logEvent('PAY_WITH_PAYPAL')}>
          <button onClick={()=> setShowPayPal(true)} className="btn btn-light btn-lg d-flex justify-content-between align-items-center">
            <span>Pay with PayPal</span>
            <img className="rounded avatar avatar-sm" alt="flutterwave payment gateway" src={paypal}/>
          </button>
        </div>
      }
			</PayPalScriptProvider>
		</div>
	);
}
