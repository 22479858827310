import React from 'react';
import {Helmet} from 'react-helmet-async';
import {useSelector} from 'react-redux';
import {host} from '../../config';
import {StandardNavBar, AuthNavBar} from '../../component/nav';
import logo from '../../assets/images/logo/nftmarketafrica_icon.png';
import socialbanner from '../../assets/images/social/nftmarket-africa-social-banner.png';
// import visa from '../../assets/images/illustrations/visa.svg';
// import mastercard from '../../assets/images/illustrations/mastercard.svg';
import favicon from '../../assets/images/favicon/favicon.ico';
import favicon180 from '../../assets/images/favicon/apple-touch-icon.png';
import favicon32 from '../../assets/images/favicon/favicon-32x32.png';
import favicon16 from '../../assets/images/favicon/favicon-16x16.png';
import manifestJson from '../../assets/images/favicon/site.webmanifest';
import paypal from '../../assets/images/illustrations/paypal2.svg'
import visa1 from '../../assets/images/illustrations/visa1.svg'
import mastercard1 from '../../assets/images/illustrations/mastercard1.svg'
import bitcoin from '../../assets/images/illustrations/bitcoin.svg'

export default function LandingWrapper(props) {
  let {metadata} = props;
  let {loggedIn} = useSelector( state => state.auth.value)
  return (
    <>
    <Helmet>
      <title>{metadata.title}</title>
      <meta name="title" content={metadata.meta_title}/>
      <meta name="description" content={metadata.description}/>
      <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1"/>
      <link rel="canonical" href={host + window.location.pathname + window.location.search}/>
      <link rel="icon" href={host + favicon}/>
      <link rel="apple-touch-icon" sizes="180x180" href={host + favicon180}/>
      <link rel="icon" type="image/png" sizes="32x32" href={host + favicon32}/>
      <link rel="icon" type="image/png" sizes="16x16" href={host + favicon16}/>
      <link rel="manifest" href={host + manifestJson}/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content={host + window.location.pathname + window.location.search}/>
      <meta property="og:title" content={metadata.title || "NFT Market Africa"}/>
      <meta property="og:description" content={metadata.description || "We help creators to provide physical utility"}/>
      <meta property="og:image" content={metadata.social_image || host + socialbanner}/>
      <meta property="og:site_name" content="NFT Market Africa"/>
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content={host + window.location.pathname + window.location.search}/>
      <meta property="twitter:title" content={metadata.title || "NFT Market Africa"}/>
      <meta property="twitter:description" content={metadata.description}/>
      <meta property="twitter:image" content={metadata.social_image || host + socialbanner}/>
      <meta name="twitter:site" content="@nftmarketafrica"/>
      <meta name="twitter:image:alt" content={metadata.image_alt || "NFT Market Africa"}/>
    </Helmet>
    {
      loggedIn?
      <AuthNavBar/>
      :
      <StandardNavBar/>
    }
    <div className="min-vh-75 overflow-hidden">
      {props.children }
    </div>
    {
      props.showAcceptedPayments?
      <section>
        <div className="py-10 py-lg-12 bg-white px-1 px-lg-0">
          <div className="container">
            <div className="row mb-6 justify-content-start">
              <div className="col-12 mb-6">
                {/* caption */}
                <span className="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                  Payment
                </span>
                <h2 className="mb-2 display-4 fw-bold">
                  Supported payment methods
                </h2>
              </div>
            </div>
            <div className="row bg-dark py-6 rounded-4">
              <div className="col-12">
                <div className="p-3 p-lg-6">
                  <div className="text-center">
                    <h2 className="display-2 text-white fw-bold mb-3">
                      Payment made easy with us
                    </h2>
                    <p className="lead text-white">
                    Make payment the way you already know
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row p-3 p-lg-6 text-center justify-content-center align-items-center py-6">
                  <div className="col-6 col-lg-3 py-3 px-3">
                    <img src={paypal}  alt="paypal" width={140}/>
                  </div>
                  <div className="col-6 col-lg-3 py-3 px-3">
                    <img src={visa1} alt="visa1" width={100}/>
                  </div>
                  <div className="col-6 col-lg-3 py-3 px-3">
                    <img src={mastercard1} alt="mastercard1" width={150}/>
                  </div>
                  <div className="col-6 col-lg-3 py-3 px-3">
                    <img src={bitcoin} alt="bitcoin" width={150}/>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center">
                    <a href="/discover" className="btn btn-lg brand-button-1 btn-outline-light mt-3 rounded-3">Shop the Collections</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      :
      null
    }
    <div className="py-10 footer bg-white">
      <div className="container border-top border-2 border-light py-6">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            {/* about company */}
            <div className="mb-4">
              <img src={logo} alt="logo" width={50} />
              <div className="mt-3 text-dark fs-4">
                <p>© 2023 NFT Market Africa. All Rights Reserved</p>
              </div>
              <div className="mt-4">
                {/* social media */}
                <div className="fs-4 mt-4">
                  <a href="https://discord.gg/3WAnWJJC" target="blank_" className="btn btn-xs btn-outline-white me-3"><i className="mdi mdi-discord fs-4 text-dark fs-4"/></a>
                  <a href="https://twitter.com/nftmarketafrica" target="blank_"  className="btn btn-xs btn-outline-white me-3"><i className="mdi mdi-twitter fs-4 text-dark fs-4"/></a>
                  <a href="https://instagram.com/nftmarketafrica" target="blank_" className="btn btn-xs btn-outline-white"><i className="mdi mdi-instagram fs-4 text-dark fs-4"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="offset-lg-1 col-lg-2 col-md-3 col-6">
            <div className="mb-4">
              {/* list */}
              <h3 className="fw-bold mb-3 text-dark fs-4">Product</h3>
              <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                <li>
                  <a href="/shop" className="nav-link text-dark fs-4">
                    Shop
                  </a>
                </li>
                <li>
                  <a href="/utility" className="nav-link text-dark fs-4">
                    Utility
                  </a>
                </li>
                <li>
                  <a href="https://blog.nftmarket.africa" className="nav-link text-dark fs-4">
                    Blog
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-3 col-6">
            <div className="mb-4">
              {/* list */}
              <h3 className="fw-bold mb-3 text-dark fs-4">Company</h3>
              <ul className="list-unstyled nav nav-footer flex-column nav-x-0">
                <li>
                  <a href="/contact/support-ticket" className="nav-link text-dark fs-4">
                    Contact
                  </a>
                </li>
                <li>
                  <a href="/terms-and-conditions" className="nav-link text-dark fs-4">
                    T & C
                  </a>
                </li>
                <li>
                  <a href="/privacy-policy" className="nav-link text-dark fs-4">
                    Privacy
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
//@NFTmarket.africa 2022
