import React from 'react';
import {useDispatch} from 'react-redux';
import {disconnectAccount} from '../../features/auth/authSlice';

export default function MenuModal(props){
  let dispatch = useDispatch()
  return (
    <div
      className="offcanvas offcanvas-end"
      tabIndex={-1}
      id="menuModal"
      aria-labelledby="menuModalLabel"
    >
      <div className="offcanvas-header">
        <h5 id="menuModalLabel">Account Menu</h5>
        <button
          type="button"
          className="btn-close text-reset text-dark"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
        <i className="fe fe-x-circle " />
        </button>
      </div>
      <div className="offcanvas-body bg-light">
        <ul className="list-group overflow-hidden fs-4">
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <a href="/profile">Profile</a>
            <span className="badge bg-primary "><i className="fe fe-user"/></span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <a href="/gallery">Gallery</a>
            <span className="badge bg-primary "><i className="fe fe-image"/></span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <a href="/wallet">Wallet</a>
            <span className="badge bg-primary "><i className="fe fe-dollar-sign"/></span>
          </li>
          <li className="list-group-item d-flex justify-content-between align-items-center">
            <a href="/sales-orders">Sales & Orders</a>
            <span className="badge bg-primary "><i className="fe fe-shopping-bag"/></span>
          </li>
        </ul>
        <ul className="list-group overflow-hidden fs-4">
          <li className="list-group-item d-flex justify-content-between align-items-center" onClick={() => dispatch(disconnectAccount())}>
            <a href="/profile">Logout</a>
            <span className="badge bg-primary "><i className="fas fa-power-off"/></span>
          </li>
        </ul>
      </div>
    </div>
  )
}
//@NFTmarket.africa 2022
