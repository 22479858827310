import React, {useState, useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import blank from '../assets/images/tools/blank_image.png';
import {getPost, updatePost} from '../features/post/postSlice';
import {getUserProfile} from '../features/user/userSlice';
import {utilities} from '../store/data';
import done from '../assets/animations/done.json';
import PostCardContent from '../component/post_card/card_content';
import AnimationCard from '../component/ui_kits/animation/animation_card';
// import Media from '../component/cards/media';
import {
  LandingWrapper
} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();


let metadata = {
  title:"NFT Market Africa - Update Item",
  meta_title:"NFT Market Africa - Update Item",
  description:"Update your NFT item"
}

const EditItem =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('EDIT_ITEM_OPTIONS')
    }
  },[pageLoaded, setPageLoaded])

  let navigate = useNavigate();
  let dispatch = useDispatch()
  let {post_id} = useParams()
  let user = useSelector(state => state.user.value)
  let post = useSelector(state => state.post.value)
  let {retrieved_post} = post;

  let [update, setUpdate] = useState({
    category:"artwork",
    has_physical_item:false
  });
  let [complete, setCompleteStatus] = useState(false);

  useEffect(()=>{
    if(user && user.profile && !user.profile.profile_verified){
      navigate('/dashboard')
    }
  },[user, navigate])

  //get required data
  useEffect(()=>{
    dispatch(getUserProfile())
    dispatch(getPost(post_id))
  },[dispatch, post_id])

  //set default post utilities
  useEffect(()=>{
    if(retrieved_post){
      setUpdate((update)=> {
        return {
          ...update,
          utilities:retrieved_post.utilities
        }
      })
    }
  },[retrieved_post])

  //handle post edit
  let handleSubmit =(e)=> {
    e.preventDefault()
    let data = {
      amount:update['amount'] || retrieved_post['amount'],
      total_supply:update['total_supply '] || retrieved_post['total_supply'],
      ...update,
      post_id
    }
    dispatch(updatePost(data)).then((response)=>{
      if(response.meta && response.meta.requestStatus === "fulfilled"){
        EventTracker.logEvent('SET_ITEM_OPTIONS_SUCCESS')
        setCompleteStatus(true);
      }else{
        EventTracker.logEvent('SET_ITEM_OPTIONS_FAILURE')
      }
    });
  }

  let handleInput =(e)=> {
    let {value, name, accept} = e.target;
    update = {
      ...update,
      [name]:value
    }
    if(value && value.match(accept)){
      setUpdate(update)
    }
  }

  let handleToggleCategory =(e)=> {
    let {value} = e.target;
    update = {
      ...update,
      category:value
    }
    if(value){
      setUpdate(update)
    }
  }

  // let handlePhysicalItemToggle =(e)=> {
  //   update = {
  //     ...update,
  //     has_physical_item: retrieved_post?!retrieved_post.has_physical_item:!update.has_physical_item
  //   }
  //   setUpdate(update)
  // }

  let handleAddUtility =(e)=> {
    let {value} = e.target;
    if(update.utilities){
      setUpdate({
        ...update,
        utilities:{
          ...update.utilities,
          [value]:{
            include:!update.utilities[value]["include"],
            slug:value
          }
        }
      })
    }else{
      setUpdate({
        ...update,
        utilities:{
          ...utilities,
          [value]: {
            include:true,
            slug:value
          }
        }
      })
    }
  }
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 bg-white min-vh-75">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/storefront">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
          </div>
          <div className="bs-stepper py-3">
            <div className="row">
              <div className="offset-lg-1 col-lg-10 col-md-12 col-12">
                <div className="bs-stepper-header shadow-sm rounded-4" role="tablist">
                  <div className="step" data-target="#test-l-1">
                    <button
                      type="button"
                      className="step-trigger"
                      role="tab"
                      id="courseFormtrigger1"
                      aria-controls="test-l-1"
                      disabled
                    >
                      <span className="bs-stepper-circle">1</span>
                      <span className="bs-stepper-label">Select your Item</span>
                    </button>
                  </div>
                  <div className="bs-stepper-line" />
                  <div className={`${'step'} ${!complete? 'active' : ''}`} data-target="#test-l-2">
                    <button
                      type="button"
                      className="step-trigger"
                      role="tab"
                      id="courseFormtrigger2"
                      aria-controls="test-l-2"
                      onClick={()=> setCompleteStatus(false)}
                    >
                      <span className="bs-stepper-circle">2</span>
                      <span className="bs-stepper-label">Item Details</span>
                    </button>
                  </div>
                  <div className="bs-stepper-line" />
                  <div className={`${'step'} ${complete? 'active' : ''}`} data-target="#test-l-3">
                    <button
                      type="button"
                      className="step-trigger"
                      role="tab"
                      id="courseFormtrigger3"
                      aria-controls="test-l-3"
                    >
                      <span className="bs-stepper-circle">3</span>
                      <span className="bs-stepper-label">Complete</span>
                    </button>
                  </div>
                </div>

                {
                  complete?
                  <div className="mt-5">
                    <div className="show fade">
                      <div className="card mb-3 ">
                        <div className="card-header border-bottom px-4 py-3">
                          <h4 className="mb-0">Complete</h4>
                        </div>
                        <div className="card-body py-6">
                          <div className="row justify-content-between align-items-center">
                            <div className="col-12 col-lg-6 col-md-12">
                            {
                              retrieved_post?
                              <PostCardContent
                              showLikes={false}
                              data={retrieved_post}
                              resolution={{width:"400px",height:"400px"}}
                              />
                              :
                              null
                            }
                            </div>
                            <div className="d-flex col-12 col-lg-6 justify-content-center align-items-center" style={{zIndex:1}}>
                              <div className="col-6 card shadow-none text-center">
                                <AnimationCard
                                animation={done}
                                style={{width:"100%", textAlign:'center'}}
                                options={{loop:false}}/>
                                <p className="fs-4">Your Item has been listed</p>
                                <div className="col-12">
                                  <Link to="/storefront" className="btn btn-primary brand-button">
                                    Go to Storefront
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="mt-5">
                    <div className="show fade">
                      <div className="card mb-3 rounded-4">
                        <div className="card-header border-bottom px-4 py-3">
                          <h4 className="mb-0">Edit Item</h4>
                        </div>
                        <div className="card-body py-6">
                          <div className="row">
                          {
                            retrieved_post?
                            <>
                            <div className="col-12 col-lg-6 col-md-12">
                              <PostCardContent
                              showLikes={false}
                              data={retrieved_post}
                              resolution={{width:"400px",height:"400px"}}
                              />
                            </div>
                            <div className="col-12 col-lg-6 gy-5 gy-lg-0" style={{zIndex:1}}>
                              <form>

                                <div className="mb-3">
                                  <label htmlFor="category" className="form-label">
                                    Category
                                  </label>
                                  <div>
                                    <select className="form-select form-select-lg rounded-4" defaultValue={retrieved_post.category} id="category" data-width="100%" onChange={handleToggleCategory}>
                                      <option value={"artwork"}>Art</option>
                                      <option value={"fashion"}>Fashion</option>
                                      <option value={"music"}>Music</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="amount" className="form-label">
                                    Amount
                                  </label>
                                  <input
                                    id="amount"
                                    name="amount"
                                    className="form-control rounded-4 form-control-lg"
                                    type="number"
                                    onChange={handleInput}
                                    placeholder="0.00 USD"
                                    maxLength={10}
                                    defaultValue={retrieved_post.amount}
                                    required
                                  />
                                  <small>Enter a price for your item</small>
                                </div>
                                <div className="mb-3">
                                  <label htmlFor="total_supply" className="form-label">
                                    Total item available
                                  </label>
                                  <input
                                    id="total_supply"
                                    name="total_supply"
                                    className="form-control rounded-4 form-control-lg"
                                    type="number"
                                    onChange={handleInput}
                                    placeholder="10"
                                    maxLength={10}
                                    defaultValue={retrieved_post.total_supply}
                                    required
                                  />
                                  <small>How many edition is your item?</small>
                                </div>
                                {/*
                                <div className="mb-3">
                                  <label htmlFor="twitter_link" className="form-label">
                                    Twitter Link
                                  </label>
                                  <div className="input-group has-validation">
                                    <span className="input-group-text rounded-0 rounded-start" id="inputGroupPrepend">
                                      <img src={twitter} width={15} alt="twitter"/>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control rounded-4 form-control-lg"
                                      id="twitter_link"
                                      name="twitter_link"
                                      onChange={handleInput}
                                      aria-describedby="inputGroupPrepend"
                                      defaultValue={retrieved_post.twitter_link}
                                      required
                                    />
                                  </div>
                                  <small>Enter a link to item on Twitter.</small>
                                </div>

                                <div className="mb-3">
                                  <label htmlFor="instagram_link" className="form-label">
                                    Instagram Link
                                  </label>
                                  <div className="input-group has-validation">
                                    <span className="input-group-text rounded-0 rounded-start" id="inputGroupPrepend">
                                      <img src={instagram} width={15} alt="instagram"/>
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control rounded-4 form-control-lg"
                                      id="instagram_link"
                                      name="instagram_link"
                                      onChange={handleInput}
                                      aria-describedby="inputGroupPrepend"
                                      defaultValue={retrieved_post.instagram_link}
                                      required
                                    />
                                  </div>
                                  <small>Enter a link to item on Instagram.</small>
                                </div>

                                <hr className="mt-6"/>
                                <h3>Add Utility</h3>
                                <div className="mb-3">
                                  <div>
                                    <h4 className="mb-0">NFT iFrame</h4>
                                  </div>
                                  <div>
                                    <div className="form-check form-switch">
                                      <input
                                        className="form-check-input form-control-xs"
                                        type="checkbox"
                                        id="checkAll"
                                        defaultChecked={retrieved_post.has_physical_item}
                                        onChange={handlePhysicalItemToggle}
                                      />
                                      <label className="form-check-label" htmlFor="checkAll" />
                                    </div>
                                  </div>
                                </div>
                                */}
                                <hr className="mt-6"/>
                                <div>
                                  <h4 className="mb-0">Utility (optional)</h4>
                                  <small className="mb-5">
                                    Add utility to your Item
                                  </small>
                                  {/* List group */}
                                  <ul className="list-group list-group-flush overflow-hidden pt-3" onClick={()=> EventTracker.logEvent('ADDED_ITEM_UTILITY')}>
                                    {/* List group item */}
                                    <li className="py-3 list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                                      <div>NFT iFrame</div>
                                      <div>
                                        <div className="form-check form-switch">
                                          <input
                                            type="checkbox"
                                            className="form-check-input form-control-xs"
                                            id="swicthOne"
                                            value="iframe"
                                            defaultChecked={update.utilities&&update.utilities.iframe.include}
                                            onClick={handleAddUtility}
                                          />
                                          <label className="form-check-label" htmlFor="swicthOne" />
                                        </div>
                                      </div>
                                    </li>
                                    {/* List group item */}
                                    <li className="py-3 list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                                      <div>Merchandise</div>
                                      <div>
                                        <div className="form-check form-switch">
                                          <input
                                          type="checkbox"
                                          className="form-check-input form-control-xs"
                                          id="switchTwo"
                                          value="merch"
                                          defaultChecked={update.utilities&&update.utilities.merch.include}
                                          onClick={handleAddUtility}
                                          />
                                          <label className="form-check-label" htmlFor="switchTwo" />
                                        </div>
                                      </div>
                                    </li>
                                    {/* List group item */}
                                    <li className="py-3 list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                                      <div>Physical Work</div>
                                      <div>
                                        <div className="form-check form-switch">
                                          <input
                                            type="checkbox"
                                            className="form-check-input form-control-xs"
                                            id="switchThree"
                                            value="physical_work"
                                            defaultChecked={update.utilities&&update.utilities.physical_work.include}
                                            onClick={handleAddUtility}
                                          />
                                          <label className="form-check-label" htmlFor="switchThree" />
                                        </div>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </form>
                            </div>
                            </>
                            :
                            <>
                            <div className="col-12 col-lg-6">
                              <div className="card-body rounded text-center bg-white m-auto clickable py-0">
                                <img
                                  src={blank}
                                  alt="nft"
                                  className="card-img-top rounded-top-md"
                                  style={{height: '100%', width: '100%', objectFit: 'contain'}}
                                />
                              </div>
                            </div>
                            <div className="col-12 col-lg-6 gy-5 gy-lg-0">
                              <form>
                                <div className="mb-3">
                                  <label htmlFor="amount" className="form-label">
                                    Amount
                                  </label>
                                  <input
                                    id="amount"
                                    name="amount"
                                    className="form-control rounded-4 form-control-lg"
                                    type="number"
                                    placeholder="0.00 USD"
                                    maxLength={10}
                                    disabled
                                  />
                                  <small>Enter the amount you want to sell your item.</small>
                                </div>
                              </form>
                            </div>
                            </>
                          }
                          </div>
                        </div>
                      </div>
                      {
                        post.updating?
                        <button className="btn btn-primary brand-button" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {" "}Completing...
                        </button>
                        :
                        <button
                        className="btn btn-primary brand-button"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={!retrieved_post}
                        >
                        Complete
                        </button>
                      }
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default EditItem;
//@ NFTmarket.africa 2022
