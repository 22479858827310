import React, { useEffect } from "react";
import {Navigate } from "react-router-dom";
import {useDispatch, useSelector} from 'react-redux';
// import {getUserProfile} from '../features/user/userSlice';
// import Cookies from 'js-cookie';
// let token = Cookies.get('c-token')

function Protected({children}){
  let dispatch = useDispatch();
  let {loggedIn} = useSelector( state => state.auth.value)

  useEffect(()=>{
    // if(loggedIn && token) dispatch(getUserProfile());
  },[dispatch, loggedIn])
  return loggedIn? children : <Navigate to="/signin" />;
}

export default Protected;

//@NFTmarket.africa 2022
