import React, {useEffect, useState} from 'react';
import {LandingWrapper} from './wrapper';
// import AnimationCard from '../component/ui_kits/animation/animation_card';
import {Amplitude} from '../features/tools';
// import fastway from '../assets/images/brand/fastway.gif';
// import postnet from '../assets/images/brand/postnet.png';
import dhl from '../assets/images/brand/dhl.svg';
// import merch from '../assets/images/tools/merch.png';
// import iframe from '../assets/images/tools/iframe.png';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Utility",
  meta_title:"NFT Market Africa - Utility",
  description:"Discover Utilities"
}

const Utility =(props)=> {

  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_UTILITY_PAGE')
    }
  },[pageLoaded, setPageLoaded])

  return (
    <LandingWrapper
    showNavigation={true}
    metadata={metadata}
    showAcceptedPayments={true}
    >
    <section className="pt-20 mt-lg-4 py-6 bg-white bg-cover px-1 px-lg-0">
      <div className="container py-10 px-5 bg-dark rounded-4">
        <div className="row justify-content-center align-items-center px-3 px-lg-6">
          <div className='col-12 col-lg-8'>
            <div className="">
              <div className="text-center text-md-start">
                {/* heading */}
                <h1 className="display-2 fw-bold mb-3 text-white">
                  Physical Utility
                </h1>
                {/* lead */}
                <p className="lead text-white">
                  We ship physical items along with NFTs purchased on our marketplace.
                </p>
                <a
                href="#product1"
                className="btn btn-lg btn-outline-light brand-button rounded-3 border-2">
                View Products
                </a>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-7 col-lg-4 pt-6 pt-lg-0'>
          </div>
        </div>
      </div>
    </section>
    <section className="py-6 position-relative bg-white px-1 overflow-hidden">
      <div className="container py-10 rounded-4 border border-3 border-light">
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-lg-6">
            <div className="p-3 p-lg-6">
              <span className="fw-bold">TOKENWEAR.XYZ</span>
              <h1 className="display-3 mb-5 fw-bold">
                Token Wear
              </h1>
              <p className="lead">A tokenized physical wear that lets you showcase ownership of your NFT.</p>
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="position-relative">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-6 position-relative bg-white px-1 overflow-hidden" id="iframe">
      <div className="container py-10 rounded-4 border border-3 border-light">
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-lg-6">
            <div className="p-3 p-lg-6">
              <span className="fw-bold">IFRAME.APP</span>
              <h1 className="display-3 mb-5 fw-bold">
                NFT iFrame
              </h1>
              <p className="lead">A smart digital collectible frame for home decoration</p>
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="position-relative">
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="py-6 position-relative bg-white px-1 overflow-hidden">
      <div className="container py-10 bg-brand-1 rounded-4">
        <div className="row justify-content-between align-items-center">
          <div className="col-12 col-lg-6">
            <div className="p-3 p-lg-6">
              <span className="text-white fw-bold">EXPERIENCE IT!</span>
              <h1 className="display-3 mb-5 fw-bold text-white">
                Quick Delivery
              </h1>
              <p className="lead">Get your Physical Items within 7 days in South Africa and within 21 days for international delivery.</p>
            </div>
            <div className="px-3 px-lg-6">
              <img src={dhl} alt="dhl" width={100}/>
            </div>
          </div>
          <div className="col-lg-5 col-12">
            <div className="position-relative">
            </div>
          </div>
        </div>
      </div>
    </section>
      {/*
      <div className="py-lg-16 pb-8 bg-white">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mb-4 mb-lg-0">
                <img
                  src={iframe}
                  alt="iframe"
                  className="img-fluid w-100"
                />
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12 mt-4 mt-lg-0">
              <div className="ps-lg-7">
                <span
                  className=" text-primary ls-md text-uppercase
                fw-semi-bold"
                >
                  Beautify your wall with
                </span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  NFT iFrame
                </h2>
                <h3>
                  A flat compact frame that fits on your wall.
                </h3>
                <div className="mt-5 row">
                  <div className="col">
                    <ul
                      className="list-unstyled fs-4
                    fw-medium"
                    >
                      <li className="mb-2 d-flex">
                        <i
                          className="mdi mdi-check-circle
                        text-success me-2"
                        />
                        Modern Design{" "}
                      </li>
                      <li className="mb-2 d-flex">
                        <i
                          className="mdi mdi-check-circle
                        text-success me-2"
                        />
                        Flat & Compact
                      </li>
                      <li className="mb-2 d-flex">
                        <i
                          className="mdi mdi-check-circle
                        text-success me-2"
                        />
                        Blockchain Verification
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul
                      className="list-unstyled fs-4
                    fw-medium"
                    >
                      <li className="mb-2 d-flex">
                        <i
                          className="mdi mdi-check-circle
                        text-success me-2"
                        />
                        Light weight{" "}
                      </li>
                      <li className="mb-2 d-flex">
                        <i
                          className="mdi mdi-check-circle
                        text-success me-2"
                        />
                        Easy to hang
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-lg-12 my-8" />
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12 col-12">
              <div className="pe-lg-6 ps-lg-6">
                <span
                  className="text-primary ls-md text-uppercase
                fw-semi-bold"
                >
                  Wear your NFT
                </span>
                <h2 className="display-4 mt-4 mb-3 fw-bold">
                  Merchandise
                </h2>
                <h3>Claim T-shirt Merchandise.</h3>
                <div className="mt-6">
                  <div className="d-flex mb-4">
                    <i className="fas fa-star fs-3 text-success"/>
                    <div className="ms-3">
                      <h3 className="mb-2">5-star Quality </h3>
                      <p className="mb-0 fs-4">
                        Get your T-shirt in Premium quality.
                      </p>
                    </div>
                  </div>
                  <div className="d-flex ">
                    <i className="fas fa-qrcode fs-3 text-success"/>
                    <div className="ms-3">
                      <h3 className="mb-2 lh-1">
                        Blockchain Verification{" "}
                        <span
                          className="badge bg-light-success fs-6 text-success ms-1">
                          Scan T-shirt
                        </span>
                      </h3>
                      <p className="mb-0 fs-4">
                        Scan to proof NFT ownership.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-12">
              <div className="mt-4 mt-lg-0">
                <img
                  src={merch}
                  alt="merchandise"
                  className="img-fluid w-100"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      */}

      <div className="py-10 bg-white">
        <div className="container">
          <div className="row justify-content-center" id="collect">
            <div className="col-12">
              <div className="mb-4 mt-6">
                <span>QUESTIONS</span>
                <h2 className="mb-0 fs-2 fw-bold">FAQs</h2>
              </div>
              <div className="accordion accordion-flush" id="collectAccordion" onClick={()=> EventTracker.logEvent('READ_UTILITY_FAQ')}>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="HowToPurchaseNFT">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        What is Physical Utility?
                      </span>
                      <span className="collapse-toggle fs-3 ms-4">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="HowToPurchaseNFT"
                    data-bs-parent="#collectAccordion"
                  >
                    <div className="pt-2 fs-4">
                      Physical Utility are the real world items or benefits that you receive for owning an NFT. It can be a physical painting, physical product such as merchandise or it may be benefits such as exclusive access to an event or community.
                    </div>
                  </div>
                </div>
                {/* Card  */}
                {/* Card header  */}
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="CanIPayWithBTCOrPayPal">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit
                    text-decoration-none"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        What Physical Utility do you offer?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="CanIPayWithBTCOrPayPal"
                    data-bs-parent="#collectAccordion"
                  >
                    <div className="pt-2 fs-4">
                      We help creators to deliver their physical work, we also offer Physical Utilities such as Tokenized Wears, iFrames and physical events.
                    </div>
                  </div>
                </div>
                {/* Card  */}
                {/* Card header  */}
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="HowLongDoesItTakeToReceiveNFT">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit
                    text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <span className="me-auto fs-3 fw-bold">Which countries do you ship to?</span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseSix"
                    className="collapse"
                    aria-labelledby="HowLongDoesItTakeToReceiveNFT"
                    data-bs-parent="#collectAccordion"
                  >
                    <div className="pt-3 fs-4">
                      We ship worldwide. We offer free delivery for orders within South Africa & Nigeria.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*
      <div className="py-14 bg-white">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12 col-12 text-center">
              <span
                className="fs-4 text-warning ls-md text-uppercase
              fw-semi-bold"
              >
                Its life
              </span>
              <h2 className="display-3 mt-4 mb-3 text-dark fw-bold">
                Collect it, Experience it!
              </h2>
              <p className="lead text-dark-50 px-lg- 8 mb-6">
                Art should be seen, felt and occupy a space.
              </p>
              <a href="/discover" className="btn btn-primary">
                Buy NFT
              </a>
            </div>
          </div>
        </div>
      </div>
      */}
    </LandingWrapper>
  )
}
export default Utility;
//@nftmarket.africa 2022
