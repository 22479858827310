import React, {useEffect, useState} from 'react';
import {ethers} from 'ethers';
import {Link} from 'react-router-dom';
import {activateWallet, getWallets, getTransactions} from '../features/wallet/walletSlice';
import {useDispatch, useSelector} from 'react-redux';
import {currencyFormat, Amplitude} from '../features/tools';
import {LandingWrapper} from './wrapper';
import { Network, Alchemy } from 'alchemy-sdk';
let metadata = {
  title:"NFT Market Africa - Wallet",
  meta_title:"NFT Market Africa - Wallet",
  description:"View your wallet"
}
let EventTracker = new Amplitude();


const settings = {
  apiKey:"FWg37YaXHGuIWDp6A-GSY95Twk1PT4kR",
  network: Network.ETH_MAINNET
};

const Wallet =(props)=> {
  let dispatch = useDispatch();
  let [pageLoaded, setPageLoaded] = useState(false);
  let [ethBalance, setEthBalance] = useState(0.00);
  let user = useSelector(state => state.user.value);
  let wallet = useSelector( state => state.wallet);
  let {activating, loading_wallets, data} = wallet.value;

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('VIEWING_WALLET')
    }
  },[pageLoaded, setPageLoaded, ethBalance])

  useEffect(()=>{
    if(user.profile.account){
      const alchemy = new Alchemy(settings);
      alchemy.core.getBalance(user.profile.account, "latest").then((balance)=>{
        setEthBalance(ethers.utils.formatEther(balance));
      }).catch((err)=>{
        console.error(err);
      })
    }
  },[user])

  useEffect(()=>{
    dispatch(getWallets())
    dispatch(getTransactions())
  },[dispatch])

  let handleActivateWallet =()=> {
    dispatch(activateWallet())
  }

  // let handleOpenTransaction =(tx_id)=> {
  //   if(tx_id){
  //     window.location.href = `/tx/${tx_id}`
  //   }
  // }
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 bg-white min-vh-75">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/dashboard">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10 py-3">
              <div className="card bg-light shadow-none rounded-4 clickable hover">
                <div className="card-body">
                  <div className="py-3">
                    <span className="fs-5 fw-bold">Marketplace  Wallet</span>
                    <h3 className="display-4 py-2">{data&&data.length?currencyFormat(data[0]['balance'],'USD'):"$0.00"}</h3>
                    {
                      !loading_wallets&&!data.length?
                        <button
                          type="button"
                          className="btn btn-primary brand-button"
                          disabled={activating || loading_wallets}
                          onClick={handleActivateWallet}
                        >
                         Activate Wallet
                        </button>
                      :
                      (!loading_wallets&&data.length)?
                      <button
                        className="btn btn-primary brand-button" disabled={false}
                      >
                       Withdraw
                      </button>
                      :
                      <button
                        disabled
                        className="btn btn-primary brand-button">
                        <div className="spinner-border spinner-border-sm" role="status">
                          <span className="sr-only"></span>
                        </div>
                      </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10 py-3">
              <div className="card bg-light shadow-none rounded-4 clickable hover">
                <div className="card-body">
                  <div className="py-3">
                    <span className="fs-5 fw-bold">Ethereum Wallet</span>
                    <h3 className="display-4 py-2">{ethBalance?ethBalance.slice(0,6):ethBalance} ETH</h3>
                    <button className="btn btn-primary brand-button" disabled={false}>
                      Withdraw
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Wallet;
//@ NFTmarket.africa 2022
