import React, {useState, useEffect} from 'react';
import blank from '../../assets/images/tools/blank_image.png';
import {getURL} from '../../features/tools';

export default function Media(props){
  let [data, setData] = useState({media_url:blank});
  let {metadata, token_address, token_id} = props.data.alchemy_data;
  useEffect(()=>{
    if(metadata){
      let parsed_metadata = JSON.parse(metadata)
      setData( prev => {
        return {
          ...prev,
          ...parsed_metadata,
          token_address,
          token_id,
          media_url:getURL(parsed_metadata.image)
        }
      })
    }else{
      console.log('media not detected');
    }
  },[metadata, token_id, token_address])

  let mediaTypeIsVideo =(url)=> {
    if(url){
      let url_ = new URL(url)
      let {pathname} = url_
      //get url extension
      let type = pathname.split('.').pop()
      if(type.match('mp4')){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }

  return (
    <div style={{zIndex:1}}>
    {
      mediaTypeIsVideo(data.media_url)?
      <video
      className="rounded-4 card-img-top"
      alt="NFT"
      type="video/mp4"
      loop
      autoPlay
      muted={true}
      src={data.media_url}
      style={{...props.resolution}}
      />
      :
      <img
      className={`${props.className ||"rounded-4"}`}
      alt="nft media"
      src={data.media_url}
      style={{...props.resolution}}
      />
    }
    </div>
  )
}
// <div className="card-img-overlay"></div>
//@NFTmarket.africa 2022
