import React, { useState} from 'react'
import {useDispatch} from 'react-redux';
import {useDropzone} from 'react-dropzone'
import blank_banner from '../../assets/images/background/blank_image.png';
import {uploadToFirebase} from '../../service/firebase';
import {updateProfile} from '../../features/user/userSlice';

export default function UpdateBanner(props) {
  let [uploaded, setUploaded] = useState();
  let dispatch = useDispatch();
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept
  } = useDropzone({
    accept: '.jpeg,.png,.jpg',
    multiple:false,
    onDrop: (files) => {
      console.log(isDragAccept);
      let reader = new FileReader();
      reader.onabort = () => console.log('file reading was aborted')
      reader.onerror = () => console.log('file reading has failed')
      reader.onload = () => {
        const baseStr = reader.result
        setUploaded(baseStr)
      }
      reader.readAsDataURL(files[0]);
      uploadToFirebase('banner', files[0]).then((response)=>{
        dispatch(updateProfile({banner:response}));
      }).catch((err)=>{
        console.error(err);
      })
    }
  });

  return (
    <div
      className="custom-file-container bg-white rounded-4 p-3 mb-3"
      data-upload-id="banner"
      id="banner"
      {...getRootProps()}
    >
      <input {...getInputProps()}/>
      <label className="form-label">
        Profile banner image
      </label>
      <label className="custom-file-container__custom-file">
      {
        isDragActive ?
        <span className="custom-file-container__custom-file__custom-file-control">
          Drop files here...
          <span className="custom-file-container__custom-file__custom-file-control__button">
            Upload
          </span>
        </span>
        :
        isDragAccept?
        <span className="custom-file-container__custom-file__custom-file-control">
          Accepted
          <span className="custom-file-container__custom-file__custom-file-control__button">
            Change
          </span>
        </span>
        :
        <span className="custom-file-container__custom-file__custom-file-control">
          {props.banner || uploaded?
            "Change Image":
            "No Image Selected"
          }
          <span className="custom-file-container__custom-file__custom-file-control__button">
            Upload
          </span>
        </span>
      }
      </label>
      <div
        className="custom-file-container__image-preview rounded-4"
        style={{ backgroundImage: `url(${uploaded|| props.banner||blank_banner})` }}
      />
    </div>
  )
}
//@NFTmarket.africa 2022
