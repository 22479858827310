import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {getPost} from '../features/post/postSlice';
import {getUserProfile} from '../features/user/userSlice';
import {getWallets} from '../features/wallet/walletSlice';
import {getGalleries} from '../features/gallery/gallerySlice';
import {createOrder, createCharge} from '../features/checkout/checkoutSlice';
import {getPrice, getUtilityPrice, sliceCharacter, Amplitude, getEtherScanUrl, getOpenseaUrl} from '../features/tools';
import {utility_prices} from '../store/data';
import DataApi from '../service/api/data_api';
import {
  LandingWrapper
} from './wrapper';
import Media from '../component/cards/media';
import country_data from '../store/countries';
let api = new DataApi();
let EventTracker = new Amplitude();
let metadata = {
  title:"NFT Market Africa - Buy",
  meta_title:"NFT Market Africa - Buy",
  description:"Create checkout and make payment"
}

const Buy =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_BUY_PAGE')
    }
  },[pageLoaded, setPageLoaded])

  let dispatch = useDispatch()
  let {post_id} = useParams()
  let user = useSelector(state => state.user.value)
  let post = useSelector(state => state.post.value)
  let checkout = useSelector(state => state.checkout.value)
  let galleries = useSelector( state => state.gallery.value)
  let wallet = useSelector( state => state.wallet.value)
  let [data, setData] = useState({})
  let [order, setOrder] = useState({showAddressInput:false})
  let [countryData, setCountryData] = useState([])
  let [selectedCountry, setSelectedCountry] = useState(null)
  let [receivable_gallery, setReceivableGallery] = useState(null)

  useEffect(()=>{
    setCountryData(country_data.countries)
  },[countryData])

  useEffect(()=>{
    dispatch(getPost(post_id))
    dispatch(getUserProfile())
    dispatch(getWallets())
    dispatch(getGalleries())
  },[dispatch, post_id])

  //set order details
  useEffect(()=>{
    if(post.retrieved_post){
      //get platform fees
      api.getPlatform().then((response)=>{
        let {order_fee} = response.data[0];
        setOrder((prev) => {
          return {
            ...prev,
            order_fee,
            total:getPrice(utility_prices, (post.retrieved_post.utilities|| {}), post.retrieved_post.amount, {noFormat:true})
          }
        })
      }).catch((err)=>{
        console.error(err);
      })
    }
  },[post.retrieved_post])

  //parse and set item Metadata
  useEffect(()=>{
    if(post.retrieved_post && post.retrieved_post.alchemy_data){
      setData((prev) => {
        return {
          ...prev,
          creator:post.retrieved_post.account,
          blockchain:post.retrieved_post.blockchain,
          token_id:post.retrieved_post.token_id,
          contract_address:post.retrieved_post.contract_address,
          ...JSON.parse(post.retrieved_post.alchemy_data.metadata)
        }
      })
    }
  },[post.retrieved_post])

  let handleCreateOrder =(e)=> {
    if(order.collectors_address){
      let details = {
        post_id,
        ...order
      }
      dispatch(createOrder(details)).then((response)=>{
        if(response&&response.error){
          EventTracker.logEvent("ENCOUNTED_CHECKOUT_ERROR")
        }
      }).catch((err)=>{
        EventTracker.logEvent("ENCOUNTED_CHECKOUT_ERROR")
      })
    }
  }

  let handleCreateCharge = (e) => {
    if(order.collectors_address){
      let details = {
        post_id,
        ...order,
        blockchain:data.blockchain
      }
      dispatch(createCharge(details)).then((response)=>{
        if(response&&response.error){
          EventTracker.logEvent("ENCOUNTED_CHECKOUT_ERROR")
        }
        console.log(response);
      }).catch((err)=>{
        console.error(err);
        EventTracker.logEvent("ENCOUNTED_CHECKOUT_ERROR")
      })
    }
  }

  let handleEnterEmail =(e)=> {
    let {value} = e.target;
    if(value){
      setOrder({
        ...order,
        email:value
      })
    }else{
      setOrder({
        ...order,
        email:null
      })
    }
  }

  let handleEnterAddress =(e)=> {
    let {value} = e.target;
    if(value){
      setOrder({
        ...order,
        collectors_address:value
      })
    }else{
      setOrder({
        ...order,
        collectors_address:null
      })
    }
  }

  let handleSelectCountry =(e)=> {
    let {value} = e.target;
    setSelectedCountry(countryData[value])
    setOrder({
      ...order,
      shipping_address:{
        ...order.shipping_address,
        country:countryData[value]['country']
      }
    })
  }

  let handleEnterShippingAddress =(e)=> {
    let {value, id} = e.target;
    if(value && id){
      setOrder({
        ...order,
        shipping_address:{
          ...order.shipping_address,
          [id]:value
        }
      })
    }else{
      return false
    }
  }

  let handleSelectAddress =(e)=> {
    let {value} = e.target;
    if(value){
      switch (value){
        case "add":
          setOrder({
            ...order,
            showAddressInput:true
          })
          break;
        case "select-address":
          setOrder({
            ...order,
            collectors_address:null,
            showAddressInput:false
          })
          break;
        case "add_shipping":
          setOrder({
            ...order,
            showShippingAddressInput:true
          })
          break;
        default:
          setOrder({
            ...order,
            collectors_address:value,
            showAddressInput:false
          })
      }
    }
  }

  function currencyFormat(amount, currency){
		return (parseFloat(amount)).toLocaleString('en-US', {
		  style: 'currency',
		  currency,
		})
	}

  //set gallery valid for this item as option to receive purchased NFT
  useEffect(()=>{
    if(galleries && galleries.data && galleries.data.length){
      let filtered = galleries.data.filter((gallery) => gallery.chain === data.blockchain);
      if(filtered && filtered.length){
        setReceivableGallery(filtered[0])
      }else{
        setReceivableGallery(null)
      }
    }else{
      setReceivableGallery(null)
    }
  },[galleries, data.blockchain])

  return (
    <LandingWrapper
    showNavigation={true}
    showAcceptedPayments={true}
    metadata={metadata}
    >
    {
      post.retrieving?
      <section className="py-20 bg-white">
        <div className="container vh-75">
          <div className="row justify-content-center align-items-top">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
          </div>
        </div>
      </section>
      :
      <section className="py-15 bg-white">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12">
              <span className="fs-3 fw-bold">
                <a href={`https://nftmarket.africa/p/${post.retrieved_post?.id}`}>
                  <i className="fe fe-arrow-left fw-bolder px-3"/>Back
                </a>
              </span>
            </div>
          </div>
          <div className="row justify-content-between align-items-top py-6 pt-lg-10">
            <div className="col-12 col-lg-5">
              <div className="d-grid justify-content-top">
                <div className="col-12 col-lg-10">
                {
                  post.retrieved_post?
                  <Media data={post.retrieved_post} resolution={{width:'100%', maxHeight:'100%', objectFit:'cover'}} />
                  :
                  null
                }
                </div>
                <div className='col-6 py-3'>
                  <h4 className="fw-bold opacity-75">Title</h4>
                  <h3 className="fw-bold">{data.name}</h3>
                  <a
                  style={{textDecoration:"underline"}}
                  className="text-brand-2"
                  href="/metadata"
                  data-bs-target="#showNFTMetadata"
                  data-bs-toggle="modal"
                  data-bs-dismiss="modal"
                  disabled={post.retrieving}
                  onClick={()=> EventTracker.logEvent('VERIFYING_ITEM')}
                  >
                    <h3 className="text-brand-2 fw-bold">
                    Verify Item{" "}
                    <i className="fe fe-shield"/>
                    </h3>
                  </a>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-12">
              <div className="row">
                <div className='col-12 col-lg-10 pb-4 py-4 mb-lg-4 py-lg-0'>
                  <label htmlFor="email" className="h4 fw-bold opacity-75">Email Address</label>
                  <input
                  defaultValue={order.email}
                  onChange={handleEnterEmail}
                  onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                  className="form-control form-control-lg"
                  placeholder="Notification email"
                  type="email"
                  id="email"
                  required
                  />
                  <small>Where we'll send your order notification</small>
                </div>
                <div className='col-12 col-lg-10 pb-4 py-4 py-lg-0'>
                  <label htmlFor="ens" className="h4 fw-bold opacity-75">Wallet Address</label>
                  {
                    !order.showAddressInput?
                    <select className="form-select form-select-lg py-3 border-light" data-width="100%" onChange={handleSelectAddress} onClick={()=> EventTracker.logEvent('SELECTED_ADDRESS')}>
                      <option value="select-address">Select Address</option>
                      {
                        user.profile?
                        <option value={user.profile.account}>{sliceCharacter(user.profile.account)}</option>
                        :
                        null
                      }
                      {
                        receivable_gallery?
                        <option
                        value={receivable_gallery['address']}>
                          {`Gallery(${receivable_gallery['chain']})` || receivable_gallery['address']}
                        </option>
                        :
                        null
                      }
                      <option value="add">Enter Address or ENS</option>
                    </select>
                    :
                    <>
                    <input id="ens" className="form-control form-control-lg py-3" defaultValue={order.collectors_address} onChange={handleEnterAddress} onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')} placeholder="Enter address or ENS name"/>
                    <p className="clickable pt-2" onClick={(e)=> setOrder({...order,showAddressInput:false})}>Show my Addresses</p>
                    </>
                  }
                  <small>Where to receive your digital collectible</small>
                </div>
                <div className='col-12 col-lg-10 pb-4 py-4 py-lg-4'>
                  <h4 className="fw-bold opacity-75">Shipping Address</h4>
                  {
                    !order.showShippingAddressInput?
                    <select className="form-select form-select-lg py-3 border-light" data-width="100%" onChange={handleSelectAddress} onClick={()=> EventTracker.logEvent('SELECTED_ADDRESS')}>
                      <option defaultValue>Select Shipping Address</option>
                      <option value="add_shipping">Enter Shipping address</option>
                    </select>
                    :
                    <>
                      {/* Validation Form */}
                      <form className="row g-3 needs-validation" noValidate="">
                      <div className="col-md-6">
                        <label htmlFor="first_name" className="form-label fw-bold opacity-75">
                          First name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="first_name"
                          placeholder="Enter first name"
                          required=""
                          onChange={handleEnterShippingAddress}
                          onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                      <div className="col-md-6">
                        <label htmlFor="last_name" className="form-label fw-bold opacity-75">
                          Last name
                        </label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          id="last_name"
                          placeholder="Enter last name"
                          required=""
                          onChange={handleEnterShippingAddress}
                          onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                        <div className="col-12">
                          <label htmlFor="address_1" className="form-label fw-bold opacity-75">
                            Address 1
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="address_1"
                            placeholder="Enter Address 1"
                            required=""
                            onChange={handleEnterShippingAddress}
                            onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </div>
                        <div className="col-md-8">
                          <label htmlFor="address_2" className="form-label fw-bold opacity-75">
                            Address 2
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="address_2"
                            placeholder="Enter Address 2"
                            required=""
                            onChange={handleEnterShippingAddress}
                            onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                          />
                          <div className="valid-feedback">Looks good!</div>
                        </div>
                        <div className="col-md-4">
                          <label htmlFor="country" className="form-label fw-bold opacity-75">
                            Country
                          </label>
                          <select
                          className="form-select form-select-lg rounded-3"
                          id="country"
                          required=""
                          onChange={handleSelectCountry}
                          >
                            <option defaultValue="" disabled="" value="">Choose a country</option>
                            {
                              countryData.map((country, i)=>
                                <option key={i} value={i}>{country.country}</option>
                              )
                            }
                          </select>
                          <div className="invalid-feedback">Please select a valid state.</div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="city" className="form-label fw-bold opacity-75">
                            City
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="city"
                            placeholder="Enter City"
                            required=""
                            onChange={handleEnterShippingAddress}
                            onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                          />
                          <div className="invalid-feedback">Please provide a valid city.</div>
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="state" className="form-label fw-bold opacity-75">
                            State
                          </label>
                          <select
                          className="form-select form-select-lg rounded-3"
                          id="state"
                          required=""
                          onChange={handleEnterShippingAddress}
                          onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                          >
                            <option defaultValue="" disabled="" value="">
                              Choose State
                            </option>
                            {
                              selectedCountry?
                              selectedCountry.states.map((state, i)=>
                                <option key={i}>{state}</option>
                              )
                              :
                              null
                            }
                          </select>
                          <div className="invalid-feedback">Please select a valid state.</div>
                        </div>
                        <div className="col-md-3">
                          <label htmlFor="zip" className="form-label fw-bold opacity-75">
                            Zip
                          </label>
                          <input
                            type="text"
                            className="form-control form-control-lg"
                            id="zip"
                            placeholder="Postal Code"
                            required=""
                            onChange={handleEnterShippingAddress}
                            onInput={()=> EventTracker.logEvent('ENTERED_ADDRESS')}
                          />
                          <div className="invalid-feedback">Please provide a valid zip.</div>
                        </div>
                      </form>
                      <p className="clickable pt-2" onClick={(e)=> setOrder({...order,showShippingAddressInput:false})}>Hide Shipping Address</p>
                    </>
                  }
                  <small>Where we'll deliver your physical Item if any</small>
                  <hr className="text-light"/>
                </div>
              </div>
              {
                wallet.data.length && false?
                <div className="row">
                  <div className='col-12 col-lg-10 pb-4'>
                    <div className="card bg-light shadow-none py-1 mb-4">
                      <div className="card-body">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <span className="fs-4 fw-6">Available Balance</span>
                            <h2 className="fw-bold display-5">{wallet.data&&wallet.data.length?currencyFormat(wallet.data[0]['balance'], 'USD'):<a href="/wallet" className="text-warning fs-3">Activate Wallet</a>}</h2>
                          </div>
                          <a href="/tx/start?type=credit" className="fs-4 btn btn-sm btn-light">Add Funds &nbsp;&nbsp;<i className="fas fa-plus"/></a>
                        </div>
                      </div>
                      <div className="p-3 d-grid">
                      {
                        checkout.creating?
                        <button className="btn rounded-3 btn-primary col-12 d-flex justify-content-center align-items-center" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        </button>
                        :
                        <button
                        className="btn rounded-3 btn-primary col-12 d-flex justify-content-center align-items-center"
                        onClick={handleCreateOrder}
                        disabled={post.retrieving || !order.collectors_address || !wallet.data.length || post.retrieved_post.is_sold}
                        >
                        <span className="me-1">Pay with Balance</span>
                        </button>
                      }
                      </div>
                    </div>
                    <hr className="text-light"/>
                  </div>
                </div>
                :
                null
              }

              <div className="row">
                <div className='col-12 col-lg-10 pb-4'>
                  <ul className="list-group overflow-hidden">
                    <li className="list-item">
                      <div className="d-flex justify-content-between">
                        <p>NFT price</p>
                        <p className="fw-bolder text-dark fs-4">
                          {post.retrieved_post?currencyFormat(post.retrieved_post.amount,'USD'):"..."}
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div className="d-flex justify-content-between">
                        <p>Physical item</p>
                        <p className="fw-bolder text-dark fs-4">
                          {post.retrieved_post?getUtilityPrice(utility_prices, post.retrieved_post.utilities):"..."}
                        </p>
                      </div>
                    </li>
                    <li className="list-item">
                      <div className="d-flex justify-content-between">
                        <p>Total amount</p>
                        <p className="fw-bolder text-brand-2 fs-4">
                          {
                            order.total?
                            currencyFormat(order.total, 'USD')
                            :
                            '$0.00'
                          }
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="d-flex">
                  {
                    post.retrieved_post&&post.retrieved_post.is_sold?
                    <button
                    className="btn btn-success btn-lg py-4 col-12 col-lg-10"
                    >
                      <i className="fe fe-check-circle text-white"/> Sold out
                    </button>
                    :
                    checkout.creating_charge?
                    <div className="col-12 col-lg-10">
                      <button className="btn btn-lg btn-primary py-4 col-12" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {" "}Please wait...
                      </button>
                    </div>
                    :
                    <div className="col-12 col-lg-10">
                    <button
                    className="btn btn-lg btn-primary py-4 col-12"
                    onClick={handleCreateCharge}
                    disabled={post.retrieving || !order.collectors_address || !order.email}
                    >
                    <span>Express Checkout</span>
                    </button>
                    {
                      post.retrieved_post&&post.retrieved_post.shopify_url && false?
                      <a
                      href={post.retrieved_post.shopify_url}
                      className="btn btn-lg btn-light py-4 col-12"
                      target="blanc_"
                      disabled={!post.retrieved_post.shopify_url}
                      >
                      <span>Buy on Shopify</span>
                      </a>
                      :
                      null
                    }
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    }
      {/*modal - verify metadata*/}
      <div
        className="modal fade"
        id="showNFTMetadata"
        aria-hidden="true"
        aria-labelledby="showNFTMetadata"
        tabIndex={-1}
      >
        <div className="modal-dialog modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <i className="fe fe-shield text-success"/> Verification
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body bg-light">
              <div className="card mt-4">
                <div className="card-header">
                  <h4 className="mb-0">Metadata</h4>
                </div>
                <div className="card-body">
                  <div className="d-grid">
                    <a className="btn btn-light btn-lg col-12" href={getEtherScanUrl(data)} target="blank_">
                      View on {data.blockchain==="polygon"?"polygonscan":data.blockchain==="ethereum"?"etherscan":"scan on explorer"}
                    </a>
                    <a className="btn btn-light btn-lg col-12 my-3" href={getOpenseaUrl(data)} target="blank_">
                      View on opensea
                    </a>
                  </div>
                  <ul className="d-none">
                    <li><span className="fs-4 fw-bold">Contract:</span> {data.contract_address}</li>
                    <li><span className="fs-4 fw-bold">Token:</span> {data.token_id}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between align-items-center">
              <button
              className="btn btn-light me-3"
              data-bs-target="#showNFTMetadata"
              data-bs-toggle="modal"
              data-bs-dismiss="modal"
              >
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Buy;
//@ NFTmarket.africa 2022
