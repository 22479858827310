import React, {
  useEffect,
  useState
} from 'react';
import {
  useParams
} from 'react-router-dom';
import avatar from '../assets/images/avatar/user.png';
import opensea from '../assets/images/brand/opensea.png';
import rarible from '../assets/images/brand/rarible.svg';
import instagram from '../assets/images/brand/instagram-2.png';
import twitter from '../assets/images/brand/twitter.png';
import foundation from '../assets/images/brand/foundation.jpeg';
import checkmark from '../assets/images/tools/checkmark.svg';
import userApi from '../service/api/user_api';
import {LandingWrapper} from './wrapper';
import {Post} from '../component';
import LoadingPostCard from '../component/ui_kits/loading_card';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
Notify.init({position:'left-bottom'})
const api = new userApi();

const ProfileAccountView =(props)=> {
  let {account} = useParams()
  let [error, setError] = useState()
  let [data, setData] = useState({
    profile:'',
    posts:[],
    metrics:{},
    loading:false
  })
  useEffect(()=>{
    setData( prevState => {return {...prevState, loading:true}})
    api.retrieveProfile(account).then((response)=>{
      let {username} = response.data;
      if(username){
        api.getProfileByUsername(username).then((response)=>{
          setData( prevState => {return {...prevState, loading:false, ...response.data}})
        }).catch((err)=>{
          setData( prevState => {return {...prevState, loading:false}})
          if(!err.response || !err.response.data){
            Notify.failure('Network error');
            setError('Network Error')
          }else{
            Notify.failure(err.response.data.error);
            setError(err.response.data.error)
          }
        })
      }else{
        setData( prevState => {return {...prevState, loading:false}})
        setError('Complete your profile')
      }
    }).catch((err)=>{
      setData( prevState => {return {...prevState, loading:false}})
      if(!err.response || !err.response.data){
        Notify.failure('Network error');
        setError('Network Error')
      }else{
        Notify.failure(err.response.data.error);
        setError(err.response.data.error)
      }
    })
  },[account])

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }
  return (
    <LandingWrapper
    showNavigation={true}
    metadata={{
      title:data.profile.username,
      meta_title:data.profile.username,
      description:data.profile.about_me,
      social_image:data.profile.avatar,
      url:`${window.location.origin}/@/${data.profile.username}`
    }}>
      <div
        className="py-15 bg-dark position-relative"
        style={{
          backgroundImage: `url(${data.profile.banner || ""})`,
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
      </div>

      <div className="card p-lg-2 pt-2 pt-lg-0 rounded-0 border-0">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-8 col-md-8 col-12">
              <div className="d-flex align-items-center">
                <div className="position-relative mt-n9">
                  <img
                    src={data.profile.avatar|| avatar}
                    alt="avatar"
                    className="rounded-circle avatar-xxl border-white border border-4 position-relative bg-white"
                  />
                  {
                    data.profile &&
                    data.profile.profile_verified?
                    <span
                      className="position-absolute top-0 end-0 me-2"
                      data-bs-toggle="tooltip"
                      data-placement="top"
                      title="Verified"
                    >
                      <img
                        src={checkmark}
                        alt="checkmark"
                        height={30}
                        width={30}
                      />
                    </span>
                    :
                    null
                  }
                </div>
                  <div className="ms-3">
                    <div className="d-flex align-items-center">
                      <h3 className="mb-0 fw-bold me-2">
                      {
                        !error&&data.profile.username?
                        `@${data.profile.username}`:
                        "@---"
                      }
                      </h3>
                    </div>
                    <span className={`fs-6 ${error?'text-danger':''}`}>
                      {
                        !error&&data.profile.account?
                        <span className="clickable">
                          {sliceCharacter(data.profile.account)}
                          {" "}<i className="fe fe-copy"/>
                        </span>
                        :
                        <span className="clickable">
                          {error||"0x---"}
                        </span>
                      }
                    </span>
                  </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12">
              <div className="fs-3 mt-4 mt-lg-0 pb-2 pb-lg-0 d-lg-flex justify-content-end">
              {
                data.profile.instagram_profile?
                <a href={`${data.profile.instagram_profile}`} target="blank" className="text-muted me-4"><img alt="instagram icon" src={instagram} className="pb-1" width={20}/></a>
                :
                null
              }
              {
                data.profile.twitter_profile?
                <a href={`${data.profile.twitter_profile}`} target="blank" className="text-muted me-4"><img alt="twitter icon" src={twitter} className="pb-1" width={20}/></a>
                :
                null
              }
              {
                data.profile.opensea_profile?
                <a href={`${data.profile.opensea_profile}`} target="blank" className="text-muted me-4"><img alt="opensea icon" src={opensea} className="pb-1" width={20}/></a>
                :
                null
              }
              {
                data.profile.rarible_profile?
                <a href={`${data.profile.rarible_profile}`} target="blank" className="text-muted me-4"><img alt="rarible icon" src={rarible} className="pb-1" width={20}/></a>
                :
                null
              }
              {
                data.profile.foundation_profile?
                <a href={`${data.profile.foundation_profile}`} target="blank" className="text-muted me-4"><img alt="foundaton icon" src={foundation} className="pb-1" width={20}/></a>
                :
                null
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 py-md-5">
    		<div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-5 py-3">
              {
                data.profile.about_me?
                <div className="card border-0 mb-4">
                  {/* Card body */}
                  <div className="card-body">
                    <h1 className="h4">About me</h1>
                    <p>{data.profile.about_me}</p>
                  </div>
                </div>
                :
                null
              }
              <div className="card border-0 mb-4 mb-lg-0">
                {/* Card body */}
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
                    <div>
                      <h4 className="mb-0 fw-bold">{data.metrics.total_posts || '0'}</h4>
                      <p className="fs-6 mb-0">Items</p>
                    </div>
                    <div>
                      <span>
                        <i className="fe fe-plus fs-3" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between border-bottom pb-3 mb-3">
                    <div>
                      <h4 className="mb-0 fw-bold">{data.metrics.total_sales || '0'}</h4>
                      <p className="fs-6 mb-0">Sales</p>
                    </div>
                    <div>
                      <span>
                        <i className="fe fe-shopping-cart fs-3" />
                      </span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <h4 className="mb-0 fw-bold">{data.metrics.total_likes || '0'}</h4>
                      <p className="fs-6 mb-0">Likes</p>
                    </div>
                    <div>
                      <span>
                        <i className="fe fe-heart fs-3" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    			<div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-6 py-6">
              <div className="row justify-content-center align-items-center">
                {
                  data.loading?
                  <div className="col-12 col-md-7 col-lg-10">
                    <LoadingPostCard/>
                  </div>
                  :
                  (data.posts && data.posts.length)?
                  data.posts.map((item, i)=>
                    <div key={i} className="col-12 col-md-7 col-lg-10 pb-12">
                      <Post
                      showFooterDesc={true}
                      data={item}
                      resolution={{height:400}}
                      />
                    </div>
                  )
                  :
                  <div className="col-12 col-md-7 col-lg-10">
                    <div className="text-center">
                      <p className="py-6 h4">{error || "No item in store"}</p>
                    </div>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default ProfileAccountView;
//@ NFTmarket.africa 2022
