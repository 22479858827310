import React, {useState} from 'react';
// import {NavLink} from 'react-router-dom';
// import {useDispatch} from 'react-redux';
import logo2 from '../../assets/images/logo/nftmarketafrica_icon.png';
// import {connectAccount, getToken} from '../../features/auth/authSlice';

let StandardNavBar = (props)=> {
  // let dispatch = useDispatch()
  let [search, setSearch] = useState("nftmarketafrica")
  let handleInput =(e)=> {
    let {value} = e.target;
    if(value){
      setSearch(value);
    }
  }
  return (
    <nav className="navbar navbar-expand-lg navbar-default fixed-top shadow-none py-3" style={{zIndex:2}}>
      <div className="container px-0">
        <a className="navbar-brand" href="https://nftmarket.africa">
          <img src={logo2} width={50} alt="logo"/>
        </a>
        <ul className="navbar-nav ms-auto d-lg-none d-flex nav-top-wrap">
          <li className="nav-item docs-header-btn d-flex justify-content-between">
            <a
              href="/signin"
              className="btn brand-button btn-primary border border-2 btn-dashed ms-3"
            >
              Sign in
            </a>
          </li>
        </ul>
        {/* Button */}
        <button
          className="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbar-default"
          aria-controls="navbar-default"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="icon-bar top-bar mt-0" />
          <span className="icon-bar middle-bar" />
          <span className="icon-bar bottom-bar" />
        </button>
        {/* Collapse */}
        <div className="collapse navbar-collapse offset-lg-1" id="navbar-default">
          <ul className="navbar-nav d-lg-none">
            <form action={`/@/${search}`} method="GET" className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
              <span className="position-absolute ps-3 search-icon">
                <i className="fe fe-search" />
              </span>
              <input
                type="search"
                className="form-control form-control-lg rounded-pill ps-6"
                placeholder="Search creators"
                onChange={handleInput}
              />
            </form>
          </ul>
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link fs-4"
                href="https://nftmarket.africa/shop"
              >
                Shop
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link fs-4"
                href="https://nftmarket.africa/utility"
              >
                Utility
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link fs-4"
                href="/faq"
              >
                FAQ
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link fs-4"
                href="https://blog.nftmarket.africa"
                target="blank_"
              >
                Blog
              </a>
            </li>
          </ul>
          <ul className="navbar-nav ms-auto d-none d-lg-block">
            <li className="nav-item docs-header-btn d-flex justify-content-between">
              <div className="d-flex">
                <form action={`/@/${search}`} method="GET" className="mt-3 mt-lg-0 ms-lg-3 d-flex align-items-center">
                  <span className="position-absolute ps-3 search-icon">
                    <i className="fe fe-search" />
                  </span>
                  <input
                    type="search"
                    className="form-control form-control-lg rounded-pill ps-6"
                    placeholder="Search creators"
                    onChange={handleInput}
                  />
                </form>
                <a
                  href="/signin"
                  className="btn brand-button btn-primary border border-2 btn-dashed ms-3"
                >
                  Sign in
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}

export default StandardNavBar;
//@nftmarket.africa 2022
