import React from 'react';
import {useDispatch} from 'react-redux';
import {getToken} from '../features/auth/authSlice';
import {
  LandingWrapper
} from './wrapper';

let metadata = {
  title:"NFT Market Africa - Connect your wallet",
  meta_title:"NFT Market Africa - Connect your wallet",
  description:"Connect your wallet to sign in"
}

const Signin =(props)=> {
  let dispatch = useDispatch();
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div
        className="py-15 position-relative text-center vh-75 bg-white"
        style={{
          background: "url('') no-repeat",
          backgroundPosition: "center"
        }}
      >
      <div className="container py-9">
        <div className="row justify-content-center align-items-top no-gutters">
          <div className="col-12 col-lg-5">
            <div className="d-grid gap-2 col-12 mx-auto">
              <button onClick={() => dispatch(getToken())} className="d-flex brand-button justify-content-between align-items-center btn btn-light btn-lg border-0" type="button">
                <span>Connect Wallet</span>
                <i className="fas fa-wifi"/>
              </button>
              {/*
              <button className="d-flex justify-content-between align-items-center btn btn-light btn-lg border-0 my-3">
                <span>Create Wallet</span>
                <i className="fas fa-plus"/>
              </button>
              */}
            </div>
          </div>
        </div>
      </div>
      </div>
    </LandingWrapper>
  )
}
export default Signin;
//@ NFTmarket.africa 2022
