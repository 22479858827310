import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, Link} from 'react-router-dom';
import {retrieveOrder} from '../features/checkout/checkoutSlice';
import {
  LandingWrapper
} from './wrapper';
import {
  Post
} from '../component';
import LoadingPostCard from '../component/ui_kits/loading_card';
import {currencyFormat} from '../features/tools';
let metadata = {
  title:"NFT Market Africa - Sale",
  meta_title:"NFT Market Africa - Sale",
  description:"Complete a sale"
}

const SaleView =(props)=> {
  let navigate = useNavigate()
  let dispatch = useDispatch()
  let {order_id} = useParams()
  let user = useSelector(state => state.user.value)
  let {retrieving, retrieved_order} = useSelector(state => state.checkout.value)
  let {post, order} = retrieved_order;

  useEffect(()=>{
    dispatch(retrieveOrder(order_id))
  },[dispatch, order_id])

  useEffect(()=>{
    if(user && user.profile && !user.profile.profile_verified){
      navigate('/orders')
    }
  },[user, navigate])

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-10 pt-15 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="d-lg-flex align-items-center justify-content-between">
                {/* Content */}
                <div className="mb-4 mb-lg-0">
                  <h1 className="text-white mb-1 display-4">Sale</h1>
                  <p className="mb-0 text-white lead">
                    Complete a sale
                  </p>
                </div>
                <div className="d-flex">
                  <Link to="/sales" className="btn btn-white btn-sm">
                    Sales
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container py-6">
        <div className="row justify-content-between">
          <div className="col-lg-5 col-md-6 col-12 py-6">
            {
              retrieving?
              <LoadingPostCard/>
              :
              (!retrieving&&post)?
              <Post data={post} noFooter={true} noOptions={true} resolution={{height:400}} layout="transfer"/>
              :
              null
            }
          </div>

          <div className="col-lg-6 col-md-6 col-12 py-6">
            <div className="card">
              <div className="card-body">
                  {
                    order?
                  <div className="card border">
                    <div className="card-header">
                    {
                      (order.is_complete&&order.status==="complete")?
                      <h3 className="mb-0"><i className="fas fa-check-circle text-success"/> Your sale is complete!</h3>
                      :
                      <h3 className="mb-0"><i className="fas fa-check-circle text-success"/> You got a sale!</h3>
                    }
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Item</h4>
                        <h4>{(post.alchemy_data&&post.alchemy_data.metadata)?JSON.parse(post.alchemy_data.metadata).name:''}</h4>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Amount</h4>
                        <h4>{currencyFormat(order.item_amount,'USD')}</h4>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Utility</h4>
                        <h4>{currencyFormat(order.utility_amount,'USD')}</h4>
                      </div>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Collector</h4>
                        <h4><a href={`/account/${order.collector}`} target="blank_">View Profile</a></h4>
                      </div>
                      <hr/>
                      <div className="d-flex justify-content-between align-items-center">
                        <h4>Available Credit</h4>
                        <h2 className='text-success'>{currencyFormat(order.item_amount,'USD')}</h2>
                      </div>
                    </div>
                    <div className="card-footer border-0">
                    <div>
                    </div>
                      <div className="d-grid gap-2">
                      {
                        (order.is_complete&&order.status==="complete")?
                        <button type="button" className="btn btn-success">
                          Sale is Complete
                        </button>
                        :
                        <a href={`https://nftmerchant.app/sign-in/?sale=${order_id}`} target="blank_" className="btn btn-primary">
                          Accept Sale
                        </a>
                      }
                      </div>
                    </div>
                  </div>
                    :
                    null
                  }
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandingWrapper>
  )
}
export default SaleView;
//@ NFTmarket.africa 2022
