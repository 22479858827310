import React from 'react';
import {
  LandingWrapper
} from './wrapper';

const Privacy =(props)=> {
  let metadata = {
    title:"NFT Market Africa | Privacy Policy",
    meta_title:"Take a moment to read our Privacy Policy",
    description:"Take a moment to read our Privacy Policy"
  }
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-10 bg-primary mt-9 border-bottom border-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div>
                <h1 className="mb-1 display-4 text-white">Privacy Policy</h1>
                <p className="mb-0 lead text-muted">
                  Read our Privacy Policy.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <div className="container">
          <div className="row">
            <div className="offset-md-2 col-md-8 col-12">
              <div className="mb-8">
                <h1 className="display-4 fw-semi-bold">Privacy Policy</h1>
                <div className="mb-3">
                  <p>This Notice explains how we obtain, use and disclose your personal
                  information, in accordance with the requirements of the Protection of
                  Personal Information Act (“POPIA”).At COR Concepts (and including this
                  website, POPIAct-Compliance) we are committed to protecting your privacy
                  and to ensure that your personal information is collected and used properly,
                  lawfully and transparently.
                  </p>
                  <h3>About the Company</h3>
                  <p>NFT Market Africa</p>
                  <h3>The information we collect</h3>
                  <p>
                  We collect and process your personal information mainly to contact you
                  for the purposes of understanding your requirements, and delivering
                  services accordingly.  For this purpose we will collect contact details
                  including your name and organisation.
                  We collect information directly from you where you provide us with
                  your personal details.  Where possible, we will inform you what
                  information you are required to provide to us and what information
                  is optional.
                  Website usage information may be collected using “cookies” which
                  allows us to collect standard internet visitor usage information.
                  </p>
                  <h3>How we will use your information</h3>
                  <p>
                  We will use your personal information only for the purposes
                  for which it was collected and agreed with you.  In addition,
                  where necessary your information may be retained for legal or
                  research purposes.
                  </p>
                  <p>For example</p>
                  <ul>
                    <li>To gather contact information;</li>
                    <li>To confirm and verify your identity or to verify that you are an authorised user for security purposes.</li>
                    <li>For the detection and prevention of fraud, crime, money laundering or other malpractice.</li>
                    <li>To conduct market or customer satisfaction research or for statistical analysis;</li>
                    <li>For audit and record keeping purposes.</li>
                    <li>In connection with legal proceedings.</li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h3 className="">Disclosure of information</h3>
                  <p>We may disclose your personal information to our service providers
                  who are involved in the delivery of products or services to you.
                  We have agreements in place to ensure that they comply with the privacy
                  requirements as required by the Protection of Personal Information Act.
                  </p>
                  <p>We may also disclose your information</p>
                  <ul>
                    <li>Where we have a duty or a right to disclose in terms of law or industry codes.</li>
                    <li>Where we believe it is necessary to protect our rights.</li>
                  </ul>
                </div>
                <div className="mb-3">
                  <h3>Information Security</h3>
                  <p>We are legally obliged to provide adequate protection for the personal
                  information we hold and to stop unauthorized access and use of personal
                  information. We will, on an on-going basis, continue to review our security
                  controls and related processes to ensure that your personal information remains secure.</p>
                  <p>Our security policies and procedures cover</p>
                  <ul>
                    <li>Physical security;</li>
                    <li>Computer and network security;</li>
                    <li>Access to personal information
                    </li>
                    <li>Secure communications</li>
                    <li>Security in contracting out activities or functions.</li>
                    <li>Retention and disposal of information</li>
                    <li>Acceptable usage of personal information;</li>
                    <li>Governance and regulatory issues</li>
                    <li>Monitoring access and usage of private information</li>
                  </ul>
                  <p>
                  When we contract with third parties, we impose appropriate security,
                  privacy and confidentiality obligations on them to ensure that personal
                  information that we remain responsible for, is kept secure.
                  </p>
                </div>
                <div>
                  <h3>Your Rights: Access to information</h3>
                  <p>You have the right to request a copy of the personal information
                  we hold about you. To do this, simply contact us at the numbers/addresses
                  as provided on our website and specify what information you require.
                  We will need a copy of your ID document to confirm your identity
                  before providing details of your personal information.</p>
                  <p>Please note that any such access request may be subject to a payment
                  of a legally allowable fee
                  </p>
                  <h3>Correction of your information</h3>
                  <p>You have the right to ask us to update, correct or delete
                  your personal information. We will require a copy of your
                  ID document to confirm your identity before making changes to
                  personal information we may hold about you. We would appreciate
                  it if you would keep your personal information accurate.
                  </p>
                </div>
                <div>
                  <h3>Definition of personal information</h3>
                  <p>According to the Act ‘‘personal information’’
                  means information relating to an identifiable,
                  living, natural person, and where it is applicable,
                  an identifiable, existing juristic person.
                  Further to the POPI Act, COR Concepts also
                  includes the following items as personal information:
                  </p>
                  <ul>
                    <li>All addresses including residential, postal and email addresses.</li>
                    <li>Change of name – for which we require copies of the marriage certificate or official change of name document issued by the state department</li>
                  </ul>
                  <h3>How to contact us</h3>
                  <p>
                  If you have any queries about this notice; you need
                  further information about our privacy practices;
                  wish to withdraw consent; exercise preferences or
                  access or correct your personal information,
                  please contact us at the numbers/addresses listed on our website.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Privacy;
//@ NFTmarket.africa 2022
