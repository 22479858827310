import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import flutterwave from '../assets/images/brand/flutterwave.jpeg';
import paypal from '../assets/images/brand/paypal.png';
import {getPost} from '../features/post/postSlice';
import {getUserProfile} from '../features/user/userSlice';
import {createOrder} from '../features/checkout/checkoutSlice';
import {
  LandingWrapper
} from './wrapper';
import PostCardContent from '../component/post_card/card_content';

let metadata = {
  title:"NFT Market Africa - Checkout",
  meta_title:"NFT Market Africa - Checkout",
  description:"Create checkout and make payment"
}

let payment_gateways = [
  {
    name:'Flutterwave',
    id:'flutterwave',
    icon:flutterwave,
    desc:'Pay with card or bank transfer'
  },
  {
    name:'Paypal',
    id:'paypal',
    icon:paypal,
    desc:'Pay with Paypal'
  }
]

const Checkout =(props)=> {
  let dispatch = useDispatch()
  let {post_id} = useParams()

  let user = useSelector(state => state.user.value)
  let post = useSelector(state => state.post.value)
  let checkout = useSelector(state => state.checkout.value)
  let [order, setOrder] = useState({})
  let [gateway, setPaymentGateway] =useState({})

  useEffect(()=>{
    dispatch(getPost(post_id))
    dispatch(getUserProfile())
  },[dispatch, post_id])

  useEffect(()=>{
    if(post.retrieved_post){
      // setOrder({...order,total:post.retrieved_post.amount})
      setOrder((prev) => {
        return {
          ...prev,
          total:post.retrieved_post.amount
        }
      })
    }
  },[post.retrieved_post])

//sums up the total of item and any extras
  let handleTotal =(e)=> {
    let {value, id} = e.target
    let {total} = order
    if(value){
      //check if extra is selected or not
      if(!order[id]){
        return parseInt(total) + parseInt(value)
      }else{
        return parseInt(total) - parseInt(value)
      }
    }
  }

  //add extras options to the order
  let handleExtras =(e)=>{
    let {id} = e.target;
    setOrder({
      ...order,
      [id]:!order[id],
      total:handleTotal(e)
    })
  }

  //selects a Preferred payment gateway
  let handleSelectPaymentGateway =(index)=>{
    setPaymentGateway({...payment_gateways[index]})
  }

  let handleCreateOrder =(e)=> {
    let details = {
      post_id,
      ...order,
      payment_gateway:gateway['id']
    }
    dispatch(createOrder(details));
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-4 py-lg-6 bg-dark mt-9 border-bottom border-white">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div>
                <h1 className="mb-1 display-4 text-white">Checkout</h1>
                <p className="mb-0 lead text-muted">
                  Continue to Payment
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container py-10">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-9 col-md-8 col-12">
            {/* Card */}
            <div className="card mb-4">
              {/* Card body */}
              <div className="card-body d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="fw-bold mb-0">${post.retrieved_post?post.retrieved_post.amount:'---'}</h2>
                  <p className="mb-0">
                   Amount due
                  </p>
                </div>
                <div>
                {
                  post.retrieved_post?
                  <PostCardContent data={post.retrieved_post} resolution={{width:"100px"}}/>
                  :
                  null
                }
                </div>
              </div>
            </div>
            {/* Card */}
            <div className="card mb-4">
              {/* Card header */}
              <div className="card-header">
                <h3 className="mb-0">Payment Method</h3>
                <span>How will you like to pay?</span>
              </div>
              {/* Card body */}
              <div className="card-body">
                {
                  post.retrieved_post?
                  <ul className="list-group list-group-flush overflow-hidden">
                    {/* List group item */}
                    {
                      payment_gateways.map((method, i)=>
                      <li key={i} className="list-group-item px-0 pb-3 py-3">
                        <a
                        href="/#"
                        data-bs-toggle="modal"
                        data-bs-target={"#checkoutModal"}
                        aria-controls={"checkoutModal"}
                        onClick={()=>handleSelectPaymentGateway(i)}
                        >
                          <div className="d-flex justify-content-between">
                            <div className="d-flex">
                              <img
                                src={method.icon}
                                alt=""
                                width={40}
                                height={40}
                                className="me-3 rounded"
                              />
                              <div>
                                <h5 className="mb-0">{method.name}</h5>
                                <p className="mb-0 fs-6">{method.desc}</p>
                              </div>
                            </div>
                            <div>
                              <span className="badge bg-success me-4"></span>
                            </div>
                          </div>
                        </a>
                      </li>
                      )
                    }
                  </ul>
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Modal */}
      <div className="col-auto">
        <div
          className="modal fade"
          id={"checkoutModal"}
          tabIndex={-1}
          role="dialog"
          aria-labelledby="checkoutModalTitle"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={"checkoutModalTitle"}>
                  Order Summary
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body bg-light">
              <div className="card">
                {
                  post.retrieved_post?
                  <PostCardContent data={post.retrieved_post} resolution={{width:'300px', height:'300px'}}/>
                  :
                  null
                }
                </div>

                <div className="card mt-4">
                  {/* Card header */}
                  <div className="card-header">
                    <h3 className="mb-0">Add Extras</h3>
                    <p className="mb-0">
                    </p>
                  </div>
                  {/* Card body */}
                  <div className="card-body">
                    <div>
                      <h4 className="mb-0">NFT iFrame - <span className="text-success">$200</span></h4>
                      {/* List group */}
                      <ul className="list-group list-group-flush overflow-hidden">
                        {/* List group item */}
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Order iFrame M1</div>
                          <div>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="iframe"
                                value={200}
                                onChange={handleExtras}
                              />
                              <label className="form-check-label" htmlFor="swicthOne" />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div>
                      <h4 className="mb-0">NFT iFrame Pro - <span className="text-success">$450</span></h4>
                      {/* List group */}
                      <ul className="list-group list-group-flush overflow-hidden">
                        {/* List group item */}
                        <li className="list-group-item d-flex align-items-center justify-content-between px-0 py-2">
                          <div>Order iFrame M1 Pro</div>
                          <div>
                            <div className="form-check form-switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="iframe_pro"
                                value={450}
                                onChange={handleExtras}
                              />
                              <label className="form-check-label" htmlFor="swicthOne" />
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between align-items-center">
                <button
                className="btn btn-light me-3"
                data-bs-target="#checkoutModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                className="btn btn-primary"
                data-bs-target="#confirmOrderModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                >
                  <i className="fe fe-arrow-right text-success"/> Continue
                </button>
              </div>
            </div>
          </div>
        </div>
        {/* second modal*/}
        <div
          className="modal fade"
          id="confirmOrderModal"
          aria-hidden="true"
          aria-labelledby="exampleModalToggleLabel2"
          tabIndex={-1}
        >
          <div className="modal-dialog modal-dialog-scrollable" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id={"checkoutModalTitle"}>
                  Confirm Order
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body bg-light">
                <div className="card mt-4">
                  {/* Card header */}
                  <div className="card-header">
                    <h3 className="mb-0">Delivery Details</h3>
                  </div>
                  {/* Card body */}
                  <div className="card-body">
                    <div>
                      <ul className="list-group list-group-flush overflow-hidden">
                        <li className="list-group-item px-0 pt-0 pb-4">
                          <h4 className="mb-0">Wallet Address</h4>
                          <div>{user.profile?user.profile.account:'wallet not available'}</div>
                        </li>
                        {
                          (order.iframe||order.iframe_pro)?
                          <li className="list-group-item px-0 py-4">
                            <h4>Mailing Address</h4>
                            {
                              user.profile.mailing_address?
                              <div>{user.profile.mailing_address}</div>
                              :
                              <div className="text-warning">Mailing address not provided</div>
                            }
                          </li>
                          :
                          null
                        }
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="card mt-4">
                  {/* Card header */}
                  <div className="card-header">
                    <h3 className="mb-0">Total Due</h3>
                  </div>
                  {/* Card body */}
                  <div className="card-body d-flex justify-content-between">
                    <div>
                      <h2 className="fw-bold text-success">${order.total||'---'}</h2>
                    </div>
                    <div>
                      <img
                        src={gateway['icon']}
                        alt=""
                        width={40}
                        height={40}
                        className="me-3 rounded"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between align-items-center">
                <button
                className="btn btn-light me-3"
                data-bs-target="#checkoutModal"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
                >
                  Back
                </button>
                {
                  checkout.creating?
                  <button className="btn btn-primary" type="button" disabled>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    {" "}Submitting...
                  </button>
                  :
                  <button
                  className="btn btn-primary"
                  onClick={handleCreateOrder}
                  >
                    <i className="fe fe-check-circle text-success"/> Submit Order
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Checkout;
//@ NFTmarket.africa 2022
