import firebase from 'firebase/compat/app';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, getRedirectResult, signInWithRedirect, signInWithPopup, TwitterAuthProvider } from "firebase/auth";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
const firebaseConfig = {
  apiKey: "AIzaSyAU7ve2itJajbzIaN0H18DkIYqtJ0GOYb4",
  authDomain: "nftmarket-africa.firebaseapp.com",
  projectId: "nftmarket-africa",
  storageBucket: "nftmarket-africa.appspot.com",
  messagingSenderId: "710000852735",
  appId: "1:710000852735:web:d34a07a6ca65e9cec956ca",
  measurementId: "G-P633VQ4NZL"
};
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const auth = getAuth(app);
const baseURL = 'https://nftmarket-africa.uc.r.appspot.com/';
// const baseURL = 'https://nft-market-africa.herokuapp.com';
const host = 'https://nftmarket.africa';
// const baseURL = 'http://localhost:3001/';
export{
  firebase,
  app,
  auth,
  analytics,
  storage,
  ref,
  TwitterAuthProvider,
  getRedirectResult,
  signInWithRedirect,
  signInWithPopup,
  uploadBytesResumable,
  getDownloadURL,
  baseURL,
  host
}
