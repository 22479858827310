import React, {useEffect, useState} from 'react';
// import {ethers} from 'ethers';
// import {web3Modal} from "../features/auth/authSlice";
// import {useNavigate} from 'react-router-dom';
// import {getUserProfile} from '../features/user/userSlice';
import {getGalleries} from '../features/gallery/gallerySlice';
import {useDispatch, useSelector} from 'react-redux';
import {LandingWrapper} from './wrapper';
// import {Amplitude} from '../features/tools';
// import {ProfileHeaderCard} from '../component/ui_kits/headers';
// import LoadingPostCard from '../component/ui_kits/loading_card';
// import GalleryCard from '../component/ui_kits/nft-gallery-card';
// import NFTPortApi from '../service/api/nftport';
import { Network, Alchemy } from 'alchemy-sdk';
import { MoralisProvider } from "react-moralis";
import TransferModal from '../component/ui_kits/web3/transferModal';
// let EventTracker = new Amplitude();
// let NftPort = new NFTPortApi();
const serverUrl = "https://kihn9rzsvhbt.usemoralis.com:2053/server";
const appId = "G4SClSFcqfRO83Khrm9ZFpNG84Rvv5HmGYDHBmZr";

let metadata = {
  title:"Dashboard",
  meta_title:"Dashboard",
  description:"View your Dashboard"
}

const Dashboard =(props)=> {
  let dispatch = useDispatch();
  // let navigate = useNavigate();
  let user = useSelector(state => state.user.value)
  let galleries = useSelector( state => state.gallery.value)
  // let [pageLoaded, setPageLoaded] = useState(false)
  let [loading, setLoading] = useState(false);
  // let [currentGallery, setCurrentGallery] = useState(0)
  let [nfts, setNfts] = useState([])
  let [selectedNft, setSelectedNft] = useState({})
  // let {data, items, loading} = galleries;

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }

  let getNFTs = (data) => {
    setLoading(true)
    let {network, address} = data;
    if(network && address){
      const settings = {
        apiKey:"FWg37YaXHGuIWDp6A-GSY95Twk1PT4kR",
        network: Network[network]
      };
      const alchemy = new Alchemy(settings);
      alchemy.nft.getNftsForOwner(address).then((response)=>{
        let {ownedNfts} = response;
        setNfts(ownedNfts);
        setLoading(false)
      }).catch((err)=>{
        setLoading(false)
        console.error(err);
      })
    }
  }

  useEffect(()=>{
    dispatch(getGalleries())
  },[dispatch, user])

  useEffect(()=>{
    if(user.profile.account){
      getNFTs({
        network:"ETH_MAINNET",
        address:user.profile.account
      })
    }
  },[user])

  let handleToggleGallery =(e)=>{
    let {value} = e.target;
    if(value && value === "user_account_ethereum"){
      return getNFTs({network:"ETH_MAINNET", address:user.profile.account})
    }else if(value && value === "user_account_polygon"){
      return getNFTs({network:"MATIC_MAINNET", address:user.profile.account})
    }
    if(galleries && galleries.data && galleries.data.length){
      let gallery = galleries.data[value];
      let {address, chain} = gallery
      if(chain==="polygon"){
        return getNFTs({network:"MATIC_MAINNET", address:address})
      }else if(chain==="ethereum"){
        return getNFTs({network:"ETH_MAINNET", address:address})
      }else{
        return getNFTs({network:"ETH_MAINNET", address:address})
      }
    }
  }

  // useEffect(()=>{
  //   if(!pageLoaded){
  //     EventTracker.logEvent('ON_DASHBOARD')
  //   }
  // },[pageLoaded, setPageLoaded])
  //
  // useEffect(()=>{
  //   dispatch(getUserProfile())
  //   dispatch(getUserMetrics())
  // },[dispatch])
  //
  // useEffect(()=>{
  //   if(user && user.profile && !user.profile.profile_verified){
  //     navigate('/dashboard')
  //   }
  // },[user, navigate])
  //
  //
  // useEffect(()=>{
  //   if(data && data.length){
  //     dispatch(getGalleryItems(data[currentGallery]['id']))
  //   }
  // },[dispatch, data, currentGallery])
  //
  // let handleToggleGallery =(e)=>{
  //   let {value} = e.target;
  //   setCurrentGallery(value)
  // }

  return (
    <MoralisProvider serverUrl={serverUrl} appId={appId}>
    <LandingWrapper loggedIn={true} showNavigation={true} metadata={metadata}>
      <div className="pt-15 bg-white min-vh-75">
    		<div className="container">
    			<div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10 py-3">
              <div className="card bg-light shadow-none rounded-4">
                <div className="card-body">
                  <div className="py-3">
                    <span className="fs-5 fw-bold">Welcome</span>
                    <h3 className="display-4 py-2">@{user.profile.username || sliceCharacter(user.profile.address?user.profile.address:'.')}</h3>
                    <a href="/discover" className="btn btn-primary brand-button">
                      Buy NFTs
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className='col-12 col-lg-10'>
              <div className="d-flex justify-content-between">
                <h2 className="fs-3 fw-bold p-3 col-6">My NFTs</h2>
                <div className="col-5 col-lg-3">
                  <select className="form-select form-select-md rounded-4" onChange={handleToggleGallery}>
                    <option value={"user_account_ethereum"}>Account (Ethereum)</option>
                    <option value={"user_account_polygon"}>Account (Polygon)</option>
                    {
                      galleries&&galleries.data?
                      galleries.data.map((galry, i)=>
                        <option key={i} value={i}>Gallery ({galry.chain})</option>
                       )
                       :
                       null
                    }
                  </select>
                </div>
              </div>
              <div className="row my-6 justify-content align-items-center">
              {
                nfts && nfts.length && !loading?
                nfts.map((nft, i)=>
                <div key={i} className="col-12 col-md-6 col-lg-6 py-3">
                  <div className="card rounded-3">
                    <div className="card-header border-0">
                      <span className="fw-bold fs-4">{nft.title}</span>
                    </div>
                    <div className="card-body">
                      <img
                      className="rounded rounded-3"
                      src={nft.media && nft.media.length?nft.media[0]['gateway']:''}
                      alt="nft"
                      style={{width:'300px', maxHeight:'300px', objectFit:'cover'}}
                      />
                    </div>
                    <div className='card-footer border-0 py-3'>
                    <button
                      type="button"
                      className="btn btn-light brand-button"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModalCenter"
                      onClick={()=> setSelectedNft(nft)}
                    >
                      Send
                    </button>
                    </div>
                  </div>
                </div>
                )
                :
                loading || user.loading?
                  <div className="col-12 rounded pb-6 vh-75 text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                :
                <div className="col-12 col-md-6 col-lg-6 p-4">
                  <p>No NFTs</p>
                </div>
              }
              </div>
            </div>
          </div>
        </div>
      </div>
      <TransferModal data={selectedNft}/>
    </LandingWrapper>
    </MoralisProvider>
  )
}
export default Dashboard;
//@ NFTmarket.africa 2022
