import React, {useState, useEffect} from 'react';
// import {useNavigate} from 'react-router-dom';
import {LandingWrapper} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();

let metadata = {
  title:"Product Ownership",
  meta_title:"Product Ownership",
  description:"Proof ownership of your item"
}

const Faq =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)
  // let navigate = useNavigate();

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('PRODUCT_SCAN')
      window.location.href = "https://utility.nftmarket.africa"
    }
  },[pageLoaded, setPageLoaded])

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 py-10 py-lg-15 bg-dark">
        <div className="container">
          <div className="row text-center justify-content-center align-items-center py-3">
            <div className="col-12 py-6">
              {/* caption*/}
              <h1 className="fw-bold mb-1 display-4 text-white">Game Boy</h1>
              <p className="mb-0 fs-4 text-white opacity-75">Owned by <a className="text-white" href="https://nftmarket.africa/@/vandalcpt/">VANDALCPT</a></p>
            </div>
            <div className="col-6 d-grid justify-content-center">
              <img
              src={"https://lh3.googleusercontent.com/nL_HdIhZWx7Yo4DyZDYMfLKagHJfccjD6iGcpzhW4covsI3v0JtlGqgf7VVGDKS5R5uLzXDdVqn9mel90tQxneW2gNjOKz6_zC6F"}
              alt="merch setup"
              width={300}
              className="rounded"
              />
              <p className="py-3 text-white opacity-75">Token Details</p>
              <a href="https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/48018183894803920285685917806447364524462887252284732278510759644964509974534" className="btn btn-light brand-button rounded-3 border-2" target="blank_"> Verify on opensea</a>
              <a href="https://polygonscan.com/token/0x2953399124f0cbb46d2cbacd8a89cf0599974963?a=48018183894803920285685917806447364524462887252284732278510759644964509974534" className="btn btn-light my-4 brand-button rounded-3 border-2" target="blank_"> Verify on polygonscan</a>
            </div>
          </div>
          <div className="row text-center justify-content-center align-items-center">
            <div className="col-lg-6">
              <p className="fw-bold">Token ID: 480181...4534</p>
              <button
              type="button"
              className="btn btn-primary brand-button rounded-3 border-2"
              data-bs-toggle="modal"
              data-bs-target="#productsetup"
              >
              Complete Setup
              </button>
            </div>
          </div>
        </div>
      </div>

      <>
      <div
        className="modal fade"
        id="productsetup"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="productsetup"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h5 className="modal-title fs-4 fw-bold" id="exampleModalCenterTitle">
                Card PIN
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <input className='form-control fs-3 rounded-3 border-2 brand-button'/>
              </form>
            </div>
            <div className="modal-footer border-0">
              <button
                type="button"
                className="btn btn-secondary brand-button"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              <button type="button" className="btn btn-primary brand-button">
                Verify
              </button>
            </div>
          </div>
        </div>
      </div>
      </>
    </LandingWrapper>
  )
}
export default Faq;
//@nftmarket.africa 2022
