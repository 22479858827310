import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import { v1 as uuidv1 } from 'uuid';
import authApi from '../../service/api/auth_api';
import web3 from 'web3';
import Web3Modal from "web3modal";
import {ethers} from 'ethers';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import WalletConnect from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import {getUserProfile} from '../user/userSlice';

Notify.init({position:'left-bottom'})
let api = new authApi();

export const providerOptions = {
  walletlink: {
    package: CoinbaseWalletSDK,
    options: {
      appName: "NFT Market Africa",
      infuraId: '1dee6c111ad948c5854b6458cd44cd8d'
    }
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId: '1dee6c111ad948c5854b6458cd44cd8d'
    }
  }
};

export const web3Modal = new Web3Modal({
  cacheProvider: true,
  providerOptions
});


let createNonce =(message)=> {
  const v1options = {msecs: new Date().getTime()}
  let nonce = uuidv1(v1options)
  let hash = web3.utils.sha3(nonce)
  return web3.utils.utf8ToHex(`Welcome to NFT Market Africa. \nThis is a one-time signin request. \nPlease authorize to sign into your account for 24hrs. \nYour access code is ${hash}`)
}


export const signMessage = createAsyncThunk(
  'auth/SIGN_MESSAGE',
  async(message, thunkAPI)=> {
    try{
      //hash the message
      message = web3.utils.utf8ToHex(message);
      //connect web3 provider
      const provider = await web3Modal.connect();
      if(provider){
        const library = new ethers.providers.Web3Provider(provider);
        const accounts = await library.provider.request({
          method: "eth_accounts"
        });
        if(accounts.length){
          const signature = await library.provider.request({
            method: "personal_sign",
            params: [message, accounts[0]]
          });
          if(!signature){
            console.error('acct not available');
            throw thunkAPI.rejectWithValue({error:'Account not available'})
          }
          return {
            signature,
            message
          }
        }else{
          console.error('acct not available');
          throw thunkAPI.rejectWithValue({error:'Account not available'})
        }
      }else{
        console.error('provider not available');
        throw thunkAPI.rejectWithValue({error:'Account not available'})
      }
    }catch(err){
      console.error(err);
    }
  }
)


export const connectAccount = createAsyncThunk(
  'auth/CONNECT_ACCOUNT',
  async(arg, thunkAPI)=> {
    try{
      const provider = await web3Modal.connect();
      if(provider){
        // console.log(provider);
        const library = new ethers.providers.Web3Provider(provider);
        const accounts = await library.listAccounts();
        if(accounts[0]){
          // console.log('getting acct');
          thunkAPI.dispatch(getToken())
        }
        provider.on('accountsChanged', function(){
          thunkAPI.dispatch(disconnectAccount())
          console.log('account changed-----REFRESHING');
        })
        provider.on('disconnect', function(){
          thunkAPI.dispatch(disconnectAccount())
          console.log('account disconnected');
        })
      }else{
        console.log('Install Metamask');
      }
    }catch(err){
      console.error(err);
    }
  }
)
export const disconnectAccount = createAsyncThunk(
  'auth/DISCONNECT_ACCOUNT',
  async(arg, thunkAPI)=> {
    try{
      await web3Modal.clearCachedProvider();
      Cookies.remove('account')
      Cookies.remove('c-token')
      localStorage.clear()
      thunkAPI.dispatch(signOut())
      // window.location.reload()
    }catch(err){
      console.error(err);
    }
  }
)

export const getToken = createAsyncThunk(
  'auth/GET_TOKEN',
  async(arg, thunkAPI)=> {
    try{
      const token = Cookies.get('c-token');
      if(!token){
        const provider = await web3Modal.connect();
        if(provider){
          const library = new ethers.providers.Web3Provider(provider);
          const accounts = await library.provider.request({
            method: "eth_accounts"
          });
          if(accounts.length){
            let hash = createNonce()
            const signature = await library.provider.request({
              method: "personal_sign",
              params: [hash, accounts[0]]
            });
            // if(signature) console.log(signature);
            let authData = {signature, hash}
            api.getToken(authData).then((response)=>{
              let {token} = response.data
              Cookies.set('c-token', token)
              Cookies.set('account', accounts[0])
              thunkAPI.dispatch(getUserProfile())
              return token;
            }).catch((err)=>{
              if(!err.response || !err.response.data){
                Notify.failure("Network Error");
                throw thunkAPI.rejectWithValue({error:'Network Error'})
              }else{
                Notify.failure(err.response.data);
                throw thunkAPI.rejectWithValue({error:err.response.data})
              }
            })
          }else{
            console.error('acct not available');
            thunkAPI.dispatch(disconnectAccount())
            throw thunkAPI.rejectWithValue({error:'Account not available'})
          }
        }else{
          console.error('provider not available');
          thunkAPI.dispatch(disconnectAccount())
          throw thunkAPI.rejectWithValue({error:'Provider not available'})
        }
      }else{
        return token;
      }
    }catch(err){
      console.error(err);
      thunkAPI.dispatch(disconnectAccount())
      throw thunkAPI.rejectWithValue({error:err.message})
    }
  }
)
export const authSlice = createSlice({
  name:'auth',
  initialState:{
    value:{
      provider:{},
      loggedIn:false,
      loggingIn:false,
      loading:false,
      account:null,
      userToken:null,
      error:null
    }
  },
  reducers:{
    logIn: (state, action)=>{
      state.value = {
        ...state.value,
        loggedIn:true
      }
    },
    signOut: (state, action)=>{
      state.value = {
        ...state.value,
        loggedIn:false
      }
    },
    setAccount: (state, action)=>{
      state.value = {
        ...state.value,
        account:action.payload,
        loggedIn:true
      }
    },
    setProvider: (state, action)=>{
      state.value = {
        ...state.value,
        provider:action.payload
      }
    }
  },
  extraReducers: (builder)=> {
    builder.addCase(getToken.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading:true
      }
    })
    builder.addCase(getToken.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        userToken:action.payload,
        loggedIn:true,
        loading:false
      }
    })
    builder.addCase(getToken.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loggedIn:false,
        loading:false,
        error:action.payload.error
      }
    })
  }
})
export const {setAccount, setProvider, logIn, signOut} = authSlice.actions;
export default authSlice.reducer;
//@NFTmarket.africa 2022
