import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faShirt } from '@fortawesome/free-solid-svg-icons'

export default function UtiltiyModal(props){
  let {
    utilities
  } = props.data
  return (
    <div
      className="offcanvas offcanvas-bottom rounded-top-3 border-0"
      tabIndex={-1}
      id={props.id}
      aria-labelledby="utilityModalLabel"
      key={props.data.id}
    >

      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="utilityModalLabel">
          Available Utility
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fe fe-x-circle " />
        </button>
      </div>
      <div className="container p-0">
        <div className="offcanvas-body m-0">
          <div className="row">
            <div className="col-12-6 col-12">
              <div className="card mb-4 shadow-none">
                <div className="">
                  <ul className="list-group list-group-flush overflow-hidden fs-4">
                  {
                    utilities?
                    <>
                    {
                      utilities.iframe&&utilities.iframe.include?
                      <li className="d-flex list-group-item bg-transparent align-items-center">
                        <i className="bi bi-back align-middle me-2 text-primary" />
                        {" "}NFT iFrame
                      </li>
                      :
                      null
                    }
                    {
                      utilities.merch&&utilities.merch.include?
                      <li className="d-flex list-group-item bg-transparent align-items-center">
                        <FontAwesomeIcon icon={faShirt} />
                        {" "}&nbsp;T-Shirt Merch
                      </li>
                      :
                      null
                    }
                    {
                      utilities.physical_work&&utilities.physical_work.include?
                      <li className="d-flex list-group-item bg-transparent align-items-center">
                        <i className="bi bi-image align-middle me-2 text-primary" />
                        {" "}Physical Work
                      </li>
                      :
                      null
                    }
                    </>
                    :
                    null
                  }
                  {
                    (!utilities || (!utilities.iframe.include&&!utilities.merch.include&&!utilities.physical_work.include))?
                    <li className="d-flex list-group-item bg-transparent align-items-center">
                      <i className="bi bi-x-circle-fill align-middle me-2 text-primary" />
                      {" "}No Utility
                    </li>
                    :
                    null
                  }
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

//@NFTmarket.africa 2022
