import {
  // auth,
  // app,
  storage,
  ref,
  // getRedirectResult,
  // signInWithRedirect,
  // TwitterAuthProvider,
  uploadBytesResumable,
  // signInWithPopup,
  getDownloadURL
} from '../config';

const uploadToFirebase = (type, file) => {
  return new Promise((resolve, reject)=>{
    //create storage ref
    //create a file reference
    //reference the upload task for upload events
    // const fileMetaData = {contentType: 'image/jpeg'};
    const fileMetaData = {};
    const storageRef = ref(storage, `${type}/${parseInt(Math.random(0)*1000)+file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file, fileMetaData);
    //listen for upload state change
    uploadTask.on('state_changed', function(snapshot){
      // Observe state change events such as progress, pause, and resume
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is ' + progress + '% done');
      switch (snapshot.state) {
        case 'paused':
          console.log('Upload is paused');
          break;
        case 'running': // or 'running'
          console.log('Uploading');
          break;
        default:
      }
    }, function(error){
      reject(error);
    }, function(){
      //success
      getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        resolve(downloadURL);
      });
      // let imagePath = encodeURIComponent(uploadTask.snapshot.ref._location.path)
      // resolve(`https://plusoundcdn.com/${imagePath}`);
    })
  })
}


const twitterSignin = () => {
  // signInWithRedirect(auth, TwitterAuthProvider);
}

export {
  uploadToFirebase,
  twitterSignin
}
//@Plusound.me 2021
