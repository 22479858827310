import React, {useEffect, useState} from 'react';
import checkmark from '../../assets/images/tools/checkmark.svg';
import avatar from '../../assets/images/avatar/user.png';
import userApi from '../../service/api/user_api';
import {Amplitude} from '../../features/tools';
const api = new userApi();
let EventTracker = new Amplitude();

export default function Avatar(props){
  let [profile, setProfile] = useState({})
  useEffect(()=>{
    api.retrieveProfile(props.data.account).then((response)=>{
      setProfile(response.data);
    }).catch((err)=>{
      console.error(err);
    })

    return function cleanUp(){
      setProfile({})
    }
  },[props.data.account])


  return (
    <div className="position-relative border-0 d-flex justify-content-start align-items-center" style={{zIndex:1}}>
      <a href={`/@/${profile.username}`} onClick={()=> EventTracker.logEvent('VIEWED_A_PROFILE')}>
        <img
          src={profile.avatar|| avatar}
          alt="avatar"
          className={`rounded-circle border-light border border-4 ${props.size || 'avatar-md'}`}
          style={props.style}
        />
        {
          profile.profile_verified && !props.noCheckmark?
          <span
            className="position-absolute mt-2 ms-n3"
            data-bs-toggle="tooltip"
            data-placement="top right"
            title="Verified"
          >
            <img
              src={checkmark}
              alt="checkmark"
              height={25}
              width={25}
            />
          </span>
          :
          null
        }
      </a>
      <div>
        <a href={`/@/${profile.username}`}>
          {
            props.showUsername?
            <div className="text-start px-3">
              {
                profile.profile_verified?
                <span className="d-block small opacity-75">Creator</span>
                :
                <span className="d-block small opacity-75">Collector</span>
              }
              <span className="d-block fw-bold position-relative h6">
                @{profile.username}
              </span>
            </div>
            :
            null
          }
          {
            props.showName?
            <div className="text-start px-3">
            {
              props.customTag?
              <span className="d-block small opacity-75">{props.customTag}</span>
              :
              profile.profile_verified?
              <span className="d-block small opacity-75">Creator</span>
              :
              <span className="d-block small opacity-75">Collector</span>
            }
              <span className="d-block position-relative h6 fw-bold">
                {profile.full_name}
              </span>
            </div>
            :
            null
          }
        </a>
      </div>
    </div>
  )
}
//@NFTmarket.africa 2022
