import React from 'react';

export default function PurchaseModal(props){
  let {id} = props.data;
  return (
    <div
      className="offcanvas offcanvas-bottom rounded-top-3 border-0"
      tabIndex={-1}
      id={props.id}
      aria-labelledby="purchaseModalLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="purchaseModalLabel">
            Continue to purchase
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fe fe-x-circle " />
        </button>
      </div>
      <div className="container p-0">
        <div className="offcanvas-body m-0">
          <div className="row justify-content-center align-items-center no-gutters">
            <div className="col-12 col-lg-5">
              <div className="d-grid gap-3 col-12 mx-auto">
                <a href={`/buy/${id}`} className="d-flex justify-content-between align-items-center btn btn-primary border-0 btn-lg">
                  <span>Continue {" "}<i className="bi bi-arrow-right me-2 text-white" /></span>
                  <i className="fe fe-shopping-bag text-success"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
//@NFTmarket.africa 2022
