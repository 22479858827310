import React from 'react';
import {LandingWrapper} from './wrapper';

let metadata = {
  title:"NFT Market Africa - How It Works",
  meta_title:"NFT Market Africa - How It Works",
  description:"Learn how NFT Market Africa works"
}

const HowitWorks =(props)=> {
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 py-10 py-lg-15 bg-primary">
        <div className="container">

          <div className="row">
            <div className="col-md-8 col-12">
              {/* caption*/}
              <h1 className="fw-bold mb-1 display-4 text-white">How it works ✅</h1>
              <p className="mb-0 lead text-muted">Learn how to use NFT Market Africa.</p>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-3">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              <div>
                {/* breadcrumb  */}
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active" aria-current="page">
                      How it works
                    </li>
                    <li className="breadcrumb-item" aria-current="page">
                      <a href="/faq">Faq</a>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <div className="container">
          <div className="row ">
            <div className="col-md-8 col-12">
              <div className="row">
                <div className="col-lg-6 col-12">
                  {/* card  */}
                  <div className="card border mb-4">
                    {/* card body  */}
                    <div className="card-body p-5">
                      <div className="mb-5">
                        <h2 className="fw-semi-bold">Creator</h2>
                        <p>
                          Start selling NFTs easily.
                        </p>
                      </div>
                      {/* list  */}
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a
                            href="/articles/how-to-register"
                            className="text-body"
                          >
                            <i className="mdi mdi-arrow-right me-1 text-muted" />
                            How to sign up
                          </a>
                        </li>
                        <li className="mb-2">
                          <a
                            href="/articles/how-to-sell"
                            className="text-body"
                          >
                            <i className="mdi mdi-arrow-right me-1 text-muted" />
                            How to sell your NFTs
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* card footer  */}
                    <div className="card-footer bg-light bg-opacity-50 border-top-0">
                      <a href="/faq#sell" className="link-primary">
                        Creator FAQ <i className="mdi mdi-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-12">
                  {/* card  */}
                  <div className="card border mb-4">
                    {/* card body  */}
                    <div className="card-body p-5">
                      <div className="mb-5">
                        <h2 className="fw-semi-bold">Collector</h2>
                        <p>
                          Start collecting NFTs easily.
                        </p>
                      </div>
                      {/* list  */}
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a
                            href="/articles/how-to-register"
                            className="text-body"
                          >
                            <i className="mdi mdi-arrow-right me-1 text-muted" />
                            How to sign up
                          </a>
                        </li>
                        <li className="mb-2">
                          <a
                            href="/articles/how-to-buy"
                            className="text-body"
                          >
                            <i className="mdi mdi-arrow-right me-1 text-muted" />
                            How to buy an NFT
                          </a>
                        </li>
                      </ul>
                    </div>
                    {/* card footer  */}
                    <div className="card-footer bg-light bg-opacity-50 border-top-0">
                      <a href="/faq#collect" className="link-primary">
                        Collector FAQ <i className="mdi mdi-arrow-right" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default HowitWorks;
//@nftmarket.africa 2022
