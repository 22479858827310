import React, {useEffect, useState} from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Loading } from 'notiflix/build/notiflix-loading-aio';
import AnimationCard from '../component/ui_kits/animation/animation_card';
import logo from '../assets/images/logo/nftmarketafrica_icon.png';
import {useDispatch} from 'react-redux';
import merch from '../assets/images/tools/merch.png';
import avatar from '../assets/images/avatar/user.png';
import blank from '../assets/images/tools/blank_image.png';
import deliveryBike from "../assets/animations/delivery_bike.json";
import {getPrice, Amplitude} from '../features/tools';
import {utility_prices} from '../store/data';
import {
  LandingWrapper
} from './wrapper';
import {CategoryTab} from '../component/ui_kits/tabs';
import PostCardContent from '../component/post_card/card_content';
import Avatar from '../component/ui_kits/avatar';
import DataApi from '../service/api/data_api';
// import creatorPoster from '../assets/images/tools/creatorposter.png';
let api = new DataApi();
let EventTracker = new Amplitude();

const Welcome =(props)=> {
  let dispatch = useDispatch();
  let [data, setData] = useState({})
  let [error, setError] = useState(null)
  let [loading, setLoading] = useState(false)
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_HOMEPAGE')
    }
  },[pageLoaded, setPageLoaded])
  useEffect(()=>{
    Loading.custom({
      customSvgUrl:logo,
      backgroundColor:"#fff"
    });
    setLoading(true)
    api.getData().then((response)=>{
      setLoading(false)
      setError(null)
      setData(response.data);
      Loading.remove();
    }).catch((err)=>{
      setLoading(false)
      if(err.response && err.response.data){
        setError(err.response.data)
      }else{
        setError('Network Error')
      }
    })
  },[dispatch])

  let createArrayFromNumber =(number)=> {
    let arr = [];
    number = parseInt(number)
    for (var i = 0; i < number; i++) {
      arr.push('1')
    }
    return arr;
  }
  let metadata = {
    title:"Create & Collect NFTs with Utility - NFT Market Africa",
    meta_title:"Create & Collect NFTs with Utility - NFT Market Africa",
    description:"We help Creators provide Utility to Collectors"
  }
  return (
    <LandingWrapper showNavigation={true} showAcceptedPayments={true} metadata={metadata}>
      <div className="py-15 bg-white bg-cover">
        <div className="container">
          <div className="row justify-content-between align-items-center py-3">
            <div className="col-lg-6 col-12">
              <div className="py-6 pl-3">
                <div className="text-center text-md-start px-2">
                  {/* heading */}
                  <h1 className="display-2 fw-bold mb-3">
                    Buy NFTs & Digital Collectibles
                  </h1>
                  {/* lead */}
                  <p className="lead">
                    Experience NFTs in the physical form
                  </p>
                  <a href="/discover" className="btn btn-primary">Shop Now</a>
                </div>
              </div>
            </div>
            <div className="offset-lg-1 col-lg-5 col-12 text-center">
              <Splide
              options={{
                rewind:true,
                arrows:false,
                pagination:false,
                perMove:1,
                easing:'ease',
                interval:5000,
                lazyload:'nearby',
                type:'slide',
                autoplay:true,
                perPage:1,
                width:"100%",
                gap:'1rem'
              }}
              >
                {
                  !loading&&data&&data.sponsored&&data.sponsored.length?
                  data.sponsored.slice(0,2).map((item, i)=>
                  <SplideSlide key={i} className="card">
                    <PostCardContent data={item}/>
                    <div className="card-img-overlay"></div>
                    <div style={{zIndex:1}} className="card-footer py-6 px-1 d-flex justify-content-between align-items-top border-0">
                      <h5 className="card-title fs-3 fw-bold">
                        {item.alchemy_data&&item.alchemy_data.metadata? JSON.parse(item.alchemy_data.metadata).name : "..."}
                      </h5>
                      <div className="text-end">
                        <h5 className="card-title fs-3 fw-bold">
                          {getPrice(utility_prices, (item.utilities||{}), item.amount)}
                        </h5>
                      </div>
                    </div>
                    <div className="card-footer px-1" style={{zIndex:1}}>
                      <div className="d-flex justify-content-between align-items-center">
                        <Avatar size="avatar-lg" showName={true} data={{account:item.account}}/>
                        {
                          item.is_sold?
                          <button className="btn btn-success"><i className="fe fe-check-circle text-white"/> Item Sold</button>
                          :
                          <a href={`/p/${item.id}`} className="fw-bold" onClick={()=> EventTracker.logEvent('VIEWED_HOMEPAGE_ITEM')}>
                              View Item
                          </a>
                        }
                      </div>
                    </div>
                  </SplideSlide>
                  )
                  :
                  <div className="card p-3">
                    <img
                      src={blank}
                      className="card-img-top"
                      alt="..."
                    />
                    <div className="card-img-overlay"></div>
                    <div style={{zIndex:1}} className="card-body d-flex justify-content-between shadow-none border-0">
                      <h5 className="card-title fs-3 fw-bold">{!loading ? error : '...'}</h5>
                      <h5 className="card-title fs-3 fw-bold">$0</h5>
                    </div>
                    <div className="card-footer px-0" style={{zIndex:1}}>
                      <div className="d-flex justify-content-between">
                        <img className="avatar avatar-lg" src={avatar} alt="avatar" />
                        <button type="button" className="btn btn-primary" disabled>
                            View Item
                        </button>
                      </div>
                    </div>
                  </div>
                }
              </Splide>
            </div>
          </div>
        </div>
      </div>


      <div className="py-10 bg-white">
        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-right-md-6 col-12 mb-10">
              {/* heading */}
              <h2 className="display-4 mb-3 fw-bold">Discover Creators</h2>
              {/* lead */}
              <p className="lead mb-5">
                Empowering the Next Generation of Creators
              </p>
            </div>
          </div>
          <div className="row justify-content-between align-items-center rounded rounded-3">
          {
            data.top_creators && data.top_creators.length?
                <Splide
                  options={{
                    rewind:true,
                    arrows:false,
                    direction:'ltr',
                    pagination:false,
                    perMove:1,
                    easing:'ease',
                    interval:3000,
                    lazyload:'nearby',
                    type:'slide',
                    autoplay:true,
                    perPage:4,
                    width:"100%",
                    gap:'1rem'
                  }}
                >
                {
                  data.top_creators.map((creator, i)=>
                    <SplideSlide key={i}>
                      <span className="avatar avatar-xl">
                        <Avatar data={{account: creator.account}} style={{height:80, width:80}}/>
                      </span>
                    </SplideSlide>
                  )
                }
                </Splide>
            :
            null
          }
          {/*
            data.top_creators&&data.top_creators.length?
            data.top_creators.map((creator, i)=>
            <div key={i} className="col-md-2 col-3">
              <div className="p-xl-5 p-lg-3 mb-3 mb-lg-0">
                <Avatar size="avatar-xl" data={{account:creator.account}}/>
              </div>
            </div>
            )
            :
            null

            <div className="col-xl-6 col-lg-7 col-md-12 col-12 order-1 text-center text-lg-start">
              <div className="d-flex justify-content-start align-items-center py-6">
              {
                data.top_creators && data.top_creators.length?
                <div className="card mb-4 mb-lg-0 shadow-none">
                  <div className="card-body">
                    <Splide
                      options={{
                        rewind:true,
                        arrows:false,
                        direction:'ltr',
                        pagination:false,
                        perMove:1,
                        easing:'ease',
                        interval:3000,
                        lazyload:'nearby',
                        type:'slide',
                        autoplay:true,
                        perPage:4,
                        width:"100%",
                        gap:'1rem'
                      }}
                    >
                    {
                      data.top_creators.map((creator, i)=>
                        <SplideSlide key={i}>
                          <span className="avatar avatar-lg">
                            <Avatar data={{account: creator.account}}/>
                          </span>
                        </SplideSlide>
                      )
                    }
                    </Splide>
                  </div>
                </div>
                :
                null
              }
              </div>
              <a href="/discover" className="fw-bold py-10">
                Discover Creators {" "}<i className="bi bi-arrow-right-circle-fill me-2 text-primary" />
              </a>
            </div>
            <div className="offset-xl-1 col-xl-5 col-lg-5 col-12 mb-6 mb-lg-0 order-lg-2 text-center ">
              <img
                src={creatorPoster}
                alt="creator poster"
                className="img-fluid"
              />
            </div>
            */}
          </div>
        </div>
      </div>

      <CategoryTab
      title="Featured"
      categories={[
        {
          title:"🔥 All Category",
          id:"all-categories",
          items:data.featured
        },
        {
          title:"🎨 Artworks",
          id:"artwork",
          items:data.featured
        },
        {
          title:"📷 Photography",
          id:"photography",
          items:data.featured
        },
        {
          title:"🖼 Cover Art",
          id:"cover-art",
          items:data.featured
        }
      ]}
      />
      {/*
      <CategoryTab
      title="Trending"
      categories={[
        {
          title:"🔥 All Category",
          id:"all-categories",
          items:data.trending
        },
        {
          title:"🎨 Artworks",
          id:"artwork",
          items:data.trending
        },
        {
          title:"📷 Photography",
          id:"photography",
          items:data.trending
        },
        {
          title:"🖼 Cover Art",
          id:"cover-art",
          items:data.trending
        }
      ]}
      />
      */}
      <div className="py-lg-16 bg-white pt-8 pb-10">
        <div className="container bg-light p-6 rounded-3">
          <div className="row">
            <div className="col-md-12 col-12">
              <div className="row text-center">
                <div className="col-md-12 px-md-16 mb-8 mt-6">
                  <span className="text-uppercase text-success fw-semi-bold ls-md">
                    Experience it
                  </span>
                  <h2 className="mb-2 display-4 fw-bold">
                    Receive Physical Items
                  </h2>
                  <p className="lead">
                    When you purchase an NFT from our marketplace,
                    you will also receive physical items to showcase your assets.
                  </p>
                </div>
              </div>
              <div className="row justify-content-between align-items-center">
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="position-relative">
                    <AnimationCard animation={deliveryBike}/>
                    <div className="card-img-overlay"></div>
                  </div>
                  <ul
                    className="list-unstyled fs-3 text-dark
                   mb-6 fw-medium"
                  >
                    <li className="mb-1 d-flex">
                      <i
                        className="mdi mdi-check-circle text-success
                      me-2"
                      />
                      Receive Physical Items
                    </li>
                    <li className="mb-1 d-flex">
                      <i
                        className="mdi mdi-check-circle text-success
                      me-2"
                      />
                      Quick Item Delivery
                    </li>
                    <li className="mb-1 d-flex">
                      <i
                        className="mdi mdi-check-circle text-success
                      me-2"
                      />
                      Customer Support
                    </li>
                  </ul>
                  <a href="/utility" className="mt-lg-2 mt-4 fs-4 fw-bold">
                    Learn more{" "}<i className="bi bi-arrow-right-circle-fill me-2 text-primary" />
                  </a>
                </div>
                <div className="col-lg-5 col-md-12 col-12 py-6 py-lg-0">
                    <img
                      src={merch}
                      width={450}
                      alt="litepaper"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10 py-lg-12 bg-white">
        <div className="container">
          <div className="row mb-8 justify-content-center">
            <div className="col-lg-8 col-md-12 col-12 text-center">
              {/* caption */}
              <span className="text-primary mb-3 d-block text-uppercase fw-semi-bold ls-xl">
                Reviews
              </span>
              <h2 className="mb-2 display-4 fw-bold">
                What People are saying
              </h2>
              <p className="lead">Take a look at what people have to say about us</p>
            </div>
          </div>
          <div className="row mb-8">
            <div className="col-md-12">
              <div className="position-relative">
                <Splide
                  options={{
                    rewind:true,
                    arrows:false,
                    pagination:false,
                    perMove:1,
                    easing:'ease',
                    interval:3000,
                    lazyload:'nearby',
                    type:'slide',
                    autoplay:true,
                    perPage:3,
                    width:"100%",
                    gap:'1rem',
                    breakpoints: {
                      640: {
                        perPage: 1
                      }
                    }
                  }}
                >
                {
                  data.reviews&&data.reviews.length?
                  data.reviews.map((review,i)=>
                  <SplideSlide key={i} className="item rounded-3 border border-light border-3">
                    {/* card */}
                    <div className="card border-0 shadow-none h-100">
                      {/* card body */}
                      <div className="card-body p-5">
                        <div className="mb-2">
                          <span className="fs-4">
                          {
                            createArrayFromNumber(review.stars).map((star, i)=>
                            <i key={i} className="mdi mdi-star text-warning me-n1" />
                            )
                          }
                          </span>
                        </div>
                        {/* para */}
                        <p className="lead text-dark font-italic fw-medium mb-0">
                          {review.text}
                        </p>
                      </div>
                      {/* card footer */}
                      <div className="card-footer px-5 py-4">
                        <div className="d-flex align-items-center">
                          <Avatar data={{account:review.user}} showName={true}/>
                        </div>
                      </div>
                    </div>
                  </SplideSlide>
                  )
                  :
                  <div className="col-12 item rounded-3 border border-light border-3 py-6">
                    <div className="card border-0 shadow-none h-100">
                      {/* card body */}
                      <div className="card-body p-5">
                        <div className="mb-2">
                          <span className="fs-4">
                          </span>
                        </div>
                      </div>
                      {/* card footer */}
                      <div className="card-footer px-5 py-4">
                        <div className="d-flex align-items-center">
                          <img src={avatar} alt="avatar" className="avatar avatar-sm"/>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                </Splide>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-14 bg-white">
        <div className="container">
          <div className="row">
            <div className="offset-lg-2 col-lg-8 col-md-12 col-12 text-center">
              <span
                className="fs-4 text-warning ls-md text-uppercase
              fw-semi-bold"
              >
                YES, AMAZING
              </span>
              {/* heading  */}
              <h2 className="display-3 mt-4 mb-3 text-dark fw-bold">
                Ready to See More?
              </h2>
              {/* para  */}
              <p className="lead text-dark-50 px-lg- 8 mb-6">
                Check out the full collection and find the piece that speaks to you!
              </p>
              <a href="/discover" className="btn btn-primary">
                Shop The Collection
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*
      <div className="py-lg-16 bg-light pt-8 pb-10">
        <div className="container">
          <div className="row">
            <div className="offset-xl-1 col-xl-10 col-md-12 col-12">
              <div className="row text-center">
                <div className="col-md-12 px-md-16 mb-8 mt-6">
                  <span className="text-uppercase text-dark fw-semi-bold ls-md">
                    WHY WE ARE DIFFERENT
                  </span>
                  <h2 className="h1 fw-bold mt-3 text-dark mb-2">
                    Our Litepaper
                  </h2>
                  <p className="mb-0 text-dark-50 fs-4">
                    Learn about how we are helping Creators and Collectors connect better.
                  </p>
                </div>
              </div>
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-12 col-12">
                  <div className="mb-8 mb-lg-0 text-dark me-lg-4">
                    <p className="display-6 mb-4 lh-2">
                      "NFT is more than just a JPEG. Our Platform help Creators provide more value to Collectors, thereby leading to genuine connection and more Sales."
                    </p>
                    <p className="mb-0 text-dark">Donjay 2.0</p>
                    <span className="text-dark-50">CEO at NFT Market Africa</span>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-12">
                    <img
                      src={litepaper}
                      width={300}
                      alt="litepaper"
                    />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <a href={litepaperDoc} target="blank_" className="btn btn-outline-dark mt-lg-2 mt-4">
                    Read now
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      */}
    </LandingWrapper>
  )
}
export default Welcome;
//@ NFTmarket.africa 2022
