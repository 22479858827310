import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {getUserMetrics, getUserProfile} from '../features/user/userSlice';
import {useDispatch, useSelector} from 'react-redux';
import {LandingWrapper} from './wrapper';
// import {Post} from '../component';
import {ProfileHeaderCard} from '../component/ui_kits/headers';
// import LoadingPostCard from '../component/ui_kits/loading_card';
import {Amplitude} from '../features/tools';
import Media from '../component/cards/media';
let EventTracker = new Amplitude();


let metadata = {
  title:"NFT Market Africa - Profile",
  meta_title:"NFT Market Africa - Profile",
  description:"View and edit your profile"
}

const Profile =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)
  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_STOREFRONT')
    }
  },[pageLoaded, setPageLoaded])

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let user = useSelector(state => state.user.value)

  useEffect(()=>{
    dispatch(getUserProfile())
    dispatch(getUserMetrics())
  },[dispatch])

  useEffect(()=>{
    if(user && user.profile && !user.profile.profile_verified){
      navigate('/dashboard')
    }
  },[user, navigate])
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
    <ProfileHeaderCard />
      <div className="py-5 py-md-5 bg-white min-vh-75">
    		<div className="container">
    			<div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10 py-6">
              <div className="row justify-content-center align-items-center">
                {
                  user.loading?
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12 text-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </div>
                  </div>
                  :
                  ((user.published ||user.sold || user.drafts))?
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-12">
                      {/* Card */}
                      <div className="card rounded-3 border-0">
                        {/* Card header */}
                        <div className="card-header border-bottom-0 p-0 bg-white">
                          <div>
                            {/* Nav */}
                            <ul className="nav nav-lb-tab" id="tab" role="tablist">
                              <li className="nav-item">
                                <a
                                  className="nav-link active"
                                  id="published-tab"
                                  data-bs-toggle="pill"
                                  href="#published"
                                  role="tab"
                                  aria-controls="published"
                                  aria-selected="true"
                                >
                                  Published
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="pending-tab"
                                  data-bs-toggle="pill"
                                  href="#pending"
                                  role="tab"
                                  aria-controls="pending"
                                  aria-selected="false"
                                >
                                  Sold
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  id="drafts-tab"
                                  data-bs-toggle="pill"
                                  href="#drafts"
                                  role="tab"
                                  aria-controls="drafts"
                                  aria-selected="false"
                                >
                                  Draft
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="py-3">
                          {/* Table */}
                          <div className="tab-content" id="tabContent">
                            {/*Tab pane */}
                            <div
                              className="tab-pane fade show active"
                              id="published"
                              role="tabpanel"
                              aria-labelledby="published-tab"
                            >
                              <div className="table-responsive border-0 overflow-y-hidden">
                                <table className="table mb-0 text-nowrap">
                                  <thead className="table-white">
                                    <tr>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Item
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Status
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Price
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase" />
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    user.published && user.published.length?
                                    user.published.map((item, i)=>
                                    <tr key={i}>
                                      <td className="border-top-0">
                                        <a href={`/draft/${item.id}`} className="text-inherit">
                                          <div className="d-lg-flex align-items-center">
                                            <div>
                                              <Media data={item} className="rounded-3" resolution={{height:"150px", width:"150px"}}/>
                                            </div>
                                            <div className="ms-lg-3 mt-2 mt-lg-0">
                                              <h4 className="mb-1 text-primary-hover">
                                                {JSON.parse(item.alchemy_data.metadata).name}
                                              </h4>
                                              <span className="text-inherit fs-6">
                                                {item.total_supply} in stock{/*(item.created_on._seconds)?((new Date(item.created_on._seconds * 1000)).toString()).slice(0,15):""*/}
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span className={`${item.status==="published"?"bg-success":"bg-warning"} badge-dot me-1 d-inline-block align-middle`}/>
                                        {
                                          item.status==="published"?
                                          <span>Listed</span>
                                          :
                                          <span>{item.status}</span>
                                        }
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span>${item.amount}</span>
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span className="dropdown dropstart">
                                          <a
                                            className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                            href="/#"
                                            role="button"
                                            id="courseDropdown1"
                                            data-bs-toggle="dropdown"
                                            data-bs-offset="-20,20"
                                            aria-expanded="false"
                                          >
                                            <i className="fe fe-more-vertical" />
                                          </a>
                                          <span
                                            className="dropdown-menu"
                                            aria-labelledby="courseDropdown1"
                                          >
                                            <span className="dropdown-header">Settings</span>
                                            <a className="dropdown-item" href={`/draft/${item.id}`}>
                                              <i className="fe fe-edit dropdown-item-icon" />
                                              Edit
                                            </a>
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    )
                                    :
                                    null
                                  }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            {/*Tab pane */}
                            <div
                              className="tab-pane fade"
                              id="pending"
                              role="tabpanel"
                              aria-labelledby="pending-tab"
                            >
                              <div className="table-responsive border-0 overflow-y-hidden">
                                <table className="table mb-0 text-nowrap">
                                  <thead className="table-white">
                                    <tr>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Item
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Status
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Price
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase" />
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    user.sold && user.sold.length?
                                    user.sold.map((item, i)=>
                                    <tr key={i}>
                                      <td className="border-top-0">
                                        <a href={`/draft/${item.id}`} className="text-inherit">
                                          <div className="d-lg-flex align-items-center">
                                            <div>
                                              <Media data={item} className="rounded-3" resolution={{height:"150px", width:"150px"}}/>
                                            </div>
                                            <div className="ms-lg-3 mt-2 mt-lg-0">
                                              <h4 className="mb-1 text-primary-hover">
                                                {JSON.parse(item.alchemy_data.metadata).name}
                                              </h4>
                                              <span className="text-inherit fs-6">
                                                {item.total_supply} in stock{/*(item.created_on._seconds)?((new Date(item.created_on._seconds * 1000)).toString()).slice(0,15):""*/}
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span className={`${item.status==="published"?"bg-success":"bg-warning"} badge-dot me-1 d-inline-block align-middle`}/>
                                        {
                                          item.is_sold?
                                          <span>Sold out</span>
                                          :
                                          <span>{item.status}</span>
                                        }
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span>${item.amount}</span>
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span className="dropdown dropstart">
                                          <a
                                            className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                            href="/#"
                                            role="button"
                                            id="courseDropdown1"
                                            data-bs-toggle="dropdown"
                                            data-bs-offset="-20,20"
                                            aria-expanded="false"
                                          >
                                            <i className="fe fe-more-vertical" />
                                          </a>
                                          <span
                                            className="dropdown-menu"
                                            aria-labelledby="courseDropdown1"
                                          >
                                            <span className="dropdown-header">Settings</span>
                                            <a className="dropdown-item" href={`/draft/${item.id}`}>
                                              <i className="fe fe-edit dropdown-item-icon" />
                                              Edit
                                            </a>
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    )
                                    :
                                    null
                                  }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div
                              className="tab-pane fade"
                              id="drafts"
                              role="tabpanel"
                              aria-labelledby="drafts-tab"
                            >
                              <div className="table-responsive border-0 overflow-y-hidden">
                                <table className="table mb-0 text-nowrap">
                                  <thead className="table-white">
                                    <tr>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Item
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Status
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase">
                                        Price
                                      </th>
                                      <th scope="col" className="border-0 text-uppercase" />
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {
                                    user.drafts && user.drafts.length?
                                    user.drafts.map((item, i)=>
                                    <tr key={i}>
                                      <td className="border-top-0">
                                        <a href={`/draft/${item.id}`} className="text-inherit">
                                          <div className="d-lg-flex align-items-center">
                                            <div>
                                              <Media data={item} className="rounded-3" resolution={{height:"150px", width:"150px"}}/>
                                            </div>
                                            <div className="ms-lg-3 mt-2 mt-lg-0">
                                              <h4 className="mb-1 text-primary-hover">
                                                {JSON.parse(item.alchemy_data.metadata).name}
                                              </h4>
                                              <span className="text-inherit fs-6">
                                                {item.total_supply} in stock{/*(item.created_on._seconds)?((new Date(item.created_on._seconds * 1000)).toString()).slice(0,15):""*/}
                                              </span>
                                            </div>
                                          </div>
                                        </a>
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span className={`${item.status==="draft"?"bg-warning":"bg-warning"} badge-dot me-1 d-inline-block align-middle`}/>
                                        {
                                          item.status==="draft"?
                                          <span>Draft</span>
                                          :
                                          <span>{item.status}</span>
                                        }
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span>${item.amount}</span>
                                      </td>
                                      <td className="align-middle border-top-0">
                                        <span className="dropdown dropstart">
                                          <a
                                            className="btn-icon btn btn-ghost btn-sm rounded-circle"
                                            href="/#"
                                            role="button"
                                            id="courseDropdown1"
                                            data-bs-toggle="dropdown"
                                            data-bs-offset="-20,20"
                                            aria-expanded="false"
                                          >
                                            <i className="fe fe-more-vertical" />
                                          </a>
                                          <span
                                            className="dropdown-menu"
                                            aria-labelledby="courseDropdown1"
                                          >
                                            <span className="dropdown-header">Settings</span>
                                            <a className="dropdown-item" href={`/draft/${item.id}`}>
                                              <i className="fe fe-edit dropdown-item-icon" />
                                              Edit
                                            </a>
                                          </span>
                                        </span>
                                      </td>
                                    </tr>
                                    )
                                    :
                                    null
                                  }
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer border-top-0 border-0">
                        </div>
                      </div>
                    </div>
                  </div>
                  :
                  <div className="col-12 col-md-7 col-lg-10">
                    <div className="card rounded-4 py-6 d-flex justify-content-center align-items-center m-auto">
                       <div className="mt-4 text-center">
                         <Link to="/draft" className="btn btn-primary brand-button">
                           <i className="fas fa-shopping-cart"/>
                           {" "}Sell an Item
                         </Link>
                         <p className="my-5">
                           All your items will appear here.
                         </p>
                       </div>
                     </div>
                   </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Profile;
//@ NFTmarket.africa 2022
