import React, {useState, useEffect} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {LandingWrapper} from './wrapper';
import Avatar from '../component/ui_kits/avatar';
import {articles} from '../store/data';
import {host} from '../config';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();


const Articles =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_ARTICLES')
    }
  },[pageLoaded, setPageLoaded])

  let navigate = useNavigate();
  let [currentArticle, setCurrentArticle] = useState(articles[0]);
  let {article_id} = useParams();

  useEffect(()=>{
    if(article_id){
      let article = articles.filter((article)=> article.slug === article_id);
      if(article.length){
        setCurrentArticle(article[0] || articles[0])
      }else{
        navigate('/articles');
      }
    }
  },[article_id, navigate])

  let metadata = {
    title:currentArticle.title,
    meta_title:currentArticle.title,
    description:currentArticle.summary,
    social_image:host + currentArticle.cover || false
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      {
        !article_id?
        <>
        <div className="pt-15 pb-9 bg-white">
          <div className="container">
            <div className="row">
              <div className="col-xl-8 col-lg-10 col-md-12 col-12">
                <div className="text-left mb-5">
                  <h1 className="display-2 fw-bold">Articles</h1>
                  <p className="lead">
                    Find useful information on NFT Market Africa.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-12 bg-white">
          <div className="container">
            <div className="row">
            {
              articles.map((article, i)=>
              <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-12">
                {/* Card */}
                <div className="card mb-4 shadow ">
                  <a href={`/articles/${article.slug}`} className="card-img-top" onClick={()=> EventTracker.logEvent('VIEWED_ARTICLE')}>
                    <img
                      src={article.cover}
                      className="card-img-top rounded-top-md"
                      alt=""
                    />
                  </a>
                  <div className="card-body">
                    <p className="fs-5 fw-semi-bold d-block mb-3 text-success">
                      {article.type}
                    </p>
                    <h3>
                      <a href={`/articles/${article.slug}`} className="text-inherit" onClick={()=> EventTracker.logEvent('VIEWED_ARTICLE')}>
                        {article.title}
                      </a>
                    </h3>
                    <p>
                      {article.summary}
                    </p>
                    <div className="row justify-content-between align-items-center g-0 mt-4">
                      <div className="col-auto">
                        <Avatar data={{account:article.author}} showName={true} size="avatar-sm"/>
                      </div>
                      <div className="col-auto">
                        <p className="fs-6 mb-0">{article.read_time_minutes} Min Read</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )
            }
            </div>
          </div>
        </div>
        </>
        :
        <div className="py-15 pb-14 bg-white">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <div className="text-center mb-4">
                  <a href="#/" className="fs-5 fw-semi-bold d-block mb-4 text-primary">
                    {currentArticle.type}
                  </a>
                  <h1 className="display-3 fw-bold mb-4">
                    {currentArticle.title}
                  </h1>
                  <p className="lead">{currentArticle.summary}</p>
                  <span className="mb-3 d-inline-block">{currentArticle.read_time_minutes} min read</span>
                </div>
                {/* Media */}
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <div className="d-flex align-items-center">
                    <Avatar data={{account:currentArticle.author}} size="avatar-md" showName={true}/>
                  </div>
                  <div>
                    <span className="ms-2 text-muted">Share</span>
                    <a href="#/" className="ms-2 text-muted">
                      <i className="mdi mdi-facebook fs-4" />
                    </a>
                    <a href="#/" className="ms-2 text-muted">
                      <i className="mdi mdi-twitter fs-4" />
                    </a>
                    <a href="#/" className="ms-2 text-muted ">
                      <i className="mdi mdi-linkedin fs-4" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="row justify-content-center">
              {/* Image */}
              <div className="col-xl-8 col-lg-8 col-md-12 col-12 mb-2">
                <img
                  src={currentArticle.cover}
                  alt=""
                  className="img-fluid rounded-3"
                />
                <div className="py-6">
                  {currentArticle.content}
                </div>
                <hr className="mt-8 mb-5"/>
                <div className="d-flex justify-content-between align-items-center mb-5">
                  <div className="d-flex align-items-center">
                    <Avatar data={{account:currentArticle.author}} size="avatar-md" showName={true}/>
                  </div>
                  <div>
                    <span className="ms-2 text-muted">Share</span>
                    <a href="#/" className="ms-2 text-muted">
                      <i className="mdi mdi-facebook fs-4" />
                    </a>
                    <a href="#/" className="ms-2 text-muted">
                      <i className="mdi mdi-twitter fs-4" />
                    </a>
                    <a href="#/" className="ms-2 text-muted ">
                      <i className="mdi mdi-linkedin fs-4" />
                    </a>
                  </div>
                </div>
              </div>

            </div>
          </div>


          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12 col-12">
                <div className="my-5">
                  <h2>Related Article</h2>
                </div>
              </div>
              {
                articles.filter((artc)=> artc.slug !== currentArticle.slug).slice(0, 3).map((article, i)=>
                <div key={i} className="col-xl-4 col-lg-4 col-md-6 col-12">
                  {/* Card */}
                  <div className="card mb-4 shadow ">
                    <a href={`/articles/${article.slug}`} className="card-img-top">
                      {" "}
                      <img
                        src={article.cover}
                        className="card-img-top rounded-top-md"
                        alt=""
                      />
                    </a>
                    {/* Card body */}
                    <div className="card-body">
                      <a href="#/" className="fs-5 fw-semi-bold d-block mb-3 text-success">
                        {article.type}
                      </a>
                      <h3>
                        <a href={`/articles/${article.slug}`} className="text-inherit">
                          {article.title}
                        </a>
                      </h3>
                      <p>
                        {article.summary}
                      </p>
                      {/* Media content */}
                      <div className="row justify-content-between align-items-center g-0 mt-4">
                        <div className="col-auto">
                          <Avatar data={{account:article.author}} showName={true} size="avatar-sm"/>
                        </div>
                        <div className="col-auto">
                          <p className="fs-6 mb-0">{article.read_time_minutes} Min Read</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              }
            </div>
          </div>


        </div>
      }

    </LandingWrapper>
  )
}
export default Articles;
//@nftmarket.africa 2022
