import React, {useState, useEffect} from 'react';
import {NavLink, useParams} from 'react-router-dom';
import {LandingWrapper} from './wrapper';
import {course} from '../store/data';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Learn",
  meta_title:"NFT Market Africa - Learn",
  description:"Learn the fundamentals of NFT"
}

const Learn =(props)=> {
  let [currentCourse, setCurrentCourse] = useState(course)
  let [currentLesson, setCurrentLesson] = useState(course.lessons[0])
  let {lesson_id} = useParams();
  useEffect(()=>{
    if(lesson_id){
      let lesson = currentCourse.lessons.filter((lesson)=> lesson.slug === lesson_id);
      setCurrentLesson(lesson[0] || course.lessons[0])
    }
  },[lesson_id, currentCourse.lessons, setCurrentCourse])
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 py-10 py-lg-15 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-12">
              {/* caption*/}
              <h1 className="fw-bold mb-1 display-4 text-white">Learn ✏️</h1>
              <p className="mb-0 lead text-muted">Learn the fundamentals of NFT for free!</p>
            </div>
          </div>
        </div>
      </div>
      <div className="py-10">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12 mb-5">
              <div
                className="rounded-3 position-relative w-100 d-block overflow-hidden"
                style={{ height: 600 }}
              >
                <iframe
                  title="course videos"
                  className="position-absolute top-0 end-0 start-0 end-0 bottom-0 h-100 w-100"
                  src={`https://www.youtube.com/embed/${currentLesson.youtube_id}`}
                />
              </div>
            </div>
          </div>
          {/* Content */}
          <div className="row">
            <div className="col-xl-4 col-lg-12 col-md-12 col-12 mb-4">
              <div className="card" id="courseAccordion">
                <div>
                  {/* List group */}
                  <ul className="list-group list-group-flush overflow-hidden">
                    <li className="list-group-item p-0 bg-transparent">
                      {/* Toggle */}
                      <a
                        className="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none py-3 px-4"
                        data-bs-toggle="collapse"
                        href="#courseTwo"
                        role="button"
                        aria-expanded="true"
                        aria-controls="courseTwo"
                      >
                        <div className="me-auto">
                          {currentCourse.name}
                          <p className="mb-0 text-muted fs-6 mt-1 fw-normal">
                            {currentCourse.timeframe}
                          </p>
                        </div>
                        {/* Chevron */}
                        <span className="chevron-arrow ms-4">
                          <i className="fe fe-chevron-down fs-4"/>
                        </span>
                      </a>
                      {/* Row */}
                      {/* Collapse */}
                      <div
                        className="collapse show"
                        id="courseTwo"
                        data-bs-parent="#courseAccordion"
                        style={{}}
                      >
                        {/* List group item */}
                        <ul className="list-group list-group-flush overflow-hidden">
                          <li className="list-group-item">
                            <div>
                              <div className="progress" style={{ height: 6 }}>
                                <div
                                  className="progress-bar bg-success"
                                  role="progressbar"
                                  style={{ width: `${currentLesson.complete_percentage}%` }}
                                  aria-valuenow={currentLesson.complete_percentage}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                              <small>{currentLesson.complete_percentage}% Completed</small>
                            </div>
                          </li>
                          {
                            currentCourse.lessons.map((lesson, i)=>
                            <li key={i}  onClick={()=> EventTracker.logEvent('LEARNING')} className={`list-group-item ${lesson.slug === currentLesson.slug? "list-group-item-action active":""}`}>
                              <NavLink
                                to={lesson.locked?`/learn`:`/learn/${lesson.slug}#`}
                                className={`d-flex justify-content-between align-items-center text-decoration-none ${lesson.slug === currentLesson.slug? "text-white":"text-inherit"}`}
                              >
                                <div className="text-truncate">
                                  <span className={`icon-shape icon-sm rounded-circle me-2 ${lesson.slug === currentLesson.slug? "bg-success text-white":"bg-light"}`}>
                                    {
                                      lesson.locked?
                                      <i className="mdi mdi-lock text-muted fs-4" />
                                      :
                                      <i className="mdi mdi-play fs-4" />
                                    }
                                  </span>
                                  <span>{lesson.title}</span>
                                </div>
                                <div className="text-truncate">
                                  <span>{lesson.time}</span>
                                </div>
                              </NavLink>
                            </li>
                            )
                          }
                        </ul>
                      </div>
                    </li>

                    <li className="list-group-item p-0 bg-transparent">
                      <a
                        className="h4 mb-0 d-flex align-items-center text-inherit text-decoration-none py-3 px-4 collapsed"
                        data-bs-toggle="collapse"
                        href="#courseEleven"
                        role="button"
                        aria-expanded="false"
                        aria-controls="courseEleven"
                      >
                        <div className="me-auto">
                          {/* Title */}
                          Print Certificate
                          <p className="mb-0 text-muted fs-6 mt-1 fw-normal">
                            Take a quick test
                          </p>
                        </div>
                        {/* Chevron */}
                        <span className="chevron-arrow ms-4">
                          <i className="fe fe-chevron-down fs-4" />
                        </span>
                      </a>
                      {/* Row */}
                      {/* Collapse */}
                      <div
                        className="collapse"
                        id="courseEleven"
                        data-bs-parent="#courseAccordion"
                        style={{}}
                      >
                        <div className="px-4 pt-0 pb-2">
                          <p>
                            Take a quick test and get a certificate
                            of knowledge to prove that you understand the basics
                            of Non-fungible Tokens (NFTs) and Blockchain.
                          </p>
                          <p className="text-muted">
                            Coming soon
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-xl-8 col-lg-12 col-md-12 col-12 mb-4 mb-xl-0">
              {/* Card */}
              <div className="card mb-5">
                {/* Card body */}
                <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <h1 className="fw-semi-bold mb-2">
                      {currentLesson.title}
                    </h1>
                    <a
                      href="/#"
                      data-bs-toggle="tooltip"
                      data-placement="top"
                      title=""
                      data-bs-original-title="Share this Course"
                    >
                      <i className="fe fe-share fs-3 text-inherit" />
                    </a>
                  </div>
                  <div className="d-flex mb-5">
                    <span>
                      <i className="mdi mdi-star me-n1 text-warning" />
                      <i className="mdi mdi-star me-n1 text-warning" />
                      <i className="mdi mdi-star me-n1 text-warning" />
                      <i className="mdi mdi-star me-n1 text-warning" />
                      <i className="mdi mdi-star-half-full text-warning" />
                      <span className="fw-medium">{currentLesson.rating}</span>
                    </span>
                    <span className="ms-4 d-none d-md-block">
                      <span>{currentCourse.name}</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <img
                        src={currentLesson.author.avatar}
                        className="rounded-circle avatar-md"
                        alt=""
                      />
                      <div className="ms-2 lh-1">
                        <h4 className="mb-1">{currentLesson.author.name}</h4>
                        <p className="fs-6 mb-0">@{currentLesson.author.username}</p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Nav tabs */}
                <ul className="nav nav-lt-tab" id="tab" role="tablist">
                  {/* Nav item */}
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="description-tab"
                      data-bs-toggle="pill"
                      href="#description"
                      role="tab"
                      aria-controls="description"
                      aria-selected="true"
                    >
                      Description
                    </a>
                  </li>
                  {/* Nav item */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="review-tab"
                      data-bs-toggle="pill"
                      href="#review"
                      role="tab"
                      aria-controls="review"
                      aria-selected="false"
                    >
                      Reviews
                    </a>
                  </li>
                  {/* Nav item */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="transcript-tab"
                      data-bs-toggle="pill"
                      href="#transcript"
                      role="tab"
                      aria-controls="transcript"
                      aria-selected="false"
                    >
                      Transcript
                    </a>
                  </li>
                  {/* Nav item */}
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      id="faq-tab"
                      data-bs-toggle="pill"
                      href="#faq"
                      role="tab"
                      aria-controls="faq"
                      aria-selected="false"
                    >
                      FAQ
                    </a>
                  </li>
                </ul>
              </div>
              {/* Card */}
              <div className="card rounded-3">
                {/* Card body */}
                <div className="card-body">
                  <div className="tab-content" id="tabContent">
                    {/* Tab pane */}
                    <div
                      className="tab-pane fade active show"
                      id="description"
                      role="tabpanel"
                      aria-labelledby="description-tab"
                    >
                      <div className="mb-4">
                        <h3 className="mb-2">Course Descriptions</h3>
                        <p>
                          This course is aimed at teaching the basic
                          and fundamentals of NFT. If you are new to NFTs
                          and you want to jumpstart into creating, buying or selling
                          your own NFT, this is the right course for you.
                        </p>
                      </div>
                      <h4 className="mb-3">What you’ll learn</h4>
                      <div className="row mb-3">
                        <div className="col-12 col-md-6">
                          {/* List group */}
                          <ul className="list-unstyled">
                            <li className="d-flex align-item-center mb-2">
                              <i className="mdi mdi-check-circle-outline fs-4 text-success me-1 lh-lg" />
                              <span>
                                The basics of NFTs
                              </span>
                            </li>
                            <li className="d-flex align-item-center mb-2">
                              <i className="mdi mdi-check-circle-outline fs-4 text-success me-1 lh-lg" />
                              <span>
                                The basics of Blockchain
                              </span>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 col-md-6">
                          {/* List group */}
                          <ul className="list-unstyled">
                            <li className="d-flex align-item-center mb-2">
                              <i className="mdi mdi-check-circle-outline fs-4 text-success me-1 lh-lg" />
                              <span>
                                How to create and sell NFTS
                              </span>
                            </li>
                            <li className="d-flex align-item-center mb-2">
                              <i className="mdi mdi-check-circle-outline fs-4 text-success me-1 lh-lg" />
                              <span>
                                How to discover and buy NFTs
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <p>
                        Thats it!, you now have the necessary knowledge to create, buy and sell your own NFT. Take a knowledge test and print your certificate.
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="review"
                      role="tabpanel"
                      aria-labelledby="review-tab"
                    >
                      <div className="mb-3">
                        {/* Content */}
                        <h3 className="mb-4">How students rated this courses</h3>
                        <div className="row align-items-center">
                          <div className="col-auto text-center">
                            <h3 className="display-2 fw-bold">4.5</h3>
                            <i className="mdi mdi-star me-n1 text-warning" />
                            <i className="mdi mdi-star me-n1 text-warning" />
                            <i className="mdi mdi-star me-n1 text-warning" />
                            <i className="mdi mdi-star me-n1 text-warning" />
                            <i className="mdi mdi-star me-n1-half text-warning" />
                            <p className="mb-0 fs-6">(Based on 22 reviews)</p>
                          </div>
                          {/* Progress bar */}
                          <div className="col pt-3 order-3 order-md-2">
                            <div className="progress mb-3" style={{ height: 6 }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "90%" }}
                                aria-valuenow={90}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <div className="progress mb-3" style={{ height: 6 }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "80%" }}
                                aria-valuenow={80}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <div className="progress mb-3" style={{ height: 6 }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "70%" }}
                                aria-valuenow={70}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <div className="progress mb-3" style={{ height: 6 }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "60%" }}
                                aria-valuenow={60}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                            <div className="progress mb-0" style={{ height: 6 }}>
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{ width: "50%" }}
                                aria-valuenow={50}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                          {/* Rating */}
                          <div className="col-md-auto col-6 order-2 order-md-3">
                            <div>
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <span className="ms-1">53%</span>
                            </div>
                            <div>
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <span className="ms-1">36%</span>
                            </div>
                            <div>
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <span className="ms-1">9%</span>
                            </div>
                            <div>
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <span className="ms-1">3%</span>
                            </div>
                            <div>
                              <i className="mdi mdi-star me-n1 text-warning" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <i className="mdi mdi-star me-n1 text-light" />
                              <span className="ms-1">2%</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="my-5" />
                      <div className="mb-3">
                        {/* Review */}
                        <div className="d-lg-flex align-items-center justify-content-between mb-5">
                          <div className="mb-3 mb-lg-0">
                            <h3 className="mb-0">Reviews</h3>
                          </div>
                        </div>
                        {
                          currentCourse.reviews.map((review, i)=>
                          <div key={i} className="d-flex">
                            {/* Media */}
                            <img
                              src="https://firebasestorage.googleapis.com/v0/b/nftmarket-africa.appspot.com/o/avatar%2F656download.png?alt=media&token=ef7aab97-889f-43eb-91cd-63bb6ff37902"
                              alt=""
                              className="rounded-circle avatar-lg"
                            />
                            {/* Content */}
                            <div className=" ms-3">
                              <h4 className="mb-1">
                                Damilola Ogunsusi
                                <span className="ms-1 fs-6 text-muted">21 Days ago</span>
                              </h4>
                              <div className="fs-6 mb-2">
                                <i className="mdi mdi-star me-n1 text-warning" />
                                <i className="mdi mdi-star me-n1 text-warning" />
                                <i className="mdi mdi-star me-n1 text-warning" />
                                <i className="mdi mdi-star me-n1 text-warning" />
                                <i className="mdi mdi-star me-n1 text-warning" />
                              </div>
                              <p>
                                {review}
                              </p>
                            </div>
                          </div>
                          )
                        }
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="transcript"
                      role="tabpanel"
                      aria-labelledby="transcript-tab"
                    >
                      {/* Description */}
                      <div>
                        <h3 className="mb-3">
                          {`Transcript from the "${currentLesson.title}" Lesson`}
                        </h3>
                        {
                          currentLesson.transcript.map((lesson, i)=>
                          <div key={i} className="mb-4">
                            <h4>
                              {lesson.title}
                            </h4>
                            <p className="mb-0">
                              {lesson.details}
                            </p>
                          </div>
                          )
                        }
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="faq"
                      role="tabpanel"
                      aria-labelledby="faq-tab"
                    >
                      {/* FAQ */}
                      <div>
                        <h3 className="mb-3">Course - Frequently Asked Questions</h3>
                        {
                          currentLesson.faq.map((lesson, i)=>
                          <div key={i} className="mb-4">
                            <h4>{lesson.q}</h4>
                            <p>
                              {lesson.a}
                            </p>
                          </div>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Learn;
//@nftmarket.africa 2022
