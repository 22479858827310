import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Link, NavLink} from 'react-router-dom';
import checkmark from '../assets/images/tools/checkmark.svg';
import {requestVerification, getUserProfile} from '../features/user/userSlice';
import {
  LandingWrapper
} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Become a Creator",
  meta_title:"NFT Market Africa - Become a Creator",
  description:"Request to become a Creator"
}

const VerifyProfile =(props)=> {
  let dispatch = useDispatch();
  let user = useSelector(state => state.user.value)

  useEffect(()=>{
    dispatch(getUserProfile())
  },[dispatch])

  let handleVerification =()=> {
    EventTracker.logEvent('REQUESTED_VERIFICATION');
    dispatch(requestVerification());
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-10 pt-15 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="d-lg-flex align-items-center justify-content-between">
                {/* Content */}
                <div className="mb-4 mb-lg-0">
                  <h1 className="text-white mb-1">Profile</h1>
                  <p className="mb-0 text-white lead">
                    Edit your profile
                  </p>
                </div>
                <div className="d-flex">
                  <Link to="/storefront" className="btn btn-white btn-sm me-2">
                    Back to storefront
                  </Link>
                  {
                    user.verifying?
                    <button
                      disabled
                      className="btn btn-success btn-sm">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </button>
                    :
                    <button
                    className="btn btn-success btn-sm"
                    onClick={handleVerification}
                    disabled={user.profile.profile_verified}
                    >
                    Verify
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="container py-3">
        <div className="row justify-content-center align-items-top">
          <div className="col-12">
            <ul className="nav nav-lb-tab mb-6" id="tab" role="tablist">
              <li className="nav-item ms-0" role="presentation">
              <NavLink
                className="nav-link"
                to="/profile/edit"
              >
                Basic Info
              </NavLink>
            </li>
            <li className="nav-item" role="presentation">
              <NavLink
                className="nav-link"
                to="/profile/verification"
              >
                Become a Creator
              </NavLink>
              </li>
            </ul>
          </div>

          <div className="col-12 col-md-7 col-lg-6 py-6">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-12">
                {/* Card */}
                <div className="card">
                  {/* Card header */}
                  <div className="card-header">
                    <h4 className="mb-2">Become a Creator</h4>
                  </div>
                  {/* Card body */}
                  <div className="card-body">
                    <div className="mb-5 d-flex pt-5">
                      <div>
                      <img src={checkmark} width={40} alt="checkmark"/>
                      </div>
                      <div className="mt-1">
                        {
                          user.profile&&user.profile.profile_verified?
                          <>
                          <h3 className="mb-0">You're a Creator</h3>
                          <p>You are eligible to sell items and receive payouts</p>
                          </>
                          :
                          <>
                          <h3 className="mb-0">Creator Membership</h3>
                          <p>Become a creator to sell items and receive payouts</p>
                          </>
                        }
                        {
                          user.verifying?
                          <button
                            disabled
                            className="btn btn-primary btn-sm">
                            <div class="spinner-border spinner-border-sm" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                             {" "} Verifying
                          </button>
                          :
                          (user.profile&&user.profile.profile_verified)?
                          <button disabled className="btn btn-success btn-sm">
                           Verified
                           {" "}<i className="fe fe-check-circle"/>
                          </button>
                          :
                          <button onClick={handleVerification} className="btn btn-dark btn-sm">
                            Request Membership
                          </button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </LandingWrapper>
  )
}
export default VerifyProfile;
//@ NFTmarket.africa 2022
