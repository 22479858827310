import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {getUserProfile} from '../features/user/userSlice';
import {getOrders} from '../features/checkout/checkoutSlice';
import {currencyFormat} from '../features/tools';
import {useDispatch, useSelector} from 'react-redux';
import {LandingWrapper} from './wrapper';
let metadata = {
  title:"NFT Market Africa - Orders",
  meta_title:"NFT Market Africa - Orders",
  description:"View your Orders"
}

const Orders =(props)=> {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  // let user = useSelector(state => state.user.value);
  let {data, loading} = useSelector( state => state.checkout.value);
  useEffect(()=>{
    dispatch(getUserProfile())
    dispatch(getOrders());
  },[dispatch])

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 bg-white min-vh-75">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/dashboard">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="pt-5 pb-5 px-0">
                  <div className="container">
                    <div className="row justify-content-center">
                      <div className="col-lg-9 col-md-8 col-12">
                        {/* Card */}
                          {
                          data && data.orders && data.orders.length?
                          <div className="card mb-4 shadow-sm rounded-4">
                            {/* Card header */}
                            <div className="card-header border-bottom-0">
                              <h3 className="mb-0">Orders</h3>
                              <p className="mb-0">Find all of your orders.</p>
                            </div>
                            <div className="table-invoice table-responsive border-0">
                              <table className="table table-hover mb-0 text-nowrap">
                                <thead className="table-light">
                                  <tr>
                                    <th scope="col" className="border-bottom-0">
                                      ORDER ID
                                    </th>
                                    <th scope="col" className="border-bottom-0">
                                      TOKEN ID
                                    </th>
                                    <th scope="col" className="border-bottom-0">
                                      AMOUNT
                                    </th>
                                    <th scope="col" className="border-bottom-0">
                                      STATUS
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                {
                                  data.orders.map( (order, i) =>
                                    <tr key={i} className="clickable" onClick={()=> navigate(`/order/${order.order_id}`)}>
                                      <td>
                                        <a href={`/order/${order.order_id}`}>{order.order_id}</a>
                                      </td>
                                      <td>{sliceCharacter(order.token_id)}</td>
                                      <td>{currencyFormat(order.total_amount, 'USD')}</td>
                                      <td>
                                      {
                                        order.status === 'unpaid'?
                                        <span className="badge bg-danger">{order.status}</span>
                                        :
                                        order.status === 'pending'?
                                        <span className="badge bg-warning">{order.status}</span>
                                        :
                                        order.status === 'processing'?
                                        <span className="badge bg-success-light">{order.status}</span>
                                        :
                                        order.status === 'complete'?
                                        <span className="badge bg-success">{order.status}</span>
                                        :
                                        order.status === 'hold'?
                                        <span className="badge bg-warning">{order.status}</span>
                                        :
                                        <span className="badge bg-light">{order.status}</span>
                                      }
                                      </td>
                                    </tr>
                                  )
                                }
                                </tbody>
                              </table>
                            </div>
                          </div>
                          :
                          (loading)?
                          <div className='text-center'>
                            <div className="spinner-border spinner-border-lg" role="status">
                              <span className="sr-only"></span>
                            </div>
                          </div>
                          :
                          <div className="text-center">
                            <p>You have no order yet. Buy some NFTs</p>
                            <a href="/discover" className="btn btn-primary brand-button">
                              Buy NFT
                            </a>
                          </div>
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Orders;
//@ NFTmarket.africa 2022
