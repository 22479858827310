import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import Cryptr from 'cryptr';
import {useDispatch, useSelector} from 'react-redux';
import {createGallery} from '../features/gallery/gallerySlice';
import {LandingWrapper} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();

let metadata = {
  title:"Create Gallery",
  meta_title:"NFT Market Africa - Create Gallery",
  description:"Create a gallery to store yor NFTs"
}

const CreateGallery =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_GALLERY_SECURITY')
    }
  },[pageLoaded, setPageLoaded])

  let dispatch = useDispatch()
  let gallery = useSelector( state => state.gallery.value)
  let [view, setView] = useState('default')
  let [keys, setKeys] = useState({})
  let [accepted, setAccepted] = useState(false)
  let [pinConfirmed, setPinConfirmed] = useState(false)
  let [details, setDetails] = useState({
    chain:"polygon",
    name:"Polygon Gallery"
  })

  let handleInput =(e)=> {
    let {value, name, accept} = e.target;
    details = {
      ...details,
      [name]:value
    }
    if(value && value.match(accept)){
      setDetails(details);
    }
  }

  let handleConfirmSecretPin =(e)=> {
    let {value} = e.target;
    if(value === details.secret_pin){
      setPinConfirmed(value)
    }else{
      setPinConfirmed(false)
    }
  }

  // useEffect(()=>{
  //   if(pinConfirmed === details.secret_pin){
  //     setPinConfirmed(pinConfirmed)
  //   }else{
  //     setPinConfirmed(pinConfirmed)
  //   }
  // },[details.secret_pin])

  let handleCreateGallery = async(e) => {
    e.preventDefault()
    dispatch(createGallery(details)).then(async(response)=>{
      if(response.meta && response.meta.requestStatus === "fulfilled"){
        try{
          setView('secure')
          let {sec_key_hash, priv_key_hash} = response.payload.data;
          const cryptr = new Cryptr(details.secret_pin);
          const private_key = await cryptr.decrypt(priv_key_hash);
          const seed_phrase = await cryptr.decrypt(sec_key_hash);
          if(private_key && seed_phrase){
            EventTracker.logEvent('DECRYPTING_KEYS_SUCESS')
            return setKeys({private_key, seed_phrase, decrypted:true})
          }else{
            EventTracker.logEvent('DECRYPTING_KEYS_FAILURE')
          }
        }catch(err){
          EventTracker.logEvent('DECRYPTING_KEYS_FAILURE')
        }
      }
    })
  }


  let [copied, setCopiedStatus] = useState({})

  useEffect(()=>{
    if(copied){
      let timer = setTimeout(()=>{
        setCopiedStatus({})
      },1000)
      return function cleanUp(){
        clearTimeout(timer)
      }
    }
  },[copied])

  let copyKeys =(link, type)=> {
    navigator.clipboard.writeText(link);
    setCopiedStatus({
      ...copied,
      [type]:true
    })
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 bg-white min-vh-75">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/gallery">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
            <div className="col-md-8 col-12 py-3">
            {
              view !== "secure"?
              <div className="card bg-light shadow-none rounded-4">
                <div className="card-body p-5">
                  <h2 className="mb-4 fw-semi-bold">Gallery Details</h2>
                  <form onSubmit={handleCreateGallery}>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        onChange={handleInput}
                        className="form-control form-control-lg rounded-4"
                        type="text"
                        placeholder="Gallery Name"
                        id="name"
                        name="name"
                        required=""
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="chain">
                        Blockchain
                      </label>
                      <select className="form-select form-select-lg rounded-4" id="chain" name="chain" onChange={handleInput}>
                        <option value="polygon">Polygon</option>
                        <option value="ethereum">Ethereum</option>
                      </select>
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Secret PIN
                      </label>
                      <input
                        onChange={handleInput}
                        className="form-control form-control-lg rounded-4"
                        type="number"
                        placeholder="Secret PIN"
                        id="secret_pin"
                        name="secret_pin"
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label" htmlFor="name">
                        Confirm Secret PIN
                      </label>
                      <input
                        onChange={handleConfirmSecretPin}
                        className="form-control form-control-lg rounded-4"
                        type="number"
                        placeholder="Confirm Secret PIN"
                        id="confirm_secret_pin"
                        name="confirm_secret_pin"
                        required
                      />
                    </div>
                    {
                      gallery.creating?
                      <button className="btn btn-primary  brand-button" type="button" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        {" "}Creating...
                      </button>
                      :
                      <button
                        className="btn btn-primary  brand-button"
                        type="submit"
                        disabled={!pinConfirmed || (pinConfirmed!==details.secret_pin)}
                      >
                        Create
                      </button>
                    }
                  </form>
                </div>
              </div>
              :
              <div className="card rounded-4 bg-light shadow-none">
                <div className="card-body p-5">
                  <h2 className="mb-4 fw-semi-bold">Keep Your Gallery Safe <i className="fe fe-shield text-success"/></h2>
                  <p>Keep your gallery keys safe. It is the access to your gallery. <strong>Only you</strong> have access to it and <strong>only you</strong> can recover it with your <strong>6 digit PIN</strong>.</p>
                  <label className="fw-bold">Private Key</label>
                  <input className="form-control form-control-lg rounded-4" placeholder="Private Key" id="private-key-secure" defaultValue={keys.private_key} type={accepted?"text":"password"}/>
                  <br/>
                  <label className="fw-bold">12 Word Seed Phrase</label>
                  <input className="form-control form-control-lg rounded-4" placeholder="Seed Phrase" id="seed-phrase-secure" defaultValue={keys.seed_phrase} type={accepted?"text":"password"}/>
                  <div className="d-lg-flex justify-content-between align-items-center py-3 mb-4">
                    <div className="form-check">
                      <input type="checkbox" checked={accepted} className="form-check-input" id="rememberme" onChange={()=> setAccepted(!accepted)} />
                      <label className="form-check-label" htmlFor="rememberme">
                        Show my keys. I agree that anyone with these keys will have access to my gallery and NFTs
                      </label>
                    </div>
                  </div>
                  <div className="d-grid">
                    <button className="btn btn-primary brand-button mb-3 " disabled={!accepted} onClick={()=> copyKeys(keys.private_key, 'private_key')}>
                    {
                      copied.private_key?
                      <span>Copied <i className="fe fe-check-circle text-success"/></span>
                      :
                      <span>Copy Private Key</span>
                    }
                    </button>
                    <button className="btn btn-primary brand-button mb-3 " disabled={!accepted} onClick={()=> copyKeys(keys.seed_phrase, 'seed_phrase')}>
                    {
                      copied.seed_phrase?
                      <span>Copied <i className="fe fe-check-circle text-success"/></span>
                      :
                      <span>Copy 12 Word Seed Phrase</span>
                    }
                    </button>
                    <a className="btn btn-success  brand-button" href={!accepted?"#":"/gallery"} disabled={!accepted}>
                      <span>Finish</span>
                    </a>
                  </div>
                </div>
              </div>
            }
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default CreateGallery;
//@ NFTmarket.africa 2022
