import toast, { Toaster } from 'react-hot-toast';

const notify = () => toast.success('Copied');

export default function CoptText(props){
  let {type} = props;

  let copyText =(text)=> {
    if(navigator) {
      navigator.clipboard.writeText(text)
      notify();
    }
  }
  return (
    <>
    {
      type==="button"?
      <>
      <button className="btn btn-primary brand-button" onClick={()=>copyText(props.text)}>
        Copy Address
      </button>
      <Toaster />
      </>
      :
      <>
      <i className="fe fe-copy clickable" onClick={()=>copyText(props.text)}></i>
      <Toaster />
      </>
    }
    </>
  );
};

//@nftmarket.africa 2022
