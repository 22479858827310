import React, {useState, useEffect} from 'react';
// import {NavLink} from 'react-router-dom';
import {LandingWrapper} from './wrapper';
import {Amplitude} from '../features/tools';
let EventTracker = new Amplitude();


let metadata = {
  title:"NFT Market Africa - FAQ.",
  meta_title:"NFT Market Africa - FAQ",
  description:"Frequently Asked Questions"
}

const Faq =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_FAQ')
    }
  },[pageLoaded, setPageLoaded])

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-20 bg-white min-vh-75">
        <div className="container">
          <div className="row" id="collectors">
            <div className="col-12">
              <div className="mb-4 mt-6">
                <span>FAQ</span>
                <h2 className="mb-0 fs-2 fw-bold">Collectors</h2>
              </div>
              <div className="accordion accordion-flush" id="sellAccordion" onClick={()=> EventTracker.logEvent('VIEWED_FAQ')}>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="HowToBuy">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <span className="me-auto fs-3 fw-bold">How do I buy an NFT?</span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="HowToBuy"
                    data-bs-parent="#sellAccordion"
                    style={{}}
                  >
                    <div className="pt-2 fs-4">
                      Go to the <a href="/discover">discover</a> page to find an NFT you want to purchase then click on buy item to enter your wallet and shipping address. Your order will be completed after we receive payment
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="offerUtility">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        Do you offer Utility?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="offerUtility"
                    data-bs-parent="#sellAccordion"
                  >
                    <div className="pt-3 fs-4">
                      Yes we offer physical utility such as physical items and physical events.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="payment_accepted">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        What payment options do you accept?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="payment_accepted"
                    data-bs-parent="#sellAccordion"
                  >
                    <div className="pt-3 fs-4">
                      We accept Card, Crypo and Bank Payments.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="walletRequired">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        Is it required to connect my wallet to buy an NFT?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="walletRequired"
                    data-bs-parent="#sellAccordion"
                  >
                    <div className="pt-3 fs-4">
                      No, connecting your wallet is optional, with our express checkout feature, you can buy NFTs without connecting your wallet.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="whatBlockchain">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFive"
                      aria-expanded="false"
                      aria-controls="collapseFive"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        What blockchain do you support?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseFive"
                    className="collapse"
                    aria-labelledby="whatBlockchain"
                    data-bs-parent="#sellAccordion"
                  >
                    <div className="pt-3 fs-4">
                      We support the Ethereum and Polygon Blockchain.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-6" id="collectors">
            <div className="col-12">
              <div className="mb-4 mt-6">
                <span>FAQ</span>
                <h2 className="mb-0 fs-2 fw-bold">Creators</h2>
              </div>
              <div className="accordion accordion-flush" id="creatorsTab" onClick={()=> EventTracker.logEvent('VIEWED_FAQ')}>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="HowTosellMyNft">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix"
                      aria-expanded="false"
                      aria-controls="collapseSix"
                    >
                      <span className="me-auto fs-3 fw-bold">How do I list my Item for sale?</span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseSix"
                    className="collapse"
                    aria-labelledby="HowTosellMyNft"
                    data-bs-parent="#creatorsTab"
                    style={{}}
                  >
                    <div className="pt-2 fs-4">
                      Go to your storefront and click on List item. Your profile must be verified to see this option.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="addUtility">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSix2"
                      aria-expanded="false"
                      aria-controls="collapseSix2"
                    >
                      <span className="me-auto fs-3 fw-bold">How do I add Utility to my Item?</span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseSix2"
                    className="collapse"
                    aria-labelledby="addUtility"
                    data-bs-parent="#creatorsTab"
                    style={{}}
                  >
                    <div className="pt-2 fs-4">
                      While listing your item you will find the option to add utility.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="HowDoIGetPaid">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseSeven"
                      aria-expanded="false"
                      aria-controls="collapseSeven"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        What blockchain do you support?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseSeven"
                    className="collapse"
                    aria-labelledby="HowDoIGetPaid"
                    data-bs-parent="#creatorsTab"
                  >
                    <div className="pt-3 fs-4">
                      We support the Ethereum and Polygon blockchain.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="HowToBecomeACreator">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseEight"
                      aria-expanded="false"
                      aria-controls="collapseEight"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        Can I mint my work on the platform?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseEight"
                    className="collapse"
                    aria-labelledby="HowToBecomeACreator"
                    data-bs-parent="#creatorsTab"
                  >
                    <div className="pt-3 fs-4">
                      You will soon be able to mint directly on the platform. We currently support lisitng only.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="canIMint">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseNine"
                      aria-expanded="false"
                      aria-controls="collapseNine"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        Why can't I see the option to list my NFTs?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseNine"
                    className="collapse"
                    aria-labelledby="canIMint"
                    data-bs-parent="#creatorsTab"
                  >
                    <div className="pt-3 fs-4">
                      Your profile must be verified before you can list items.
                    </div>
                  </div>
                </div>
                <div className="border border-3  border-light p-5 rounded-4 mb-2 my-4" id="canIMint">
                  <h3 className="mb-0 fs-4">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active collapsed"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTen"
                      aria-expanded="false"
                      aria-controls="collapseTen"
                    >
                      <span className="me-auto fs-3 fw-bold">
                        How do I get paid?
                      </span>
                      <span className="collapse-toggle ms-4 fs-3">
                        <i className="fe fe-chevron-down text-muted" />
                      </span>
                    </a>
                  </h3>
                  <div
                    id="collapseTen"
                    className="collapse"
                    aria-labelledby="canIMint"
                    data-bs-parent="#creatorsTab"
                  >
                    <div className="pt-3 fs-4">
                      Payment for your sales will be deposited to your marketplace wallet. You can choose to withdraw it through bank transfer or cryptocurrency
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Faq;
//@nftmarket.africa 2022
