import React from 'react';
import avatar from '../../../assets/images/avatar/user.png';
import {useSelector} from 'react-redux';
import Avatar from '../../../component/ui_kits/avatar';
// import {disconnectAccount} from '../../../features/auth/authSlice';
import {ProfileModal} from '../../../component/modal';

const ProfileHeader =(props)=> {
  // let dispatch = useDispatch()
  let user = useSelector(state => state.user.value)

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }
  return (
    <>
    <div className="pt-15 bg-white">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-lg-10 col-md-12 col-12">
            <div className="card p-3 rounded-4">
              <div
                className="pt-20 rounded-4 bg-brand-1"
                style={{
                  backgroundImage: `url(${user.profile.banner})`,
                  backgroundRepeat:'no-repeat',
                  backgroundSize: "cover"
                }}
    />
              <div className="d-flex align-items-end justify-content-between bg-white px-4 pt-2 pb-4 rounded-none">
                <div className="d-flex align-items-center">
                  <div className="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                  {
                    user.profile.account?
                    <Avatar data={{account:user.profile.account}} size="avatar-xl bg-white"/>
                    :
                      <img
                        src={avatar}
                        alt="avatar"
                        className={`rounded-circle border-white border border-4 position-relative avatar-xl`}
                      />
                  }
                  </div>
                  <div className="lh-1">
                    <h2 className="mb-0">
                      {user.profile ? (user.profile.full_name || sliceCharacter(user.profile.account)) : '0x...'}
                      <a
                        href="#/"
                        className="text-decoration-none"
                        data-bs-toggle="tooltip"
                        data-placement="top"
                        title="Beginner"
                      >
                      </a>
                    </h2>
                  </div>
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  <button
                    className="btn btn-sm rounded-4 btn-white text-center nofocus me-lg-2 d-lg-none"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target={`#profileModal`}
                    aria-controls={`profileModal`}>
                    <i className="fe fe-more-vertical fs-3"/>
                  </button>
                  <ProfileModal profile={user.profile}/>
                {
                  user.profile && user.profile.profile_verified?
                  <a
                    href="/draft"
                    className="btn btn-light brand-button d-none d-md-block"
                  >
                    List Item
                  </a>
                  :
                  <a
                    href="/draft"
                    className="btn btn-light brand-button d-none d-md-block"
                    disabled={true}
                  >
                    List Item
                  </a>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  )
}
export default ProfileHeader;
//@ NFTmarket.africa 2022
