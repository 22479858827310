// import React from 'react';
// import {useSelector} from 'react-redux';
// import {createPost} from '../../features/post/postSlice';
//
// export default function PostModal(props){
//   // let dispatch = useDispatch()
//   let post = useSelector(state => state.post.value)
  // const [nftDetails, setNftDetails] = useState({
  //   contract_address:null,
  //   token_id:null
  // });

  // let handleInput =(e)=> {
  //   let {name, value} = e.target;
  //   setNftDetails({
  //     ...nftDetails,
  //     [name]:value
  //   })
  // }
  // let handleSubmit=()=>{
  //   if(!window.ethereum){
  //     window.location.href = 'https://metamask.app.link/dapp/nftmarket.africa';
  //   }else{
  //     dispatch(createPost(nftDetails))
  //   }
  // }
//   return (
//     <div
//       className="offcanvas offcanvas-top rounded-bottom-3 border-0"
//       tabIndex={-1}
//       id="postModal"
//       aria-labelledby="postModal"
//     >
//       <div className="offcanvas-header">
//         <h5 className="offcanvas-title" id="postModalLabel">
//             {post.error ||"Post NFT"}
//         </h5>
//         <button
//           type="button"
//           className="btn-close text-reset"
//           data-bs-dismiss="offcanvas"
//           aria-label="Close"
//         >
//           <i className="fe fe-x-circle " />
//         </button>
//       </div>
//       <div className="container">
//         <div className="offcanvas-body mb-6">
//           <div className="row justify-content-center align-items-center">
//             <div className="col-12 col-lg-5">
//               <div className="d-grid col-12">
//                 <a
//                 href="/gallery"
//                 className="btn btn-light btn-primary btn-lg d-flex justify-content-start align-items-center"
//                 >
//                   <i className="fe fe-image"></i>
//                   &nbsp;&nbsp;<span>Select from Gallery</span>
//                 </a>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

//@NFTmarket.africa 2022

//
//
//
//
//
//
//
import React, {useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {createPost} from '../../features/post/postSlice';

export default function PostModal(props){
  let dispatch = useDispatch()
  let post = useSelector(state => state.post.value)
  const [nftDetails, setNftDetails] = useState({
    contract_address:null,
    token_id:null
  });

  let handleInput =(e)=> {
    let {name, value} = e.target;
    setNftDetails({
      ...nftDetails,
      [name]:value
    })
  }
  let handleSubmit=()=>{
    if(!window.ethereum){
      window.location.href = 'https://metamask.app.link/dapp/nftmarket.africa';
    }else{
      dispatch(createPost(nftDetails))
    }
  }
  return (
    <div
      className="offcanvas offcanvas-top rounded-bottom-3 border-0"
      tabIndex={-1}
      id="postModal"
      aria-labelledby="postModal"
      style={{height:'50vh'}}
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="postModalLabel">
            {post.error ||"Post NFT"}
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fe fe-x-circle " />
        </button>
      </div>
      <div className="container">
        <div className="offcanvas-body mb-6">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-5">
              <div className="d-grid col-12">
                <>
                  {/* large input */}
                  <div className="mb-3">
                    <input
                      onChange={handleInput}
                      type="text"
                      name="contract_address"
                      className="form-control form-control-lg"
                      placeholder="Contract Address"
                    />
                  </div>
                  {/* normal input */}
                  <div className="mb-3">
                    <input
                    onChange={handleInput}
                    type="text"
                    name="token_id"
                    className="form-control form-control-lg"
                    placeholder="Token ID"
                    />
                  </div>
                  {
                    post.creating?
                    <button className="btn btn-primary btn-lg" type="button" disabled>
                      <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      {" "}Importing...
                    </button>
                    :
                    <button
                    disabled={!nftDetails.contract_address || !nftDetails.token_id}
                    className="btn btn-primary btn-lg"
                    onClick={handleSubmit}
                    type="button">
                      Post
                    </button>
                  }
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

//@NFTmarket.africa 2022
