import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import Cookies from 'js-cookie';
import {likePost} from '../../features/post/postSlice';
import postApi from '../../service/api/post_api';
import {getPrice, Amplitude} from '../../features/tools';
import {utility_prices} from '../../store/data';
import {
  UtilityModal,
  PurchaseModal
} from '../modal';
const api = new postApi();
const EventTracker = new Amplitude();

export default function DefaultCardFooter(props){
/*******************************/
  let dispatch = useDispatch()
  let {profile} = useSelector( state => state.user.value)
  let [data, setData] = useState({})
  let [properties, setProperties] = useState({
    toggled:false,
    like:false,
    uniqueKey:parseInt(Math.random(1000)*5000000).toString()
  })
/*******************************/
  let [description, setDescription] = useState({
    descriptionCount:0,
    showDescriptionDropdown:false,
    smartDescription:'',
    completeDescription:''
  })
/*******************************/
  let {
    alchemy_data,
    account,
    utilities
  } = props.data
  let {
    metadata
  } = alchemy_data;
/*******************************/
  let handleGetDescription =(description)=> {
    let descriptionCount = description?description.length:0
    let showDescriptionDropdown = descriptionCount>100?true:false
    let smartDescription = description?description.slice(0,100):''
    let completeDescription = description&&descriptionCount>100?description.slice(100, descriptionCount):''
    return {
      descriptionCount,
      showDescriptionDropdown,
      smartDescription,
      completeDescription
    }
  }
/*******************************/
  useEffect(()=>{
    if(metadata){
      let parsed_metadata = JSON.parse(metadata)
      setData( prev => {
        return {
          ...prev,
          ...parsed_metadata
        }
      })
    }else{
      console.log('media not detected');
    }
  },[metadata])
/*******************************/
  useEffect(()=>{
    setDescription(handleGetDescription(JSON.parse(alchemy_data.metadata).description))
  },[alchemy_data.metadata])
/*******************************/
  useEffect(()=>{
    let token = Cookies.get('c-token')
    let data = {
      post_id:props.data.id,
      token
    }
    if(token && data.post_id){
      api.getLikes(data).then((response)=>{
        setProperties(function(prev){
          return {...prev, like:response.data.liked}
        })
      }).catch((err)=>{
        console.error(err);
      })
    }

    return function cleanUp(){
      setProperties({})
    }
  },[props.data.id])
/*******************************/
  let handleToggle =()=> {
    setProperties({
      ...properties,
      toggled:!properties.toggled
    })
  }
/*******************************/
  let handleLikeToggle=()=>{
    switch (properties.like) {
      case true:
        setProperties({
          ...properties,
          like:false
        })
        dispatch(likePost({type:'dislike', post_id:props.data.id}))
        break;
      case false:
        setProperties({
          ...properties,
          like:true
        })
        dispatch(likePost({type:'like', post_id:props.data.id}))
        break;
      default:
    }
  }
/*******************************/
  return (
    <div className="card-footer border-0 bg-white py-6 position-relative">
      <div className="lh-1 d-flex d-inline-block justify-content-between align-items-center">
        <div>
          <span className="fs-3 clickable" onClick={handleLikeToggle}>
          <button
            className="btn btn-white btn-icon border-0 rounded-circle"
            type="button"
            onClick={()=> EventTracker.logEvent('LIKED_AN_ITEM')}
          >
          {
            properties.like?
            <i className="text-danger fas fa-heart fs-3"></i>
            :
            <i className="bi bi-heart fs-3"></i>
          }
          </button>
          </span>
          <span className="fs-3 m-6">
            <button
              className="btn btn-white btn-icon border-0 rounded-circle"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target={`#commentModal${props.data.id}`}
              aria-controls={`commentModal${props.data.id}`}
              onClick={()=> EventTracker.logEvent('VIEWED_ITEM_UTILITY')}
            >
              <i className="bi bi-gift fs-3"></i>
            </button>
            <UtilityModal
            data={props.data}
            id={`commentModal${props.data.id}`}
            />
          </span>
        </div>
        <div>
          <span className="fs-4 text-muted">
          {
            props.data.is_sold?
            <button className="btn btn-success btn-sm rounded text-white"><i className="fe fe-check-circle text-white"/> Sold</button>
            :
            account===profile.account?
            <button
            type="button"
            className={`btn btn-sm rounded ${props.data.sponsored?'btn-light':'btn-primary'}`}
            disabled={props.data.promoted}
            onClick={()=> EventTracker.logEvent('ITEM_BOOST')}
            >
            {
              props.data.sponsored?
              "Sponsored"
              :
              "Boost"
            }
            <i className="bi bi-lightning-charge-fill ms-1"></i>
            </button>
            :
            <a
            className="btn btn-primary btn-sm rounded"
            href={`/buy/${props.data.id}`}
            onClick={()=> EventTracker.logEvent('CLICKED_ON_BUY')}
            >
              Buy
             <i className="fe fe-shopping-bag ms-1"></i>
            </a>
          }
            <PurchaseModal
            data={props.data}
            id={`purchaseModal${props.data.id}`}
            />
          </span>
        </div>
      </div>
      {
        props.showFooterDesc?
        <>
          <div className="pt-6">
            <div className="h4">
            <Link to={`/p/${props.data.id}`} className="fw-bold">{data.name}</Link>
            </div>
            <div className="h6">
              <span>
                {description.smartDescription||'Description not available'}
              </span>
              {
                description.showDescriptionDropdown?
                <>
                {
                  !properties.toggled?
                  <span>...
                    <a
                    data-bs-toggle="collapse"
                    href={`#see${properties.uniqueKey}`}
                    role="button"
                    aria-expanded="false"
                    aria-controls={`see${properties.uniqueKey}`}
                    className="text-muted clickable"
                    onClick={handleToggle}
                    >
                    {" "}more
                    </a>
                  </span>
                  :
                  null
                }
                <span className="collapse" id={`see${properties.uniqueKey}`}>
                    {description.completeDescription}
                    <span>[-]
                      <a
                      data-bs-toggle="collapse"
                      href={`#see${properties.uniqueKey}`}
                      role="button"
                      aria-expanded="false"
                      aria-controls={`see${properties.uniqueKey}`}
                      className="text-muted clickable"
                      onClick={handleToggle}
                      >
                      {" "}show less
                      </a>
                    </span>
                </span>
                </>
                :
                null
              }
            </div>
          </div>
          <div>
            <h5 className="fw-bold">
              <span className="text-success">
                {getPrice(utility_prices, (utilities||{}), props.data.amount)}
              </span>
            </h5>
          </div>
        </>
        :
        null
      }
    </div>
  )
}
//@NFTmarket.africa 2022
