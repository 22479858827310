import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import checkmark from '../../assets/images/tools/checkmark.svg';
import avatar from '../../assets/images/avatar/user.png';
import userApi from '../../service/api/user_api';
const api = new userApi();

export default function PostCardHeader(props){
  let [profile, setProfile] = useState({})
  let user = useSelector( state => state.user.value)
  useEffect(()=>{
    api.retrieveProfile(props.data.account).then((response)=>{
      setProfile(response.data);
    }).catch((err)=>{
      console.error(err);
    })

    return function cleanUp(){
      setProfile({})
    }
  },[props.data.account])

  let copyLink =(e)=> {
    e.preventDefault()
    const body = document.querySelector('body');
    const area = document.createElement('textarea');
    body.appendChild(area);
    area.value = `${window.location.origin}/p/${props.data.id}`;
    area.select();
    document.execCommand('copy');
    body.removeChild(area);
  }

  return (
    <div className="position-relative card-header bg-white py-3 border-0 d-flex justify-content-between align-items-center" style={{zIndex:1}}>
      <div className="">
        <Link to={`/@/${profile.username}`}>
          <img
            src={profile.avatar|| avatar}
            alt="avatar"
            className="rounded-circle avatar-md"
          />

          {
            profile.profile_verified?
            <span
              className="position-absolute mt-2 ms-n3"
              data-bs-toggle="tooltip"
              data-placement="top"
              title="Verifed"
            >
              <img
                src={checkmark}
                alt="checkmark"
                height={25}
                width={25}
              />
            </span>
            :
            null
          }

        <span className="inline position-relative px-3 h6 fw-bold">
          @{profile.username}
        </span>
        </Link>
      </div>

      {
        !props.noOptions?
        <div>
          <span className="dropdown">
            <a
              className="text-decoration-none btn-icon btn-white rounded-circle text-muted"
              href="/"
              role="button"
              id="shareDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i className="fe fe-more-horizontal fs-3"/>
            </a>
            <span className="dropdown-menu" aria-labelledby="shareDropdown">
              <span className="dropdown-header">More</span>
              <a className="dropdown-item"
                href={`https://twitter.com/intent/tweet?url=${window.location.origin}/p/${props.data.id}&via=nftmarketafrica&text=Check out this NFT "${props.metadata?props.metadata.name:""}"`}
                target="blank_"
                >
                <i className="fab fa-twitter dropdown-item-icon" />
                Tweet
              </a>
              <a className="dropdown-item" href="/" onClick={copyLink}>
                <i className="fas fa-copy dropdown-item-icon" />
                Copy Link
              </a>
              {
                user.profile && user.profile.account === props.data.account?
                <a className="dropdown-item" href={`/draft/${props.data.id}`}>
                  <i className="fas fa-edit dropdown-item-icon" />
                  Edit Item
                </a>
                :
                null
              }
            </span>
          </span>
        </div>
        :
        null
      }

    </div>
  )
}
//@NFTmarket.africa 2022
