import React from 'react';
import Avatar from '../ui_kits/avatar';
import Like from '../ui_kits/like';
import {getPrice, Amplitude} from '../../features/tools';
import {utility_prices} from '../../store/data';
import Media from './media';
let EventTracker = new Amplitude();

export default function NftCard(props){
  return (
    <div className="card border border-light border-3 shadow-none rounded-4">
      <div className="card-body">
        <div className="col-12 d-flex justify-content-center">
        {
          props.data?
          <Media data={props.data} resolution={{width:'300px', maxHeight:'300px', objectFit:'cover', ...props.resolution}} />
          :
          null
        }
        </div>
        <div className="d-grid pt-3">
          <div className="col-12">
            <h3
            className="col-12 fw-bold fs-3 text-truncate"
            style={{width:"90%"}}>
              <a
              href={`/p/${props.data.id}`}
              >
              {props.data.alchemy_data&&props.data.alchemy_data.metadata? JSON.parse(props.data.alchemy_data.metadata).name : "..."}
              </a>
            </h3>
          </div>
          <div className="d-flex justify-content-between align-items-center">
            <div className="py-2">
              <span className="small opacity-75">Price</span>
              <h3 className="fs-4 fw-bold">
                {getPrice(utility_prices, (props.data.utilities||{}), props.data.amount)}
              </h3>
            </div>
            <Like data={{id:props.data.id}}/>
          </div>
        </div>
        <hr/>
      </div>
      <div className="card-footer card-footer-4 d-flex justify-content-between border-0 my-0">
        <div className="justify-content-between align-items-center g-0">
          <Avatar data={{account:props.data.account}} {...props} />
        </div>
        <div className="p-2" style={{zIndex:1, color:"#2DA46B"}}>
          <a
          href={`/p/${props.data.id}`}
          className="fw-bolder fs-5"
          onClick={()=> EventTracker.logEvent('VIEWED_CATEGORY_ITEM')}
          style={{color:"#2DA46B"}}
          >
          View Item
          </a>
        </div>
      </div>
    </div>
  )
}





// <img
// width={"100%"}
// src={"https://lh3.googleusercontent.com/cg4Q8iKuwIrrQ0LErYvijFPRlaYR--WYeJ9nj_Nlp94tJAAPNP-g0HzuzwarXSEmaMEJjZWmMzamiKNqMuUsU87gWwAFeM2u_sUrgQ"}
// className="rounded-4 card-img-top"
// />


// {/* Card */}
// <div className="card shadow-none mb-3 h-100">
//   <PostCardContent data={item} resolution={{maxWidth:'350px', height:'400px'}} />
//   {/* Card Body */}
//   <div className="card-body px-1 py-0">
//     <h4 className="mb-2 text-truncate-line-2">
//       <a href="course-single.html" className="text-inherit fw-bold">
//         {item.alchemy_data&&item.alchemy_data.metadata? JSON.parse(item.alchemy_data.metadata).name : "..."}
//       </a>
//     </h4>
//     {/* List */}
//     <div className="d-flex justify-content-between">
//       <div className="py-2">
//         <p className="fs-5 fw-bold">
//           {getPrice(utility_prices, (item.utilities||{}), item.amount)}
//         </p>
//       </div>
//       <Like data={{id:item.id}}/>
//     </div>
//   </div>
//   {/* Card Footer */}
//   <div className="card-footer d-flex justify-content-between align-items px-0">
//     <div className="justify-content-between align-items-center g-0">
//       <Avatar data={{account:item.account}} showUsername={true}/>
//     </div>
//     <div className="p-2" style={{zIndex:1}}>
//       <a href={`/p/${item.id}`} className="fw-bold fs-5 text-success" onClick={()=> EventTracker.logEvent('VIEWED_CATEGORY_ITEM')}>View Item</a>
//     </div>
//   </div>
// </div>


//@nftmarket africa 2022
