import React, {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getUserProfile} from '../features/user/userSlice';
import {getGalleries} from '../features/gallery/gallerySlice';
// import blank from '../assets/images/tools/blank_image.png';
import {LandingWrapper} from './wrapper';
// import NftGalleryCard from '../component/ui_kits/nft-gallery-card';
import GalleryApi from '../service/api/gallery_api';
import {Amplitude} from '../features/tools';
import CopyText from '../component/ui_kits/ui_buttons/copy';
// import {
//   AddItemModal
// } from '../component/modal';
import Cookie from 'js-cookie';
import Cryptr from 'cryptr';
let api = new GalleryApi();
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Gallery",
  meta_title:"NFT Market Africa - Gallery",
  description:"Your NFT gallery"
}

// let sliceCharacter=(char)=>{
//   return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
// }


const Gallery =(props)=> {
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_GALLERY')
    }
  },[pageLoaded, setPageLoaded])

  let dispatch = useDispatch()
  // let navigate = useNavigate()
  let [view, setView] = useState('options')
  let [private_keys, setPrivateKey] = useState({
    loading:false,
    decrypting:false
  })
  let [currentGallery, setCurrentGallery] = useState(0)
  // let user = useSelector(state => state.user.value)
  let galleries = useSelector( state => state.gallery.value)
  let {data, loading} = galleries;


  useEffect(()=>{
    dispatch(getUserProfile())
    dispatch(getGalleries())
  },[dispatch])

  // useEffect(()=>{
  //   if(data && data.length){
  //     dispatch(getGalleryItems(data[currentGallery]['id']))
  //   }
  // },[dispatch, data, currentGallery])

  let handleToggleGallery =(index)=>{
    setCurrentGallery(index)
  }

  let handleGetPrivateKeys =()=> {
    let token = Cookie.get('c-token');
    if(token){
      setPrivateKey({...private_keys, loading:true})
      let data_ = {
        gallery_id:data[currentGallery]['id'],
        token
      }
      api.getPrivateKeys(data_).then((response)=>{
        EventTracker.logEvent('GET_SECRET_KEY_SUCCESS')
        let {encrypted} = response.data;
        setPrivateKey({...private_keys, encrypted, loading:false})
        setView('private_key')
      }).catch((err)=>{
        EventTracker.logEvent('GET_SECRET_KEY_FAILURE')
        setPrivateKey({...private_keys, loading:false})
        console.error(err);
      })
    }
    EventTracker.logEvent('GET_SECRET_KEY')
  }

  let handleGetMnemonic =()=> {
    let token = Cookie.get('c-token');
    if(token){
      setPrivateKey({...private_keys, loading_mnemonic:true})
      let data_ = {
        gallery_id:data[currentGallery]['id'],
        token
      }
      api.getMnemonic(data_).then((response)=>{
        EventTracker.logEvent('GET_SECRET_KEY_SUCCESS')
        let {encrypted} = response.data;
        setPrivateKey({...private_keys, encrypted, loading_mnemonic:false})
        setView('private_key')
      }).catch((err)=>{
        EventTracker.logEvent('GET_SECRET_KEY_FAILURE')
        setPrivateKey({...private_keys, loading_mnemonic:false})
        console.error(err);
      })
    }
    EventTracker.logEvent('GET_SECRET_KEY')
  }

  let handleDecryptKeys = async()=> {
    EventTracker.logEvent('VERIFY_GALLERY_PIN')
    await setPrivateKey({...private_keys, decrypting:true})
    try{
      const cryptr = new Cryptr(private_keys.secret_key);
      const decrypted = await cryptr.decrypt(private_keys.encrypted);
      if(decrypted){
        EventTracker.logEvent('VERIFY_GALLERY_PIN_SUCCESS')
        return setPrivateKey({...private_keys, decrypted})
      }
      EventTracker.logEvent('VERIFY_GALLERY_PIN_FAILURE')
      return setPrivateKey({...private_keys,decrypted:false, error:'Invalid PIN'})
    }catch(err){
      EventTracker.logEvent('VERIFY_GALLERY_PIN_FAILURE')
      return setPrivateKey({...private_keys,decrypted:false, error:'Invalid PIN'})
    }
  }

  let handleSecretInput =(e)=> {
    let {value} = e.target;
    if(value){
      return setPrivateKey({...private_keys, secret_key:value})
    }else{
      return setPrivateKey({...private_keys, secret_key:null})
    }
  }

  let handleReset =()=> {
    setPrivateKey({})
    setView('options')
  }


  let copyKeys =(e)=> {
    const element = document.getElementById('private-key');
    element.select();
    document.execCommand('copy');
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
    <div className="pt-15 bg-white min-vh-75">
      <div className="container">
        <div className="row justify-content-center align-items-center">
          <div className="col-12 col-lg-10">
            <span className="fs-3 fw-bold">
              <Link to="/dashboard">
                <i className="fe fe-arrow-left fw-bolder px-3"/>
              </Link>
            </span>
          </div>
        </div>
        <div className="row justify-content-center align-items-center">
          {
            galleries.data&&galleries.data.length?
            galleries.data.map((gallery, i) =>
            <div key={i} className="col-12 col-lg-10 py-3">
              <div className="card bg-light shadow-none rounded-4">
                <div className="card-body d-flex justify-content-between">
                  <div className="py-3">
                    <span className="fs-5 fw-bold">Your {gallery.chain} NFT Gallery</span>
                    <h3 className="display-4 py-2">{gallery.name}</h3>
                    <CopyText text={gallery.address} type="button"/>
                  </div>
                  <div className="py-3">
                    <div>
                    <>
                      <button
                        type="button"
                        className="btn btn-light rounded-4 btn-sm"
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModalCenter"
                        onClick={()=>handleToggleGallery(i)}
                      >
                        <i className="fe fe-more-vertical fs-3"/>
                      </button>
                      {/* Modal */}
                      <div
                        className="modal bg-brand-1 text-primary"
                        id="exampleModalCenter"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalCenterTitle"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                          <div className="modal-content rounded-4 border-0 shadow-sm">
                            <div className="modal-header">
                              <h5 className="modal-title" id="exampleModalCenterTitle">
                                Gallery Options
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={handleReset}
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                            {
                              view === "private_key"?
                              private_keys.decrypted?
                              <div className="d-grid gap-2">
                              <label>Copy your Secret Keys</label>
                                <input
                                key="8382"
                                className="form-control brand-button"
                                type="text"
                                placeholder="Copy Keys"
                                defaultValue={private_keys.decrypted}
                                id="private-key"
                                />
                                <button className="btn btn-light my-1 brand-button" type="button" onClick={copyKeys}>
                                  Copy Secret
                                </button>
                                <button className="btn btn-light my-1 brand-button" type="button" onClick={handleReset}>
                                   Back
                                </button>
                              </div>
                              :
                              <form className="d-grid gap-2">
                              <label>Enter your 6 digit PIN</label>
                                <input
                                key="77389"
                                className="form-control brand-button"
                                type="password"
                                placeholder="* * * * * *"
                                maxLength="10"
                                onChange={handleSecretInput}
                                autoComplete="on"
                                />
                                {
                                  (private_keys.decrypting)?
                                  <button className="btn btn-light my-1 brand-button">
                                    Decrypting...
                                  </button>
                                  :
                                  <>
                                  <button className="btn btn-light my-1 brand-button" disabled={!private_keys.secret_key || private_keys.decrypting} type="button" onClick={handleDecryptKeys}>
                                     Confirm PIN
                                  </button>
                                  <button className="btn btn-light my-1 brand-button" type="button" onClick={handleReset}>
                                     Back
                                  </button>
                                  <small className="text-danger">{private_keys.error}</small>
                                  </>
                                }
                              </form>
                              :
                              (view==='info')?
                              <div className="d-grid gap-2">
                                <div className="card shadow-none">
                                  <div className="card-body">
                                    <p className="fs-5">Name: {data[currentGallery]['name']}</p>
                                    <p className="fs-5">Blockchain: {data[currentGallery]['chain']}</p>
                                    <p className="fs-5">Address: {data[currentGallery]['address']}</p>
                                    <p className="fs-5">{data[currentGallery]['active'] ? 'Active': 'Not Active'}</p>
                                  </div>
                                </div>
                                <button className="btn btn-light my-1 brand-button" type="button" onClick={handleReset}>
                                   Back
                                </button>
                              </div>
                              :
                              <div className="d-grid gap-2">
                                <button  onClick={()=> setView('info')} className="btn btn-light my-1 brand-button">
                                  Gallery Information
                                </button>
                                <a href="/gallery/create" className="btn btn-light my-1 brand-button">
                                  Create New Gallery
                                </a>
                                {
                                  private_keys.loading?
                                  <button className="btn btn-primary my-1 brand-button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {" "}Please wait...
                                  </button>
                                  :
                                  <button className="btn btn-light my-1 brand-button" type="button" onClick={handleGetPrivateKeys}>
                                    Get Private Keys
                                  </button>
                                }
                                {
                                  private_keys.loading_mnemonic?
                                  <button className="btn btn-primary my-1 brand-button" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    {" "}Please wait...
                                  </button>
                                  :
                                  <button className="btn btn-light my-1 brand-button" type="button" onClick={handleGetMnemonic}>
                                    Get 12 word recovery phrase
                                  </button>
                                }
                              </div>
                            }
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            )
            :
            (loading)?
            <div className="col-12 col-lg-10 py-3">
              <div className="card bg-light shadow-none rounded-4">
                <div className="card-body">
                  <div className="py-3">
                    <span className="fs-5 fw-bold">Loading Gallery</span>
                    <h3 className="display-4 py-2">Gallery</h3>
                    <button
                      disabled
                      className="btn btn-primary brand-button">
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only"></span>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            :
            null
          }
          <div className="col-12 col-lg-10 py-3">
            <div className="card bg-brand-1 shadow-none rounded-4">
              <div className="card-body">
                <div className="py-3">
                  <a href="/gallery/create" className="btn btn-white brand-button">
                    Create Gallery
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </LandingWrapper>
  )
}
export default Gallery;

//@ NFTmarket.africa 2022
