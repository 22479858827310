import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import UserApi from '../../service/api/user_api';
import Cookies from 'js-cookie';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
import {disconnectAccount} from '../auth/authSlice';
let api = new UserApi();
Notify.init({
  position:'left-bottom'
})

export const joinWaitList = createAsyncThunk(
  'user/JOIN_WAITLIST',
  async(data, thunkAPI)=>{
    try{
      let response = await api.joinWaitList(data);
      return response.data;
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue(err.response.data, {});
      }else{
        err.error = "Network Error";
        throw thunkAPI.rejectWithValue(err);
      }
    }
  }
)
export const getProfileByUsername = createAsyncThunk(
  'user/RETRIEVE_PROFILE',
  async(username, thunkAPI)=>{
    try{
      let response = await api.getProfileByUsername(username)
      return response.data;
    }catch(err){
      if(!err.response || !err.response.data){
        Notify.failure("Network Error");
        throw thunkAPI.rejectWithValue('Network error');
      }else{
        Notify.failure(err.response.data.error);
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }
    }
  }
)
export const getUserProfile = createAsyncThunk(
  'user/GET_PROFILE',
  async(data, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let response = await api.getUserProfile({data, token})
        return response.data;
      }
    }catch(err){
      thunkAPI.dispatch(disconnectAccount())
      if(err.response && err.response.data){
        // thunkAPI.dispatch(disconnectAccount())
        throw thunkAPI.rejectWithValue({error:err.response.data.error})
      }
      if(err.message){
        throw thunkAPI.rejectWithValue({error:err.message})
      }else{
        Notify.failure("Network error");
        throw thunkAPI.rejectWithValue({error:"Network error"})
      }
    }
  }
)
export const getUserGallery= createAsyncThunk(
  'user/GET_GALLERY',
  async(arg, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let response = await api.getUserGallery({token})
        return response.data;
      }else{
        Notify.failure("Token is required");
        throw thunkAPI.rejectWithValue({error:'Token is required'})
      }
    }catch(err){
      Cookies.remove('c-token');
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue({error:err.response.data.error})
      }
      if(err.message){
        throw thunkAPI.rejectWithValue({error:err.message})
      }else{
        Notify.failure("Network error");
        throw thunkAPI.rejectWithValue({error:"Network error"})
      }
    }
  }
)
export const getUserMetrics= createAsyncThunk(
  'user/GET_METRICS',
  async(arg, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let response = await api.getMetrics({token})
        return response.data;
      }else{
        throw Error('Auth required')
      }
    }catch(err){
      if(err.response && err.response.data){
        throw thunkAPI.rejectWithValue({error:err.response.data.error})
      }
      if(err.message){
        throw thunkAPI.rejectWithValue({error:err.message})
      }else{
        Notify.failure("Network error");
        throw thunkAPI.rejectWithValue({error:"Network error"})
      }
    }
  }
)
export const updateProfile = createAsyncThunk(
  'user/UPDATE_PROFILE',
  async(data, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let response = await api.updateProfile({data, token})
        Notify.success(response.data.message);
        return response.data;
      }else{
        Notify.failure("Token is required");
        thunkAPI.dispatch(disconnectAccount())
        throw thunkAPI.rejectWithValue({error:'Token is required'})
      }
    }catch(err){
      if(!err.response || !err.response.data){
        Notify.failure("Network Error");
        throw thunkAPI.rejectWithValue({error:"Network Error"})
      }else{
        Notify.failure(err.response.data.error);
        throw thunkAPI.rejectWithValue({error:err.response.data.error})
      }
    }
  }
)

export const requestVerification = createAsyncThunk(
  'user/REQUEST_VERIFICATION',
  async(data, thunkAPI)=>{
    try{
      let token = Cookies.get('c-token');
      if(token){
        let response = await api.requestVerification({token})
        Notify.success(response.data.message);
        thunkAPI.dispatch(getUserProfile());
        return response.data;
      }else{
        thunkAPI.dispatch(disconnectAccount())
        throw Error('Token is required')
      }
    }catch(err){
      if(!err.response || !err.response.data){
        thunkAPI.dispatch(disconnectAccount())
        Notify.failure("Network Error");
        throw thunkAPI.rejectWithValue({error:"Network Error"})
      }else{
        Notify.failure(err.response.data.error);
        throw thunkAPI.rejectWithValue({error:err.response.data.error})
      }
    }
  }
)

export const userSlice = createSlice({
  name:"user",
  initialState:{
    value:{
      metrics:{},
      profile:'',
      published:[],
      drafts:[],
      sold:[],
      gallery:[],
      loading:false,
      updating:false,
      verifying:false,
      email:'',
      joining:false,
      subscribed:false,
      error:null
    }
  },
  reducers:{
  },
  extraReducers:(builder)=>{
    builder.addCase(joinWaitList.pending, (state, action)=> {
      state.value = {
        ...state.value,
        joining:true,
        email:action.meta.arg.email,
        error:null
      }
    })
    builder.addCase(joinWaitList.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        subscribed:true,
        joining:false,
        message:action.payload,
        error:null
      }
    })
    builder.addCase(joinWaitList.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        subscribed:false,
        joining:false,
        error:action.payload.error
      }
    })
    builder.addCase(getUserProfile.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading:true,
        error:null
      }
    })
    builder.addCase(getUserProfile.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        profile:action.payload && action.payload.profile?action.payload.profile:'',
        published:action.payload && action.payload.published?action.payload.published:[],
        drafts:action.payload && action.payload.drafts?action.payload.drafts:[],
        sold:action.payload && action.payload.sold?action.payload.sold:[],
        loading:false,
        error:null
      }
    })
    builder.addCase(getUserProfile.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        error:action.payload.error
      }
    })
    builder.addCase(getUserMetrics.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading:true,
        error:null
      }
    })
    builder.addCase(getUserMetrics.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        metrics:action.payload,
        error:null
      }
    })
    builder.addCase(getUserMetrics.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        error:action.payload.error
      }
    })
    builder.addCase(getUserGallery.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading:true,
        error:null
      }
    })
    builder.addCase(getUserGallery.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        gallery:action.payload,
        error:null
      }
    })
    builder.addCase(getUserGallery.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        error:action.payload.error
      }
    })
    builder.addCase(updateProfile.pending, (state, action)=> {
      state.value = {
        ...state.value,
        updating:true,
        error:null
      }
    })
    builder.addCase(updateProfile.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        updating:false,
        message:action.payload.message,
        error:null
      }
    })
    builder.addCase(updateProfile.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        updating:false,
        error:action.payload.error
      }
    })
    builder.addCase(requestVerification.pending, (state, action)=> {
      state.value = {
        ...state.value,
        verifying:true,
        error:null
      }
    })
    builder.addCase(requestVerification.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        verifying:false,
        error:null
      }
    })
    builder.addCase(requestVerification.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        verifying:false,
        error:action.payload.error
      }
    })
    builder.addCase(getProfileByUsername.pending, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving_profile:true,
        error:false
      }
    })
    builder.addCase(getProfileByUsername.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving_profile:false,
        retrieved_profile:action.payload,
        error:false
      }
    })
    builder.addCase(getProfileByUsername.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving_profile:false,
        error:action.payload
      }
    })
  }
})
export const {enter_action_here} = userSlice.actions;
export default userSlice.reducer;

//@NFTmarket.africa 2022
