import axios from 'axios';
import {baseURL} from '../../config';

let walletClient = axios.create({
  baseURL,
  timeout:30000
})

class WalletApi {
  async activateWallet(data){
    return await walletClient.post(`/wallets/activate/`, {}, {
      headers:{
        "x-token":data.token
      }
    });
  }

  async getWallets(data){
    return await walletClient.get(`/wallets`, {
      headers:{
        "x-token":data.token
      }
    });
  }


  async createTransaction(data){
    return await walletClient.post(`/transactions/create`, {request:data.request}, {
      headers:{
        "x-token":data.token,
        "x-sign":data.headers.signature,
        "m-hash":data.headers.message
      }
    });
  }

  async getTransaction(data){
    return await walletClient.get(`/transactions/${data.transaction_id}`, {
      headers:{
        "x-token":data.token
      }
    });
  }

  async getTransactions(data){
    return await walletClient.get(`/transactions`, {
      headers:{
        "x-token":data.token
      }
    });
  }
}


export default WalletApi;
//@NFTmarket.africa 2022
