import React, {useEffect, useState} from 'react';
import PostCardHeader from './card_header';
import PostCardContent from './card_content';
import DefaultCardFooter from './default_card_footer';
import TransferFooter from './transfer_card_footer';

  const Post =(props)=> {
    let [data, setData] = useState({});

    useEffect(()=>{
      if(props.data&&props.data.alchemy_data){
        let {alchemy_data} = props.data;
        let {metadata} = alchemy_data;
        metadata = JSON.parse(metadata)
        setData(metadata)
      }
    },[props.data])
  return (
      <div id={props.data.id} className="card rounded-3">
        <PostCardHeader {...props} metadata={data}/>
        <PostCardContent {...props}/>
        {
          !props.noFooter?
            props.layout === 'transfer'?
            <TransferFooter {...props} metadata={data} />
            :
            <DefaultCardFooter{...props} showFooterDesc={props.showFooterDesc} />
          :
          <div className="py-3"/>
        }
      </div>
  )
  }
  export default Post;
//@ NFTmarket.africa 2022
