import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import checkoutApi from '../../service/api/order_api';
import { Notify } from 'notiflix/build/notiflix-notify-aio';
Notify.init({position:'left-bottom'})
let api = new checkoutApi();

let checkoutConfig = {
  // host:'http://localhost:3000/id'
  host:'https://pay.nftmarket.africa/id'
}

export const createCharge = createAsyncThunk(
  'checkout/CREATE_CHARGE',
  async(order, thunkAPI)=>{
    let token = Cookies.get('c-token')
    let data = {
      order,
      token
    }
    try{
      let response = await api.createCharge(data);
      if(response){
        let {charge_id} = response.data
        window.location.href=`${checkoutConfig.host}/${charge_id}`
        return charge_id
      }
    }catch(err){
      if(!err.response || !err.response.data){
        Notify.failure('Network error');
        throw thunkAPI.rejectWithValue('Network error');
      }else{
        Notify.failure(err.response.data.error);
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }
    }
  }
)


export const createOrder = createAsyncThunk(
  'checkout/CREATE_ORDER',
  async(order, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      let data = {
        token,
        order
      }
      try{
        let response = await api.createOrder(data);
        if(response){
          let {order_id} = response.data
          window.location.href=`/order/${order_id}`
          return order_id
        }
      }catch(err){
        if(!err.response || !err.response.data){
          Notify.failure('Network error');
          throw thunkAPI.rejectWithValue('Network error');
        }else{
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }
      }
    }else{
      throw thunkAPI.rejectWithValue('Token is required');
    }
  }
)


export const retrieveOrder = createAsyncThunk(
  'checkout/RETRIEVE_ORDER',
  async(order_id, thunkAPI)=>{
    try{
      let response = await api.retrieveOrder(order_id)
      return response.data;
    }catch(err){
      if(!err.response || !err.response.data){
        Notify.failure('Network error');
        throw thunkAPI.rejectWithValue('Network error');
      }else{
        Notify.failure(err.response.data.error);
        throw thunkAPI.rejectWithValue(err.response.data.error);
      }
    }
  }
)





export const getOrders = createAsyncThunk(
  'checkout/GET_ORDERS',
  async(request, thunkAPI)=>{
    let token = Cookies.get('c-token')
    if(token){
      let data = {
        token,
        request
      }
      try{
        let response = await api.getOrders(data);
        return response.data;
      }catch(err){
        if(!err.response || !err.response.data){
          Notify.failure('Network error');
          throw thunkAPI.rejectWithValue('Network error');
        }else{
          Notify.failure(err.response.data.error);
          throw thunkAPI.rejectWithValue(err.response.data.error);
        }
      }
    }else{
      throw thunkAPI.rejectWithValue('Token is required');
    }
  }
)

export const checkoutSlice = createSlice({
  name:'checkout',
  initialState:{
    value:{
      data:[],
      loading:false,
      retrieving:false,
      retrieved_order:{},
      transaction_processing:false,
      creating:false,
      error:null
    }
  },

  reducers:{

    setTransactionProcessing:(state, action)=>{
      state.value = {
        ...state.value,
        transaction_processing:action.payload
      }
    }

  },
  extraReducers:(builder)=> {
    builder.addCase(createOrder.pending, (state, action)=> {
      state.value = {
        ...state.value,
        creating:true,
        error:null
      }
    })
    builder.addCase(createOrder.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        creating:false,
        error:null
      }
    })
    builder.addCase(createOrder.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        creating:false,
        error:action.payload
      }
    })
    builder.addCase(createCharge.pending, (state, action)=> {
      state.value = {
        ...state.value,
        creating_charge:true,
        error:null
      }
    })
    builder.addCase(createCharge.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        creating_charge:false,
        error:null
      }
    })
    builder.addCase(createCharge.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        creating_charge:false,
        error:action.payload
      }
    })
    builder.addCase(retrieveOrder.pending, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving:true,
        error:null
      }
    })
    builder.addCase(retrieveOrder.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving:false,
        retrieved_order:action.payload,
        error:null
      }
    })
    builder.addCase(retrieveOrder.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        retrieving:false,
        error:action.payload
      }
    })
    builder.addCase(getOrders.pending, (state, action)=> {
      state.value = {
        ...state.value,
        loading:true,
        error:null
      }
    })
    builder.addCase(getOrders.fulfilled, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        data:action.payload
      }
    })
    builder.addCase(getOrders.rejected, (state, action)=> {
      state.value = {
        ...state.value,
        loading:false,
        error:action.payload
      }
    })
  }
})

export const {setTransactionProcessing} = checkoutSlice.actions;
export default checkoutSlice.reducer;
//@NFTmarket.africa
