import React from 'react';
import avatar from '../../assets/images/avatar/user.png';
import AnimationCard from '../../component/ui_kits/animation/animation_card';
import loading from '../../assets/animations/brand_loading.json';

const LoadingPostCard =(props)=> {
  return (
      <div className="card rounded-3 mb-10">
        <div className="position-relative card-header bg-white py-3 border-0 d-flex justify-content-between align-items-center" style={{zIndex:1}}>
          <div className="">
            <div>
              <img
                src={avatar}
                alt="avatar"
                className="rounded-circle avatar-md"
              />
            <span className="inline position-relative px-3 h6 fw-bold">
              @...
            </span>
            </div>
          </div>
            <div>
              <span className="dropdown">
                <a
                  className="text-decoration-none btn-icon btn-white rounded-circle text-muted"
                  href="/"
                  role="button"
                  id="shareDropdown"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fe fe-share-2 fs-4 " />
                </a>
                <span className="dropdown-menu" aria-labelledby="shareDropdown">
                  <span className="dropdown-header">Share</span>
                  <div className="dropdown-item"
                    >
                    <i className="fab fa-twitter dropdown-item-icon" />
                    Tweet
                  </div>
                  <div className="dropdown-item">
                    <i className="fas fa-copy dropdown-item-icon" />
                    Copy Link
                  </div>
                </span>
              </span>
            </div>
        </div>
        <div className="card-body text-center m-auto py-10">
          <AnimationCard animation={loading} style={{width:100}}/>
          <div className="card-img-overlay"></div>
        </div>
      </div>
  )
}
export default LoadingPostCard;
//@ NFTmarket.africa 2022
