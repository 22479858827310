import React, {useEffect, useState} from 'react';
import '@splidejs/splide/dist/css/splide.min.css';
import {useDispatch} from 'react-redux';
import {Amplitude} from '../features/tools';
import {
  LandingWrapper
} from './wrapper';
import NftCard from '../component/cards/nftcard';
import DataApi from '../service/api/data_api';
let api = new DataApi();
let EventTracker = new Amplitude();


const Discover =(props)=> {
  let dispatch = useDispatch();
  let [data, setData] = useState({})
  let [currentCategory, setCurrentCategory] = useState({
    category:"Featured ⭐️",
    id:'featured',
    data:[]
  })
  // let [currentItemCategory, setCurrentItemCategory] = useState('all-category')
  let [loading, setLoading] = useState(false)
  let [error, setError] = useState(null)
  let [pageLoaded, setPageLoaded] = useState(false)

  useEffect(()=>{
    if(!pageLoaded){
      EventTracker.logEvent('ON_DISCOVER_PAGE')
    }
  },[pageLoaded, setPageLoaded])

  useEffect(()=>{
    setLoading(true)
    api.getData().then((response)=>{
      setLoading(false)
      setError(null)
      setData(response.data);
      setCurrentCategory({
        name:"Featured ⭐️",
        id:'featured',
        data:response.data["featured"]
      })
    }).catch((err)=>{
      setLoading(false)
      if(err.response && err.response.data){
        setError(err.response.data)
      }else{
        setError('Network Error')
      }
    })
  },[dispatch])

  // let handleToggleCategory =(e)=> {
  //   let {value} = e.target;
  //   if(value){
  //     switch (value) {
  //       case 'featured':
  //         setCurrentCategory({
  //           name:"Featured ⭐️",
  //           id:'featured',
  //           data:data[value].filter((itm)=> itm.category === currentItemCategory || currentItemCategory ==="all-category")
  //         })
  //         break;
  //       case "trending":
  //         setCurrentCategory({
  //           name:"Trending 🔥",
  //           id:'trending',
  //           data:data[value].filter((itm)=> itm.category === currentItemCategory || currentItemCategory ==="all-category")
  //         })
  //         break;
  //       case "sponsored":
  //         setCurrentCategory({
  //           name:"Sponsored ⚡️",
  //           id:'sponsored',
  //           data:data[value].filter((itm)=> itm.category === currentItemCategory || currentItemCategory ==="all-category")
  //         })
  //         break;
  //       default:
  //         return false
  //     }
  //   }
  // }

  // let handleToggleItemCategory =(e)=> {
  //   let {value} = e.target;
  //   if(value){
  //     let items = data[currentCategory['id']].filter((itm) => itm.category===value || value ==="all-category");
  //     setCurrentCategory({...currentCategory, data:items});
  //     setCurrentItemCategory(value)
  //   }else{
  //     setCurrentCategory(currentCategory);
  //     setCurrentItemCategory('all-category')
  //   }
  // }

  let metadata = {
    title:"Discover",
    meta_title:"Discover Creators & Items",
    description:"Discover Creators & Items"
  }

  return (
    <LandingWrapper
    showNavigation={true}
    metadata={metadata}
    showAcceptedPayments={true}
    >
      <div className="pt-15 py-3 bg-white"  style={{zIndex:1}}>
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="py-3">
              <button
              className="btn btn-lg btn-light"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded="false"
              aria-controls="collapseOne"
              >
              Filters
              <i className="mdi mdi-filter-variant"></i>
              </button>
            </div>
            <div className="py-6">
            <button
            className="btn btn-lg btn-light"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
            >
            Sort by
            <i className="fas fa-caret-down"></i>
            </button>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white min-vh-75">
    		<div className="container">
          <div className="row justify-content-between">
            <div className="col-12 col-lg-3 py-3 bg-white rounded rounded-3">
            <>
              {/* accordions collapse */}
              <div className="accordion" id="accordionExample">
                <div className="card shadow-none">
                  <div className="card-header border-0" id="headingOne">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none active"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      <div className="me-auto">
                        <h4 className="mb-0 fw-bold">Price</h4>
                      </div>
                      <span className="chevron-arrow ms-4">
                        <i className="fe fe-chevron-down fs-4" />
                      </span>
                    </a>
                  </div>
                  <div
                    id="collapseOne"
                    className="collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <select className="form-select">
                        <option>10 - 1,000</option>
                        <option>1,000 - 5,000</option>
                        <option>5,000 - 10,000</option>
                        <option>10,000 > </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card shadow-none">
                  <div className="card-header border-0" id="headingTwo">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none "
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      <div className="me-auto">
                        <h4 className="mb-0 fw-bold">Category</h4>
                      </div>
                      <span className="chevron-arrow ms-4">
                        <i className="fe fe-chevron-down fs-4" />
                      </span>
                    </a>
                  </div>
                  <div
                    id="collapseTwo"
                    className="collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <select className="form-select">
                        <option>Artwork</option>
                        <option>Fashion</option>
                        <option>Photography</option>
                        <option>Cover Art</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card shadow-none">
                  <div className="card-header border-0" id="headingThree">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none "
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      <div className="me-auto">
                        <h4 className="mb-0 fw-bold">Utility</h4>
                      </div>
                      <span className="chevron-arrow ms-4">
                        <i className="fe fe-chevron-down fs-4" />
                      </span>
                    </a>
                  </div>
                  <div
                    id="collapseThree"
                    className="collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <select className="form-select">
                        <option>NFT iFrame</option>
                        <option>Merchandise</option>
                        <option>Physical Work</option>
                        <option>No utility</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card shadow-none">
                  <div className="card-header border-0" id="headingFour">
                    <a
                      href="/#"
                      className="d-flex align-items-center text-inherit text-decoration-none "
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      aria-expanded="false"
                      aria-controls="collapseFour"
                    >
                      <div className="me-auto">
                        <h4 className="mb-0 fw-bold">Blockchain</h4>
                      </div>
                      <span className="chevron-arrow ms-4">
                        <i className="fe fe-chevron-down fs-4" />
                      </span>
                    </a>
                  </div>
                  <div
                    id="collapseFour"
                    className="collapse"
                    aria-labelledby="headingFour"
                    data-bs-parent="#accordionExample"
                  >
                    <div className="card-body">
                      <select className="form-select">
                        <option>Polygon</option>
                        <option>Ethereum</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </>
            </div>
            <div className="col-12 col-lg-9 py-3">
              <div className="row justify-content-center align-items-center">
                {
                  loading?
                  <div className="col-12 col-md-6 col-lg-6 rounded pb-6 vh-75 text-center">
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                  :
                  (!loading && currentCategory.data.length)?
                  currentCategory.data.map((item, i)=>
                    <div key={i} className="col-12 col-md-6 col-lg-6 rounded pb-6">
                      <NftCard
                      resolution={{width:"300px", maxHeight:"300px", objectFit:"cover"}}
                      showUsername={true}
                      data={item}
                      loading={data.loading}
                      error={error}
                      />
                    </div>
                  )
                  :
                  null
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Discover;
//@ NFTmarket.africa 2022
