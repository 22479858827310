import React, {useEffect, useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getUserProfile} from '../features/user/userSlice';
import {getGalleries} from '../features/gallery/gallerySlice';
import {LandingWrapper} from './wrapper';
import {Amplitude} from '../features/tools';
import {
  AddItemModal
} from '../component/modal';
let EventTracker = new Amplitude();

let metadata = {
  title:"NFT Market Africa - Gallery",
  meta_title:"NFT Market Africa - Gallery",
  description:"Your NFT gallery"
}

const AddNewItem =(props)=> {
  let dispatch = useDispatch()
  let navigate = useNavigate()
  let [currentGallery, setCurrentGallery] = useState(0)
  let user = useSelector( state => state.user.value)
  let galleries = useSelector( state => state.gallery.value)
  let {data, loading} = galleries;


  useEffect(()=>{
    dispatch(getUserProfile())
    dispatch(getGalleries()).then((response)=>{
      if(response.meta.requestStatus === 'fulfilled' && !response.payload.length){
        navigate('/gallery/create')
      }
    })
  },[dispatch, navigate])

  let handleToggleGallery =(e)=>{
    let {value} = e.target;
    setCurrentGallery(value)
  }

  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="py-10 pt-15 bg-primary">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="d-lg-flex align-items-center justify-content-between">
                {/* Content */}
                <div className="mb-4 mb-lg-0">
                  <h1 className="text-white mb-1">Add Item</h1>
                  <p className="mb-0 text-white lead">
                    Add Items to your Gallery
                  </p>
                </div>
                <div className="d-flex">
                  {
                    loading?
                    <button
                      disabled
                      className="btn btn-success btn-sm me-2">
                      <div className="spinner-border spinner-border-sm" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </button>
                    :
                    null
                  }
                  <Link to="/gallery" className="btn btn-white btn-sm">
                    Back to Gallery
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12">
              <div className="row justify-content-between align-items-center">
                <div className="col-xl-8 col-md-6 col-12">
                  <h4 className="mb-3 mb-md-0">Select Gallery</h4>
                </div>
                <div className="col-xl-3 col-md-4 col-6">
                  <div className="row">
                    <div className="col-8">
                      <select className="form-select" data-width="100%" onChange={handleToggleGallery}>
                      {
                        galleries.data&&galleries.data.length?
                        galleries.data.map((gallery, i) =>
                        <option key={i} value={i}>{gallery.name}</option>
                        )
                        :
                        <option>Select a gallery</option>
                      }
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="py-6 mb-10">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10">
              <div className="row justify-content-between">
              {
                user.profile&&!user.profile.profile_verified?
                <div className="col-md-6 col-12">
                  {/* card  */}
                  <div className="card border mb-md-0 mb-4">
                    {/* card body  */}
                    <div className="card-body">
                      <div className="mb-3">
                        <i className="bi bi-plus fs-2 fw-bold text-success"/>
                      </div>
                      {/* para  */}
                      <h3 className="mb-2 fw-semi-bold">Collect NFTs</h3>
                      <p>
                        Discover and Collect NFTs into your Gallery
                      </p>
                      {/* btn  */}
                      <a  href="/discover" className="btn btn-primary btn-sm">
                        Discover NFTs
                      </a>
                    </div>
                  </div>
                </div>
                :
                <div className="col-md-6 col-12">
                  {/* card  */}
                  <div className="card border mb-md-0 mb-4">
                    {/* card body  */}
                    <div className="card-body">
                      <div className="mb-3">
                        <i className="bi bi-plus fs-2 fw-bold text-success"/>
                      </div>
                      {/* para  */}
                      <h3 className="mb-2 fw-semi-bold">Mint</h3>
                      <p>
                        Create a new Item on the Blockchain.(Coming soon)
                      </p>
                      {/* btn  */}
                      <button className="btn btn-primary btn-sm" disabled>
                        Mint Item
                      </button>
                    </div>
                  </div>
                </div>
              }
                <div className="col-md-6 col-12">
                  {/* card  */}
                  <div className="card border">
                    {/* card body  */}
                    <div className="card-body">
                      <div className="mb-3">
                        <i className="bi bi-download fs-2 fw-bold text-success"/>
                      </div>
                      {/* para  */}
                      <h3 className="mb-2 fw-semi-bold">Import NFTs</h3>
                      <p>
                        Transfer an existing Item into your Gallery
                      </p>
                      {/* btn  */}
                      <button
                        type="button"
                        className="btn btn-primary btn-sm me-2"
                        data-bs-target="#addItem"
                        data-bs-toggle="offcanvas"
                        aria-controls="addItem"
                        disabled={loading}
                        onClick={()=> EventTracker.logEvent('CLICKED_IMPORT_NFT')}
                      >
                        Import NFT
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        data?
        <AddItemModal data={data[currentGallery]}/>
        :
        null
      }
    </LandingWrapper>
  )
}
export default AddNewItem;
//@ NFTmarket.africa 2022
