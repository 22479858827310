import React, {useState, useEffect} from 'react';
import {Amplitude} from '../../features/tools';
let EventTracker = new Amplitude();


export default function CommentModal(props){
  let {
    layout
  } = props;
  let {
    username,
    profile_verified
  } = props.profile

  let [copied, setCopiedStatus] = useState(false)

  useEffect(()=>{
    if(copied){
      let timer = setTimeout(()=>{
        setCopiedStatus(false)
      },1000)
      return function cleanUp(){
        clearTimeout(timer)
      }
    }
  },[copied])

  let copyLink =(link)=> {
    if(navigator){
      navigator.clipboard.writeText(link);
      setCopiedStatus(true)
      EventTracker.logEvent("COPY_PROFILE_LINK")
    }else{
      EventTracker.logEvent("BROWSER_NAVIGATOR_INACTIVE")
    }
  }

  return (
    <div
      className="offcanvas offcanvas-bottom rounded-top-3 border-0"
      tabIndex={-1}
      id="profileModal"
      aria-labelledby="profileModalLabel"
      key="dcjdede"
      style={{height:'50vh'}}
    >

      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="profileModalLabel">
          Quick Actions
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fe fe-x-circle " />
        </button>
      </div>
      <div className="container p-0">
        <div className="offcanvas-body m-0">
          <div className="row justify-content-center align-items-center no-gutters">
            <div className="col-12 col-lg-5">
              <div className="d-grid gap-3 col-12 mx-auto">
                <button onClick={()=>copyLink(`https://nftmarket.africa/@/${username}`)} disabled={!username} className={`d-flex justify-content-between align-items-center btn btn-light btn-lg border-0`}>
                  {
                    copied?
                    <span>Copied</span>
                    :
                    <>
                    <span>Copy Profile URL</span>
                    </>
                  }
                  {
                    copied?
                    <i className="fe fe-check-circle text-success"></i>
                    :
                    <i className="bi bi-clipboard"></i>
                  }
                </button>
                {
                  !username?
                  <span><a href="/profile/edit"><small className="text-warning">Complete your profile</small></a></span>
                  :
                  null
                }
                {layout!=="profileview"?
                  profile_verified?
                  <a href="/draft" onClick={()=> EventTracker.logEvent("LAUNCH_NFT_MERCHANT_APP")} className={`d-flex justify-content-between align-items-center btn btn-light border-0 btn-lg`}>
                    <span>List Item</span>
                    <i className="bi bi-cart"></i>
                  </a>
                  :
                  null
                  :
                  <button onClick={()=> EventTracker.logEvent("SEND_CREATOR_A_MESSAGE")} className={`d-flex justify-content-between align-items-center btn btn-light border-0 btn-lg`}>
                    <span>Send Message</span>
                    <i className="bi bi-chat-right"></i>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

//@NFTmarket.africa 2022
