import amplitude from 'amplitude-js';
import IPFSGatewayTools from '@pinata/ipfs-gateway-tools/dist/browser';
import store from '../../store';
const gatewayTools = new IPFSGatewayTools();

let eventType = {
  ON_HOMEPAGE:'ON_HOMEPAGE',
  VIEWED_HOMEPAGE_ITEM:'VIEWED_HOMEPAGE_ITEM',
  VIEWED_CATEGORY_ITEM:'VIEWED_CATEGORY_ITEM',

  ON_DISCOVER_PAGE:'ON_DISCOVER_PAGE',
  ON_UTILITY_PAGE:'ON_UTILITY_PAGE',
  READ_UTILITY_FAQ:'READ_UTILITY_FAQ',

  VIEWED_A_PROFILE:'VIEWED_A_PROFILE',
  LIKED_AN_ITEM:'LIKED_AN_ITEM',
  VIEWED_ITEM_UTILITY:'VIEWED_ITEM_UTILITY',
  ITEM_BOOST:'ITEM_BOOST',
  CLICKED_ON_BUY:'CLICKED_ON_BUY',

  ON_BUY_PAGE:'ON_BUY_PAGE',
  ON_ORDER_PAGE:'ON_ORDER_PAGE',
  SELECTED_ADDRESS:'SELECTED_ADDRESS',
  ENTERED_ADDRESS:'ENTERED_ADDRESS',
  VERIFYING_ITEM:'VERIFYING_ITEM',
  CANCELLED_CHECKOUT:'CANCELLED_CHECKOUT',
  ENCOUNTED_CHECKOUT_ERROR:'ENCOUNTED_CHECKOUT_ERROR',

  ON_DASHBOARD:'ON_DASHBOARD',
  ON_STOREFRONT:'ON_STOREFRONT',

  VIEWING_WALLET:'VIEWING_WALLET',
  CREATE_TRANSACTION:'CREATE_TRANSACTION',
  CANCELLED_TRANSACTION:'CANCELLED_TRANSACTION',
  CREATE_TRANSACTION_SUCCESS:'CREATE_TRANSACTION_SUCCESS',
  CREATE_TRANSACTION_FAILURE:'CREATE_TRANSACTION_FAILURE',
  TRANSACTION_SIGNATURE_ERROR:'TRANSACTION_SIGNATURE_ERROR',
  DECRYPTING_KEYS_SUCESS:'DECRYPTING_KEYS_SUCESS',

  ON_GALLERY:'ON_GALLERY',
  ON_GALLERY_SECURITY:'ON_GALLERY_SECURITY',
  GET_SECRET_KEY:'GET_SECRET_KEY',
  GET_SECRET_KEY_SUCCESS:'GET_SECRET_KEY_SUCCESS',
  GET_SECRET_KEY_FAILURE:'GET_SECRET_KEY_FAILURE',
  VERIFY_GALLERY_PIN:'VERIFY_GALLERY_PIN',
  VERIFY_GALLERY_PIN_SUCCESS:'VERIFY_GALLERY_PIN_SUCCESS',
  VERIFY_GALLERY_PIN_FAILURE:'VERIFY_GALLERY_PIN_FAILURE',
  DECRYPTING_KEYS_FAILURE:'DECRYPTING_KEYS_FAILURE',

  ADD_ITEM_TO_GALLERY:'ADD_ITEM_TO_GALLERY',
  CLICKED_IMPORT_NFT:'CLICKED_IMPORT_NFT',
  COPIED_IMPORT_ADDRESS:'COPIED_IMPORT_ADDRESS',

  UPLOAD_PROFILE_IMAGE_SUCCESS:'UPLOAD_PROFILE_IMAGE_SUCCESS',
  UPLOAD_PROFILE_IMAGE_FAILURE:'UPLOAD_PROFILE_IMAGE_FAILURE',
  UPDATE_PROFILE_SUCCESS:'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE:'UPDATE_PROFILE_FAILURE',
  REQUESTED_VERIFICATION:'REQUESTED_VERIFICATION',

  ON_ARTICLES:'ON_ARTICLES',
  VIEWED_ARTICLE:'VIEWED_ARTICLE',
  ON_FAQ:'ON_FAQ',
  VIEWED_FAQ:'VIEWED_FAQ',
  LEARNING:'LEARNING',

  SELL_AN_ITEM:'SELL_AN_ITEM',
  SELECTED_A_GALLERY:'SELECTED_A_GALLERY',
  SELECTED_A_GALLERY_ITEM:'SELECTED_A_GALLERY_ITEM',
  CREATE_ITEM_POST_SUCCESS:'CREATE_ITEM_POST_SUCCESS',
  CREATE_ITEM_POST_ERROR:'CREATE_ITEM_POST_ERROR',

  EDIT_ITEM_OPTIONS:'EDIT_ITEM_OPTIONS',
  ADDED_ITEM_UTILITY:'ADDED_ITEM_UTILITY',
  SET_ITEM_OPTIONS_SUCCESS:'ITEM_OPTIONS_SUCCESS',
  SET_ITEM_OPTIONS_FAILURE:'ITEM_OPTIONS_FAILURE',

  PAY_WITH_PAYPAL:'PAY_WITH_PAYPAL',
  PAY_WITH_PAYPAL_SUCCESS:'PAY_WITH_PAYPAL_SUCCESS',
  PAY_WITH_PAYPAL_FAILURE:'PAY_WITH_PAYPAL_FAILURE',
  PAY_WITH_FLUTTERWAVE:'PAY_WITH_FLUTTERWAVE',
  PAY_WITH_FLUTTERWAVE_SUCCESS:'PAY_WITH_FLUTTERWAVE_SUCCESS',

  SEND_CREATOR_A_MESSAGE:'SEND_CREATOR_A_MESSAGE',
  ADD_FUNDS_FROM_MODAL:'ADD_FUNDS_FROM_MODAL',
  LAUNCH_NFT_MERCHANT_APP:'LAUNCH_NFT_MERCHANT_APP',
  COPY_PROFILE_LINK:'COPY_PROFILE_LINK',
  BROWSER_NAVIGATOR_INACTIVE:'BROWSER_NAVIGATOR_INACTIVE',
  PRODUCT_SCAN:'PRODUCT_SCAN'

}

export class Amplitude{

  initialize(){
    let {user} = store.getState();
    let {profile} = user.value;
    if(profile){
      return amplitude.getInstance().init("3a6082cd9b005c911c22324ced089c58", (profile.username || profile.account));
    }
    return amplitude.getInstance().init("3a6082cd9b005c911c22324ced089c58");
  }

  logEvent(event_){
    this.initialize();
    let eventName = eventType[event_];
    if(eventName){
      return amplitude.getInstance().logEvent(eventType[event_]);
    }
    console.error('Invalid event', event_);
  }

}


export const getURL =(url)=> {
  if(url){
    let detectIpfs = gatewayTools.containsCID(url)
    if(!detectIpfs.containsCid) return url
    let url_ = new URL(url)
    return `https://nftmarketafrica.mypinata.cloud${url_.pathname}`
  }else{
    return "no url"
  }
}


export const currencyFormat =(amount, currency)=>{
  return (parseFloat(amount)).toLocaleString('en-US', {
    style: 'currency',
    currency,
  })
}


export const getPrice =(utility_prices, utilities, item_price, options)=> {
  if(utilities && utility_prices){
    item_price = parseFloat(item_price)
    let included = Object.values(utilities).filter((utility) => utility.include);
    included.forEach((item, i) => {
      // console.log(utility_prices[item.slug]);
      item_price = utility_prices[item.slug] + item_price
    });
    if(options &&options.noFormat) return item_price;
    return currencyFormat(item_price, 'USD');
  }else{
    return currencyFormat(0, 'USD');
  }
}


export const getPricePlusFee = (price, fee)=> {
  fee = parseFloat(fee);
  price = parseFloat(price);
  let perentage = (fee / 100) * price;
  let total = price + perentage;
  return currencyFormat(total, 'USD');
}


export const getUtilityPrice = (utility_prices, utilities)=> {
  if(utilities && utility_prices){
    let total = 0
    let included = Object.values(utilities).filter((utility) => utility.include);
    included.forEach((item, i) => {
      // console.log(utility_prices[item.slug]);
      total = utility_prices[item.slug] + total
    });
    return currencyFormat(total, 'USD');
  }else{
    return currencyFormat(0, 'USD');
  }
}

export const sliceCharacter=(char)=>{
  return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
}


export const getEtherScanUrl =(data)=> {
  let {contract_address, blockchain} = data;
  let explorer = {
    "polygon":"https://polygonscan.com",
    "ethereum":"https://etherscan.io"
  }
  return `${explorer[blockchain]}/address/${contract_address}`
}

export const getOpenseaUrl =(data)=> {
  let {token_id, contract_address, blockchain} = data;
  return `https://opensea.io/assets/${blockchain==="polygon"?"matic":blockchain}/${contract_address}/${token_id}`
}

//@nftmarket.africa 2022
