import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from'react-router-dom';
import '@splidejs/splide/dist/css/splide.min.css';
import {
  LandingWrapper
} from './wrapper';
import ToolsApi from '../service/api/tools_api';
let api = new ToolsApi();


let valid_reasons = ['feedback', 'support-ticket'];

const Contact =(props)=> {
  let {contact_type} = useParams();
  let user = useSelector( state => state.user.value)
  let [sending, setSending] = useState(false)
  let [messageSent, setMessageSent] = useState(false)
  let [error, setError]= useState(null)
  let [details, setDetails] = useState({
    reason:contact_type
  })

  let metadata = {
    title:`NFT Market Africa | ${contact_type}`,
    meta_title:`Send a ${contact_type}`,
    description:`Send a ${contact_type}`,
  }

  useEffect(()=>{
    if(user && user.profile){
      setDetails((d)=> {
        return {
          ...d,
          account:user.profile.account,
          username:user.profile.username
        }
      })
    }
  },[user])

  let handleInput =(e)=> {
    let {value, name, accept} = e.target;
    details = {
      ...details,
      [name]:value
    }
    if(value && value.match(accept)){
      setDetails(details);
    }
  }

  let handleSubmitForm =(e)=>{
    e.preventDefault();
    let isValidReason = valid_reasons.findIndex((el)=> el=== contact_type)+1;
    if(isValidReason){
      setSending(true)
      api.contactAdmin(details).then((response)=>{
        setError(null)
        setSending(false)
        setMessageSent(true)
      }).catch((err)=>{
        setSending(false)
        setMessageSent(false)
        if(err && err.response && err.response.data && err.response.data.error){
          setError(err.response.data.error)
        }else{
          setError('Network Error')
        }
      })
    }else{
      setError('Cannot contact for wrong reason')
    }
  }
  return (
    <LandingWrapper showNavigation={true} metadata={metadata}>
      <div className="pt-15 py-10 py-lg-15 bg-light">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-12">
              <div>
                <h1 className="mb-1 display-4 text-dark">{contact_type} 📩</h1>
                <p className="mb-0 lead text-muted">
                  Send your {contact_type}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5 pb-10">
        <div className="container">
          <div className="row ">
            <div className="offset-md-2 col-md-8 col-12">
              <div className="mb-3">
                {/* lead  */}
                <p className="lead mb-8">
                  Send us a {contact_type} and let us know how we can better serve you.
                </p>
                <div className="d-flex justify-content-between">
                  <span className={error?"text-danger":"text-dark"}>{error || "Contact form"}</span>
                  <div className="text-end">
                    <a href="/#">team@nftmarket.africa</a>
                  </div>
                </div>
              </div>
              <div>
                {/* card */}
                <div className="card border">
                  {/* card body  */}
                  <div className="card-body p-5">
                    <h2 className="mb-4 fw-semi-bold">Write your message</h2>
                    {/* form  */}
                    <form onSubmit={handleSubmitForm}>
                      {/* input  */}
                      <div className="mb-3">
                        <label className="form-label" htmlFor="name">
                          Your Name
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Your name"
                          id="full_name"
                          name="full_name"
                          required
                          onChange={handleInput}
                        />
                      </div>
                      {/* input  */}
                      <div className="mb-3">
                        <label className="form-label" htmlFor="company">
                          Company
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Company name"
                          id="company"
                          name="company"
                          onChange={handleInput}
                        />
                      </div>
                      {/* input  */}
                      <div className="mb-3">
                        <label className="form-label" htmlFor="email">
                          Email Address <span className="text-danger">*</span>
                        </label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Email address here"
                          id="email"
                          name="email"
                          required
                          onChange={handleInput}
                        />
                      </div>
                      <div className="mb-3">
                        {/* input  */}
                        <label className="form-label" htmlFor="description">
                          Description
                        </label>
                        <textarea
                          placeholder="Write down here"
                          id="description"
                          name="description"
                          rows={2}
                          className="form-control"
                          onChange={handleInput}
                          required
                        />
                      </div>
                      {
                        sending?
                        <button className="btn btn-primary" type="button" disabled>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          {" "}Sending...
                        </button>
                        :
                        !sending && !messageSent?
                        <button className="btn btn-primary" type="submit">
                          Send
                        </button>
                        :
                        <button className="btn btn-success" type="button">
                          Sent{" "}<i className="fas fa-check-circle"/>
                        </button>
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingWrapper>
  )
}
export default Contact;
//@ NFTmarket.africa 2022
