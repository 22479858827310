import React, {useState, useEffect} from 'react';
import {Amplitude} from '../../features/tools';
let EventTracker = new Amplitude();


export default function AddItem(props){
  let [gallery, setGallery] = useState({})
  let [copied, setCopiedStatus] = useState(false)

  useEffect(()=>{
    if(props.data){
      setGallery(props.data)
    }
  },[props.data])

  let copyAddress =(e)=> {
    const element = document.getElementById('gallery-address');
    element.select();
    document.execCommand('copy');
    setCopiedStatus(true);
    EventTracker.logEvent('COPIED_IMPORT_ADDRESS')
  }

  useEffect(()=>{
    if(copied){
      let timer = setTimeout(()=>{
        setCopiedStatus(false)
      },500)
      return function cleanUp(){
        clearTimeout(timer)
      }
    }
  },[copied])

  return (
    <div
      className="offcanvas offcanvas-bottom rounded-top-3 border-0"
      tabIndex={-1}
      id="addItem"
      aria-labelledby="addItem"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="addItemLabel">
            Copy gallery address
        </h5>
        <button
          type="button"
          className="btn-close text-reset"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        >
          <i className="fe fe-x-circle " />
        </button>
      </div>
      <div className="container p-0">
        <div className="offcanvas-body m-0">
          <div className="row justify-content-center align-items-center no-gutters">
            <div className="col-12 col-lg-5">
              <div className="d-grid gap-3 col-12 mx-auto">
                <div className="input-group" onClick={copyAddress}>
                  <input
                    type="text"
                    defaultValue={gallery.address}
                    className="form-control form-outline outline-primary"
                    id="gallery-address"
                    aria-describedby="inputGroupAppend"
                    readOnly
                  />
                  <span className="input-group-text border-0 rounded-right" id="inputGroupApend">
                    {
                      !copied?
                      <i className="fe fe-copy"/>
                      :
                      <i className="fe fe-check-circle text-success"/>
                    }
                  </span>
                </div>
                <p>Transfer {gallery.chain} NFT to this address. <a href="/articles/how-to-sell">Learn more</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
//@NFTmarket.africa 2022
