import React, {useEffect} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {getUserProfile} from '../features/user/userSlice';
import {getOrders} from '../features/checkout/checkoutSlice';
import {currencyFormat} from '../features/tools';
import {useDispatch, useSelector} from 'react-redux';
import {LandingWrapper} from './wrapper';

let metadata = {
  title:"NFT Market Africa - Sales & Orders",
  meta_title:"NFT Market Africa - Sales & Orders",
  description:"View your sales and orders"
}

const Sales =(props)=> {
  let dispatch = useDispatch()
  let navigate = useNavigate();
  let {data, loading} = useSelector( state => state.checkout.value)
  let user = useSelector(state => state.user.value)
  useEffect(()=>{
    dispatch(getUserProfile())
    dispatch(getOrders());
  },[dispatch])

  let sliceCharacter=(char)=>{
    return `${char.slice(0,6)}......${char.slice(char.length-6, char.length)}`
  }

  useEffect(()=>{
    if(user && user.profile && !user.profile.profile_verified){
      navigate('/orders')
    }
  },[user, navigate])
  return (
    <LandingWrapper
    showNavigation={true}
    metadata={metadata}
    >
      <div className="pt-15 bg-white min-vh-75">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-10">
              <span className="fs-3 fw-bold">
                <Link to="/dashboard">
                  <i className="fe fe-arrow-left fw-bolder px-3"/>
                </Link>
              </span>
            </div>
            <div className="col-md-12 pt-5">
              {/* Tab content */}
              <div className="row justify-content-center">
                <div className="col-lg-9 col-md-8 col-12">
                  {/* Card */}
                  { data && data.sales?
                  <div className="card mb-4">
                    {/* Card header */}
                    <div className="card-header border-bottom-0">
                      <h3 className="mb-0">Sales</h3>
                      <p className="mb-0">Find all of your sales.</p>
                    </div>
                    {/* Table */}
                    <div className="table-invoice table-responsive border-0">
                      <table className="table table-hover mb-0 text-nowrap">
                        <thead className="table-light">
                          <tr>
                            <th scope="col" className="border-bottom-0">
                              ORDER ID
                            </th>
                            <th scope="col" className="border-bottom-0">
                              STATUS
                            </th>
                            <th scope="col" className="border-bottom-0">
                              AMOUNT
                            </th>
                            <th scope="col" className="border-bottom-0">
                              TOKEN ID
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                        {
                          data && data.sales?
                          data.sales.map( (sale, i) =>
                            <tr key={i} className="clickable" onClick={()=> navigate(`/sale/${sale.order_id}`)}>
                              <td>
                                <a href={`/sale/${sale.order_id}`}>{sale.order_id}</a>
                              </td>
                              <td>
                              {
                                sale.status === 'unpaid'?
                                <span className="badge bg-danger">{sale.status}</span>
                                :
                                sale.status === 'pending'?
                                <span className="badge bg-warning">{sale.status}</span>
                                :
                                sale.status === 'processing'?
                                <span className="badge bg-info">{sale.status}</span>
                                :
                                sale.status === 'complete'?
                                <span className="badge bg-success">{sale.status}</span>
                                :
                                sale.status === 'hold'?
                                <span className="badge bg-warning">{sale.status}</span>
                                :
                                <span className="badge bg-light">{sale.status}</span>
                              }
                              </td>
                              <td>{currencyFormat(sale.item_amount, 'USD')}</td>
                              <td>{sliceCharacter(sale.token_id)}</td>
                            </tr>
                          )
                          :
                          null
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                  :
                  (loading)?
                  <div className='text-center'>
                    <div className="spinner-border spinner-border-lg" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                  :
                  <div className="text-center">
                    <p>You have no sales yet.</p>
                    <a href="/storefront" className="btn btn-primary brand-button">
                      Go to Storefront
                    </a>
                  </div>
                }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </LandingWrapper>
  )
}
export default Sales;
//@ NFTmarket.africa 2022
